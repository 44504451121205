/* -------------------------------------------------------------- */
/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700;800&family=Rubik:wght@400;500&display=swap");
/*******************************************************/
/******************* 01. Default Style *****************/
/*******************************************************/
* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

body {
  color: #454545;
  background: white;
  font-weight: 400;
  line-height: 28px;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
}

a {
  color: #454545;
  cursor: pointer;
  outline: none;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
}
a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 12px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #000000;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white h1 a,
.text-white h2 a,
.text-white h3 a,
.text-white h4 a,
.text-white h5 a,
.text-white h6 a {
  color: white;
}

h1 {
  font-size: 72px;
}

h2 {
  line-height: 1.33;
  font-size: 36px;
}

h3 {
  line-height: 1.55;
  font-size: 22px;
}

h4 {
  line-height: 1.4;
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p {
  color: #454545;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  display: inline-block;
}

header:after,
section:after,
footer:after {
  display: block;
  clear: both;
  content: "";
}

/*======= Input Styles =======*/
input,
select,
textarea,
.form-control {
  width: 100%;
  height: auto;
  padding: 16px 30px;
  border-radius: 5px;
  background-color: #fff;
  border: 2px solid #cfdbf1;
}

textarea {
  display: inherit;
  padding-top: 20px;
}

label {
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 5px;
  color: #000000;
}

.form-group {
  margin-bottom: 25px;
}

input:focus,
button:focus,
.form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #cfdbf1;
}

input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="checkbox"],
input[type="radio"] {
  height: auto;
  width: auto;
}

.text-white input,
.text-white select,
.text-white textarea,
.text-white .form-control {
  color: #ffffff50;
  border-color: #273540;
  background: #0e1e2a;
}

.text-white label {
  margin-bottom: 10px;
}

/*******************************************************/
/*****************  02. Common Classes *****************/
/*******************************************************/
.page-wrapper {
  position: relative;
  z-index: 9;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  min-width: 300px;
  background: #ffffff;
}

.container {
  max-width: 1200px;
}

.small-gap {
  margin-left: -5px;
  margin-right: -5px;
}
.small-gap > div {
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 375px) {
  .col-small {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/** Section Title style **/
.section-title .sub-title {
  font-weight: 500;
  margin-bottom: 20px;
  color: #104cba;
  display: inline-block;
}

@media only screen and (max-width: 480px) {
  .section-title h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 375px) {
  .section-title h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 480px) {
  .section-title h2 br {
    display: none;
  }
}

.text-white .sub-title {
  color: white;
}

.section-title-with-btn {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.section-title-with-btn h2 {
  margin-right: 25px;
}

/** Button style **/
.theme-btn,
a.theme-btn {
  background: #104cba;
  font-size: 16px;
  color: white;
  cursor: pointer;
  font-weight: 500;
  text-align: center;
  border-radius: 5px;
  padding: 11px 28px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-transform: capitalize;
}
.theme-btn i,
a.theme-btn i {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-left: 10px;
}
.theme-btn.btn-circle,
a.theme-btn.btn-circle {
  border-radius: 50px;
  padding-left: 35px;
  padding-right: 35px;
}
.theme-btn:hover,
a.theme-btn:hover {
  color: white;
  background: #000000;
}
.theme-btn:hover i,
a.theme-btn:hover i {
  margin-left: 15px;
  margin-right: -5px;
}
.theme-btn.style-two,
a.theme-btn.style-two {
  padding: 11px 35px;
  background: #0850b0;
  border: 1px solid #88a6dd;
}
.theme-btn.style-two:hover,
a.theme-btn.style-two:hover {
  background: #000000;
  border-color: #000000;
}
.theme-btn.style-three,
a.theme-btn.style-three {
  padding: 11px 40px;
  color: #104cba;
  background: #e5eef6;
}
.theme-btn.style-three:hover,
a.theme-btn.style-three:hover {
  color: #e5eef6;
  background: #104cba;
}
.theme-btn.style-four,
a.theme-btn.style-four {
  padding: 11px 35px;
  background: #24323d;
}
.theme-btn.style-four:hover,
a.theme-btn.style-four:hover {
  background: #104cba;
}
.theme-btn.style-five,
a.theme-btn.style-five {
  padding: 10px 35px;
  background: transparent;
  border: 1px solid white;
}
.theme-btn.style-five:hover,
a.theme-btn.style-five:hover {
  background: white;
  color: #104cba;
}
.theme-btn.style-six,
a.theme-btn.style-six {
  padding: 10px 35px;
  color: #000000;
  background: transparent;
  border: 1px solid #e5e5e5;
}
.theme-btn.style-six:hover,
a.theme-btn.style-six:hover {
  background: #e5eef6;
}
.theme-btn.style-seven,
a.theme-btn.style-seven {
  padding: 13px 33px;
  color: #2f97f7;
  background: transparent;
  border: 2px solid #2f97f7;
}
.theme-btn.style-seven:hover,
a.theme-btn.style-seven:hover {
  color: white;
  background: #2f97f7;
}
.theme-btn.style-eight,
a.theme-btn.style-eight {
  border: none;
  font-size: 18px;
  padding: 16px 40px;
  background: #2969e6;
  font-family: "Circular Std";
}
@media only screen and (max-width: 375px) {
  .theme-btn.style-eight,
  a.theme-btn.style-eight {
    padding: 15px 28px;
  }
}
.theme-btn.style-eight i,
a.theme-btn.style-eight i {
  float: right;
}
.theme-btn.style-eight:hover,
a.theme-btn.style-eight:hover {
  text-decoration: underline;
  background: #2f97f7;
}
.theme-btn.style-nine,
a.theme-btn.style-nine {
  background: #03091f;
}
.theme-btn.style-nine:hover,
a.theme-btn.style-nine:hover {
  color: white;
  background: #f43336;
}
.theme-btn.style-nine.hover-two,
a.theme-btn.style-nine.hover-two {
  border: 1px solid #03091f;
}
.theme-btn.style-ten,
a.theme-btn.style-ten {
  background: #f43336;
}
.theme-btn.style-ten:hover,
a.theme-btn.style-ten:hover {
  background: white;
  color: #000000;
}
.theme-btn.style-ten:hover i,
a.theme-btn.style-ten:hover i {
  color: #000000;
}
.theme-btn.style-ten.hover-two:hover,
a.theme-btn.style-ten.hover-two:hover {
  color: white;
  background: #03091f;
}
.theme-btn.style-ten.hover-two:hover i,
a.theme-btn.style-ten.hover-two:hover i {
  color: white;
}
.theme-btn.style-eleven,
a.theme-btn.style-eleven {
  background: white;
  color: #000000;
}
.theme-btn.style-eleven i,
a.theme-btn.style-eleven i {
  color: #000000;
}
.theme-btn.style-eleven:hover,
a.theme-btn.style-eleven:hover {
  color: white;
  background: #f43336;
}
.theme-btn.style-eleven:hover i,
a.theme-btn.style-eleven:hover i {
  color: white;
}
.theme-btn.style-twelve,
a.theme-btn.style-twelve {
  background: #20c997;
}
.theme-btn.style-twelve:hover,
a.theme-btn.style-twelve:hover {
  background: white;
  color: #20c997;
}
.theme-btn.gradient-btn-one,
.theme-btn.gradient-btn-two,
a.theme-btn.gradient-btn-one,
a.theme-btn.gradient-btn-two {
  z-index: 1;
  position: relative;
  border-radius: 4px;
  padding: 14px 36px;
  text-transform: uppercase;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(-21.46%, #a146e8),
    color-stop(36.39%, #6c63d0),
    to(#387db8)
  );
  background: -webkit-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: -o-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: linear-gradient(90deg, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
}
@media only screen and (max-width: 480px) {
  .theme-btn.gradient-btn-one,
  .theme-btn.gradient-btn-two,
  a.theme-btn.gradient-btn-one,
  a.theme-btn.gradient-btn-two {
    padding: 12px 25px;
  }
}
.theme-btn.gradient-btn-one:before,
.theme-btn.gradient-btn-two:before,
a.theme-btn.gradient-btn-one:before,
a.theme-btn.gradient-btn-two:before {
  content: "";
  z-index: -1;
  left: 50%;
  top: 50%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 4px;
  position: absolute;
  background: #030511;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.theme-btn.gradient-btn-one:hover:before,
.theme-btn.gradient-btn-two:not(hover):before,
a.theme-btn.gradient-btn-one:hover:before,
a.theme-btn.gradient-btn-two:not(hover):before {
  opacity: 1;
  width: calc(100% - 3px);
  height: calc(100% - 3px);
}
.theme-btn.gradient-btn-two:hover:before,
.theme-btn.gradient-btn-one:not(hover):before,
a.theme-btn.gradient-btn-two:hover:before,
a.theme-btn.gradient-btn-one:not(hover):before {
  width: 0;
  height: 0;
  opacity: 0;
}
@media only screen and (max-width: 480px) {
  .theme-btn,
  a.theme-btn {
    font-size: 14px;
    padding: 12px 25px;
  }
}

.learn-more {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.learn-more:hover i {
  margin-left: 10px;
}
.learn-more i {
  font-size: 13px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin: 2px 0 0 8px;
}

.read-more {
  color: #000f5c;
  font-size: 16px;
  font-weight: 500;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-decoration: underline;
  font-family: "Circular Std";
}
.read-more i {
  float: right;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-left: 5px;
  color: #ff7a41;
}
.read-more:hover {
  color: #2969e6;
  text-decoration: underline;
}
.read-more:hover i {
  color: #2969e6;
  margin-left: 8px;
}

/** List style **/
.list-style-one li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  margin-top: 20px;
}
.list-style-one li:before {
  color: white;
  font-size: 14px;
  content: "\f00c";
  font-weight: 600;
  margin-top: -4px;
  margin-right: 15px;
  width: 35px;
  height: 35px;
  background: #104cba;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  font-family: "Font Awesome 5 Free";
}

.list-style-two li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.list-style-two i {
  margin: 6px 20px 0 0;
}

.list-style-three li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 7px;
  text-transform: capitalize;
}
.list-style-three li:before {
  font-size: 14px;
  content: "\f00c";
  font-weight: 700;
  margin-right: 20px;
  color: #104cba;
  font-family: "Font Awesome 5 Free";
}

/** Social Link Style One **/
.social-style-one {
  display: inline-block;
}
.social-style-one a {
  margin-right: 15px;
  color: #000000;
}
.social-style-one a:last-child {
  margin-right: 0;
}
.social-style-one a:hover {
  color: #104cba;
}

/** Social Link Style two **/
.social-style-two {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.social-style-two a {
  color: white;
  opacity: 0.4;
  margin-right: 7px;
  width: 40px;
  height: 40px;
  background: #162b3b;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
}
.social-style-two a:last-child {
  margin-right: 0;
}
.social-style-two a:hover {
  opacity: 1;
  background: #104cba;
}

/*** Preloader style ***/
.preloader {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #ffffff;
  z-index: 9999999;
}
.preloader .theme-loader {
  margin: auto;
  height: 70px;
  width: 70px;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  position: absolute;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-animation: animate 4.59s ease-in-out infinite;
  animation: animate 4.59s ease-in-out infinite;
}
.preloader .theme-loader:before,
.preloader .theme-loader:after {
  content: "";
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  border: 15px solid #1b76ff;
  animation: animate_1 1.73s ease-in-out infinite both reverse;
}
.preloader .theme-loader:after {
  border-color: #0d47a1;
  -webkit-animation-delay: -0.86s;
  animation-delay: -0.86s;
}

/* Pagination */
.pagination li {
  margin: 0 10px 10px 0;
}
.pagination li a,
.pagination li .page-link {
  padding: 0;
  color: #859aaa;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 60px;
  height: 60px;
  background: white;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #e5e5e5;
}
@media only screen and (max-width: 480px) {
  .pagination li a,
  .pagination li .page-link {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}
.pagination li.disabled .page-link,
.pagination li:last-child .page-link {
  border-radius: 50%;
}
.pagination li:hover:not(.disabled) .page-link,
.pagination li.active .page-link {
  color: white;
  background: #104cba;
  border-color: #104cba;
}

/* Rating */
.rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.rating i {
  font-size: 14px;
  color: #104cba;
}

/*** Scroll Top style ***/
.scroll-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 99;
  width: 40px;
  height: 40px;
  color: white;
  display: none;
  cursor: pointer;
  border-radius: 5px;
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  background: #135be0;
}

/* Text White */
.text-white *,
.text-white a {
  color: white;
}

/* Overlay */
.overlay {
  z-index: 1;
  position: relative;
}
.overlay::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.4;
  background-color: #0d004c;
}

/* Video Play Btn */
.video-play {
  display: inline-block;
}
.video-play i {
  width: 55px;
  height: 55px;
  background: white;
  line-height: 55px;
  border-radius: 50%;
  text-align: center;
  color: #104cba;
}
.video-play span {
  font-size: 18px;
  font-weight: 500;
  margin-left: 10px;
}

/* Position */
.rel {
  position: relative;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

/* Backgruond Size */
.bgs-cover {
  background-size: cover;
  background-position: center;
  background-color: #0e1e2a;
}

/* Border None */
.no-border {
  border: none !important;
}

.box-shadow {
  -webkit-box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.07);
}

/* Background Colors */
.bg-blue {
  background-color: #104cba;
}

.bg-black {
  background-color: #03091f;
}

.bg-red {
  background-color: #f43336;
}

.bg-light-black {
  background-color: #0e1e2a;
}

.bg-dark-blue {
  background-color: #222f5a;
}

.bg-lighter {
  background-color: #e5eef6;
}

/* Border Radius */
.br-5 {
  border-radius: 5px;
}

.br-10 {
  border-radius: 10px;
}

.br-15 {
  border-radius: 15px;
}

.br-20 {
  border-radius: 20px;
}

.br-25 {
  border-radius: 25px;
}

.br-30 {
  border-radius: 30px;
}

.home-six a:hover {
  color: #2f97f7;
}

.home-six .theme-btn,
.home-six .scroll-top,
.home-six .theme-btn.style-three:hover {
  background: #2f97f7;
}
.home-six .theme-btn:hover,
.home-six .scroll-top:hover,
.home-six .theme-btn.style-three:hover:hover {
  color: white;
}

.home-six .theme-btn.style-three {
  padding-top: 15px;
  padding-bottom: 15px;
  color: #000000;
  text-transform: uppercase;
}

.home-six .bg-lighter {
  background-color: #f4f7fa;
}

.home-six .section-title .sub-title {
  color: #2f97f7;
}

.home-six .section-title h2 {
  text-transform: capitalize;
}

.home-seven {
  color: #666f9d;
  font-size: 18px;
  font-family: "Nunito";
}
.home-seven p {
  color: #666f9d;
}
.home-seven h1,
.home-seven h2,
.home-seven h3,
.home-seven h4,
.home-seven h5,
.home-seven h6 {
  color: #000f5c;
  font-family: "Circular Std";
}
.home-seven h2 {
  font-size: 48px;
}
@media only screen and (max-width: 575px) {
  .home-seven h2 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 480px) {
  .home-seven h2 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 375px) {
  .home-seven h2 {
    font-size: 30px;
  }
}
.home-seven .sub-title {
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 15px;
  border-radius: 5px;
  display: inline-block;
  background: #ff7a41;
}

.portfolio-sidebar .bg-lighter {
  background-color: #f4f7fa;
}

.home-eight .theme-btn,
.home-eight a.theme-btn {
  padding: 15px 35px;
}
@media only screen and (max-width: 480px) {
  .home-eight .theme-btn,
  .home-eight a.theme-btn {
    padding: 12px 25px;
  }
}

.home-eight a:hover,
.home-eight .section-title .sub-title {
  color: #f43336;
}

.home-eight .list-style-one li:before {
  width: 30px;
  height: 30px;
  color: #f43336;
  line-height: 30px;
  background: #f8f8f8;
}

.home-eight .list-style-three li {
  font-weight: 500;
}
.home-eight .list-style-three li:before {
  color: #f43336;
}

.home-nine .page-wrapper {
  background: #080f17;
}

.home-nine .sub-title,
.home-nine h1,
.home-nine h2,
.home-nine h3,
.home-nine h4,
.home-nine h5,
.home-nine h6 {
  font-weight: 700;
  font-family: "Urbanist", sans-serif;
}

.home-nine .sub-title {
  font-size: 18px;
  color: #20c997;
  margin-bottom: 15px;
}

.home-nine h2 {
  font-size: 48px;
}
@media only screen and (max-width: 767px) {
  .home-nine h2 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 575px) {
  .home-nine h2 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 375px) {
  .home-nine h2 {
    font-size: 30px;
  }
}

.home-nine .slick-dots {
  margin-top: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.home-nine .slick-dots li {
  margin: 0 8px 0;
  cursor: pointer;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.home-nine .slick-dots li button {
  width: 6px;
  height: 6px;
  overflow: hidden;
  text-indent: 100px;
  border-radius: 50%;
  background: transparent;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(-21.46%, #a146e8),
    color-stop(36.39%, #6c63d0),
    to(#387db8)
  );
  background: -webkit-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: -o-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: linear-gradient(90deg, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
}
.home-nine .slick-dots li:before {
  content: "";
  width: 20px;
  height: 20px;
  left: 50%;
  top: 50%;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #6c63d0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.home-nine .slick-dots li.slick-active:before {
  opacity: 1;
}

.home-nine .slick-arrow {
  z-index: 1;
  position: relative;
  display: inline-block;
  width: 56px;
  height: 56px;
  background: #1b2429;
  line-height: 56px;
  border-radius: 50%;
  text-align: center;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
}
.home-nine .slick-arrow:before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  border-radius: 50%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(-21.46%, #a146e8),
    color-stop(36.39%, #6c63d0),
    to(#387db8)
  );
  background: -webkit-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: -o-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: linear-gradient(90deg, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
}
.home-nine .slick-arrow:first-child {
  margin-right: 5px;
}
.home-nine .slick-arrow:focus:before,
.home-nine .slick-arrow:hover:before {
  opacity: 1;
}

/*******************************************************/
/******************* 03. Repeat Style ******************/
/*******************************************************/
h1,
h2,
h3,
h4,
h5,
h6,
.success-item .count-text,
.pricing-header .price {
  font-weight: 800;
  color: #000000;
  font-family: "Nunito", sans-serif;
}

.service-normal .icon i,
.service-style-two .icon i,
.service-three-item .icon i,
.feature-item .icon i,
.work-progress-item .icon i,
.pricing-item-three .icon i {
  font-size: 70px;
  display: inline-block;
  color: #104cba;
  margin-bottom: 20px;
}

/*******************************************************/
/************** 04. Padding Margin Spacing *************/
/*******************************************************/
/* Padding Around */
.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.p-35 {
  padding: 35px;
}

.p-40 {
  padding: 40px;
}

.p-45 {
  padding: 45px;
}

.p-50 {
  padding: 50px;
}

.p-55 {
  padding: 55px;
}

.p-60 {
  padding: 60px;
}

.p-65 {
  padding: 65px;
}

.p-70 {
  padding: 70px;
}

.p-75 {
  padding: 75px;
}

.p-80 {
  padding: 80px;
}

.p-85 {
  padding: 85px;
}

.p-90 {
  padding: 90px;
}

.p-95 {
  padding: 95px;
}

.p-100 {
  padding: 100px;
}

.p-105 {
  padding: 105px;
}

.p-110 {
  padding: 110px;
}

.p-115 {
  padding: 115px;
}

.p-120 {
  padding: 120px;
}

.p-125 {
  padding: 125px;
}

.p-130 {
  padding: 130px;
}

.p-135 {
  padding: 135px;
}

.p-140 {
  padding: 140px;
}

.p-145 {
  padding: 145px;
}

.p-150 {
  padding: 150px;
}

.p-155 {
  padding: 155px;
}

.p-160 {
  padding: 160px;
}

.p-165 {
  padding: 165px;
}

.p-170 {
  padding: 170px;
}

.p-175 {
  padding: 175px;
}

.p-180 {
  padding: 180px;
}

.p-185 {
  padding: 185px;
}

.p-190 {
  padding: 190px;
}

.p-195 {
  padding: 195px;
}

.p-200 {
  padding: 200px;
}

.p-205 {
  padding: 205px;
}

.p-210 {
  padding: 210px;
}

.p-215 {
  padding: 215px;
}

.p-220 {
  padding: 220px;
}

.p-225 {
  padding: 225px;
}

.p-230 {
  padding: 230px;
}

.p-235 {
  padding: 235px;
}

.p-240 {
  padding: 240px;
}

.p-245 {
  padding: 245px;
}

.p-250 {
  padding: 250px;
}

/* Padding Top */
.pt-5,
.py-5 {
  padding-top: 5px !important;
}

.pt-10,
.py-10 {
  padding-top: 10px;
}

.pt-15,
.py-15 {
  padding-top: 15px;
}

.pt-20,
.py-20 {
  padding-top: 20px;
}

.pt-25,
.py-25 {
  padding-top: 25px;
}

.pt-30,
.py-30 {
  padding-top: 30px;
}

.pt-35,
.py-35 {
  padding-top: 35px;
}

.pt-40,
.py-40 {
  padding-top: 40px;
}

.pt-45,
.py-45 {
  padding-top: 45px;
}

.pt-50,
.py-50 {
  padding-top: 50px;
}

.pt-55,
.py-55 {
  padding-top: 55px;
}

.pt-60,
.py-60 {
  padding-top: 60px;
}

.pt-65,
.py-65 {
  padding-top: 65px;
}

.pt-70,
.py-70 {
  padding-top: 70px;
}

.pt-75,
.py-75 {
  padding-top: 75px;
}

.pt-80,
.py-80 {
  padding-top: 80px;
}

.pt-85,
.py-85 {
  padding-top: 85px;
}

.pt-90,
.py-90 {
  padding-top: 90px;
}

.pt-95,
.py-95 {
  padding-top: 95px;
}

.pt-100,
.py-100 {
  padding-top: 100px;
}

.pt-105,
.py-105 {
  padding-top: 105px;
}

.pt-110,
.py-110 {
  padding-top: 110px;
}

.pt-115,
.py-115 {
  padding-top: 115px;
}

.pt-120,
.py-120 {
  padding-top: 120px;
}

.pt-125,
.py-125 {
  padding-top: 125px;
}

.pt-130,
.py-130 {
  padding-top: 130px;
}

.pt-135,
.py-135 {
  padding-top: 135px;
}

.pt-140,
.py-140 {
  padding-top: 140px;
}

.pt-145,
.py-145 {
  padding-top: 145px;
}

.pt-150,
.py-150 {
  padding-top: 150px;
}

.pt-155,
.py-155 {
  padding-top: 155px;
}

.pt-160,
.py-160 {
  padding-top: 160px;
}

.pt-165,
.py-165 {
  padding-top: 165px;
}

.pt-170,
.py-170 {
  padding-top: 170px;
}

.pt-175,
.py-175 {
  padding-top: 175px;
}

.pt-180,
.py-180 {
  padding-top: 180px;
}

.pt-185,
.py-185 {
  padding-top: 185px;
}

.pt-190,
.py-190 {
  padding-top: 190px;
}

.pt-195,
.py-195 {
  padding-top: 195px;
}

.pt-200,
.py-200 {
  padding-top: 200px;
}

.pt-205,
.py-205 {
  padding-top: 205px;
}

.pt-210,
.py-210 {
  padding-top: 210px;
}

.pt-215,
.py-215 {
  padding-top: 215px;
}

.pt-220,
.py-220 {
  padding-top: 220px;
}

.pt-225,
.py-225 {
  padding-top: 225px;
}

.pt-230,
.py-230 {
  padding-top: 230px;
}

.pt-235,
.py-235 {
  padding-top: 235px;
}

.pt-240,
.py-240 {
  padding-top: 240px;
}

.pt-245,
.py-245 {
  padding-top: 245px;
}

.pt-250,
.py-250 {
  padding-top: 250px;
}

/* Padding Right */
.pr-5,
.px-5 {
  padding-right: 5px !important;
}

.pr-10,
.px-10 {
  padding-right: 10px;
}

.pr-15,
.px-15 {
  padding-right: 15px;
}

.pr-20,
.px-20 {
  padding-right: 20px;
}

.pr-25,
.px-25 {
  padding-right: 25px;
}

.pr-30,
.px-30 {
  padding-right: 30px;
}

.pr-35,
.px-35 {
  padding-right: 35px;
}

.pr-40,
.px-40 {
  padding-right: 40px;
}

.pr-45,
.px-45 {
  padding-right: 45px;
}

.pr-50,
.px-50 {
  padding-right: 50px;
}

.pr-55,
.px-55 {
  padding-right: 55px;
}

.pr-60,
.px-60 {
  padding-right: 60px;
}

.pr-65,
.px-65 {
  padding-right: 65px;
}

.pr-70,
.px-70 {
  padding-right: 70px;
}

.pr-75,
.px-75 {
  padding-right: 75px;
}

.pr-80,
.px-80 {
  padding-right: 80px;
}

.pr-85,
.px-85 {
  padding-right: 85px;
}

.pr-90,
.px-90 {
  padding-right: 90px;
}

.pr-95,
.px-95 {
  padding-right: 95px;
}

.pr-100,
.px-100 {
  padding-right: 100px;
}

.pr-105,
.px-105 {
  padding-right: 105px;
}

.pr-110,
.px-110 {
  padding-right: 110px;
}

.pr-115,
.px-115 {
  padding-right: 115px;
}

.pr-120,
.px-120 {
  padding-right: 120px;
}

.pr-125,
.px-125 {
  padding-right: 125px;
}

.pr-130,
.px-130 {
  padding-right: 130px;
}

.pr-135,
.px-135 {
  padding-right: 135px;
}

.pr-140,
.px-140 {
  padding-right: 140px;
}

.pr-145,
.px-145 {
  padding-right: 145px;
}

.pr-150,
.px-150 {
  padding-right: 150px;
}

.pr-155,
.px-155 {
  padding-right: 155px;
}

.pr-160,
.px-160 {
  padding-right: 160px;
}

.pr-165,
.px-165 {
  padding-right: 165px;
}

.pr-170,
.px-170 {
  padding-right: 170px;
}

.pr-175,
.px-175 {
  padding-right: 175px;
}

.pr-180,
.px-180 {
  padding-right: 180px;
}

.pr-185,
.px-185 {
  padding-right: 185px;
}

.pr-190,
.px-190 {
  padding-right: 190px;
}

.pr-195,
.px-195 {
  padding-right: 195px;
}

.pr-200,
.px-200 {
  padding-right: 200px;
}

.pr-205,
.px-205 {
  padding-right: 205px;
}

.pr-210,
.px-210 {
  padding-right: 210px;
}

.pr-215,
.px-215 {
  padding-right: 215px;
}

.pr-220,
.px-220 {
  padding-right: 220px;
}

.pr-225,
.px-225 {
  padding-right: 225px;
}

.pr-230,
.px-230 {
  padding-right: 230px;
}

.pr-235,
.px-235 {
  padding-right: 235px;
}

.pr-240,
.px-240 {
  padding-right: 240px;
}

.pr-245,
.px-245 {
  padding-right: 245px;
}

.pr-250,
.px-250 {
  padding-right: 250px;
}

/* Padding Bottom */
.pb-5,
.py-5 {
  padding-bottom: 5px !important;
}

.pb-10,
.py-10 {
  padding-bottom: 10px;
}

.pb-15,
.py-15 {
  padding-bottom: 15px;
}

.pb-20,
.py-20 {
  padding-bottom: 20px;
}

.pb-25,
.py-25 {
  padding-bottom: 25px;
}

.pb-30,
.py-30 {
  padding-bottom: 30px;
}

.pb-35,
.py-35 {
  padding-bottom: 35px;
}

.pb-40,
.py-40 {
  padding-bottom: 40px;
}

.pb-45,
.py-45 {
  padding-bottom: 45px;
}

.pb-50,
.py-50 {
  padding-bottom: 50px;
}

.pb-55,
.py-55 {
  padding-bottom: 55px;
}

.pb-60,
.py-60 {
  padding-bottom: 60px;
}

.pb-65,
.py-65 {
  padding-bottom: 65px;
}

.pb-70,
.py-70 {
  padding-bottom: 70px;
}

.pb-75,
.py-75 {
  padding-bottom: 75px;
}

.pb-80,
.py-80 {
  padding-bottom: 80px;
}

.pb-85,
.py-85 {
  padding-bottom: 85px;
}

.pb-90,
.py-90 {
  padding-bottom: 90px;
}

.pb-95,
.py-95 {
  padding-bottom: 95px;
}

.pb-100,
.py-100 {
  padding-bottom: 100px;
}

.pb-105,
.py-105 {
  padding-bottom: 105px;
}

.pb-110,
.py-110 {
  padding-bottom: 110px;
}

.pb-115,
.py-115 {
  padding-bottom: 115px;
}

.pb-120,
.py-120 {
  padding-bottom: 120px;
}

.pb-125,
.py-125 {
  padding-bottom: 125px;
}

.pb-130,
.py-130 {
  padding-bottom: 130px;
}

.pb-135,
.py-135 {
  padding-bottom: 135px;
}

.pb-140,
.py-140 {
  padding-bottom: 140px;
}

.pb-145,
.py-145 {
  padding-bottom: 145px;
}

.pb-150,
.py-150 {
  padding-bottom: 150px;
}

.pb-155,
.py-155 {
  padding-bottom: 155px;
}

.pb-160,
.py-160 {
  padding-bottom: 160px;
}

.pb-165,
.py-165 {
  padding-bottom: 165px;
}

.pb-170,
.py-170 {
  padding-bottom: 170px;
}

.pb-175,
.py-175 {
  padding-bottom: 175px;
}

.pb-180,
.py-180 {
  padding-bottom: 180px;
}

.pb-185,
.py-185 {
  padding-bottom: 185px;
}

.pb-190,
.py-190 {
  padding-bottom: 190px;
}

.pb-195,
.py-195 {
  padding-bottom: 195px;
}

.pb-200,
.py-200 {
  padding-bottom: 200px;
}

.pb-205,
.py-205 {
  padding-bottom: 205px;
}

.pb-210,
.py-210 {
  padding-bottom: 210px;
}

.pb-215,
.py-215 {
  padding-bottom: 215px;
}

.pb-220,
.py-220 {
  padding-bottom: 220px;
}

.pb-225,
.py-225 {
  padding-bottom: 225px;
}

.pb-230,
.py-230 {
  padding-bottom: 230px;
}

.pb-235,
.py-235 {
  padding-bottom: 235px;
}

.pb-240,
.py-240 {
  padding-bottom: 240px;
}

.pb-245,
.py-245 {
  padding-bottom: 245px;
}

.pb-250,
.py-250 {
  padding-bottom: 250px;
}

/* Padding Left */
.pl-5,
.px-5 {
  padding-left: 5px !important;
}

.pl-10,
.px-10 {
  padding-left: 10px;
}

.pl-15,
.px-15 {
  padding-left: 15px;
}

.pl-20,
.px-20 {
  padding-left: 20px;
}

.pl-25,
.px-25 {
  padding-left: 25px;
}

.pl-30,
.px-30 {
  padding-left: 30px;
}

.pl-35,
.px-35 {
  padding-left: 35px;
}

.pl-40,
.px-40 {
  padding-left: 40px;
}

.pl-45,
.px-45 {
  padding-left: 45px;
}

.pl-50,
.px-50 {
  padding-left: 50px;
}

.pl-55,
.px-55 {
  padding-left: 55px;
}

.pl-60,
.px-60 {
  padding-left: 60px;
}

.pl-65,
.px-65 {
  padding-left: 65px;
}

.pl-70,
.px-70 {
  padding-left: 70px;
}

.pl-75,
.px-75 {
  padding-left: 75px;
}

.pl-80,
.px-80 {
  padding-left: 80px;
}

.pl-85,
.px-85 {
  padding-left: 85px;
}

.pl-90,
.px-90 {
  padding-left: 90px;
}

.pl-95,
.px-95 {
  padding-left: 95px;
}

.pl-100,
.px-100 {
  padding-left: 100px;
}

.pl-105,
.px-105 {
  padding-left: 105px;
}

.pl-110,
.px-110 {
  padding-left: 110px;
}

.pl-115,
.px-115 {
  padding-left: 115px;
}

.pl-120,
.px-120 {
  padding-left: 120px;
}

.pl-125,
.px-125 {
  padding-left: 125px;
}

.pl-130,
.px-130 {
  padding-left: 130px;
}

.pl-135,
.px-135 {
  padding-left: 135px;
}

.pl-140,
.px-140 {
  padding-left: 140px;
}

.pl-145,
.px-145 {
  padding-left: 145px;
}

.pl-150,
.px-150 {
  padding-left: 150px;
}

.pl-155,
.px-155 {
  padding-left: 155px;
}

.pl-160,
.px-160 {
  padding-left: 160px;
}

.pl-165,
.px-165 {
  padding-left: 165px;
}

.pl-170,
.px-170 {
  padding-left: 170px;
}

.pl-175,
.px-175 {
  padding-left: 175px;
}

.pl-180,
.px-180 {
  padding-left: 180px;
}

.pl-185,
.px-185 {
  padding-left: 185px;
}

.pl-190,
.px-190 {
  padding-left: 190px;
}

.pl-195,
.px-195 {
  padding-left: 195px;
}

.pl-200,
.px-200 {
  padding-left: 200px;
}

.pl-205,
.px-205 {
  padding-left: 205px;
}

.pl-210,
.px-210 {
  padding-left: 210px;
}

.pl-215,
.px-215 {
  padding-left: 215px;
}

.pl-220,
.px-220 {
  padding-left: 220px;
}

.pl-225,
.px-225 {
  padding-left: 225px;
}

.pl-230,
.px-230 {
  padding-left: 230px;
}

.pl-235,
.px-235 {
  padding-left: 235px;
}

.pl-240,
.px-240 {
  padding-left: 240px;
}

.pl-245,
.px-245 {
  padding-left: 245px;
}

.pl-250,
.px-250 {
  padding-left: 250px;
}

/* Margin Around */
.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.m-25 {
  margin: 25px;
}

.m-30 {
  margin: 30px;
}

.m-35 {
  margin: 35px;
}

.m-40 {
  margin: 40px;
}

.m-45 {
  margin: 45px;
}

.m-50 {
  margin: 50px;
}

.m-55 {
  margin: 55px;
}

.m-60 {
  margin: 60px;
}

.m-65 {
  margin: 65px;
}

.m-70 {
  margin: 70px;
}

.m-75 {
  margin: 75px;
}

.m-80 {
  margin: 80px;
}

.m-85 {
  margin: 85px;
}

.m-90 {
  margin: 90px;
}

.m-95 {
  margin: 95px;
}

.m-100 {
  margin: 100px;
}

.m-105 {
  margin: 105px;
}

.m-110 {
  margin: 110px;
}

.m-115 {
  margin: 115px;
}

.m-120 {
  margin: 120px;
}

.m-125 {
  margin: 125px;
}

.m-130 {
  margin: 130px;
}

.m-135 {
  margin: 135px;
}

.m-140 {
  margin: 140px;
}

.m-145 {
  margin: 145px;
}

.m-150 {
  margin: 150px;
}

.m-155 {
  margin: 155px;
}

.m-160 {
  margin: 160px;
}

.m-165 {
  margin: 165px;
}

.m-170 {
  margin: 170px;
}

.m-175 {
  margin: 175px;
}

.m-180 {
  margin: 180px;
}

.m-185 {
  margin: 185px;
}

.m-190 {
  margin: 190px;
}

.m-195 {
  margin: 195px;
}

.m-200 {
  margin: 200px;
}

.m-205 {
  margin: 205px;
}

.m-210 {
  margin: 210px;
}

.m-215 {
  margin: 215px;
}

.m-220 {
  margin: 220px;
}

.m-225 {
  margin: 225px;
}

.m-230 {
  margin: 230px;
}

.m-235 {
  margin: 235px;
}

.m-240 {
  margin: 240px;
}

.m-245 {
  margin: 245px;
}

.m-250 {
  margin: 250px;
}

/* Margin Top */
.mt-5,
.my-5 {
  margin-top: 5px !important;
}

.mt-10,
.my-10 {
  margin-top: 10px;
}

.mt-15,
.my-15 {
  margin-top: 15px;
}

.mt-20,
.my-20 {
  margin-top: 20px;
}

.mt-25,
.my-25 {
  margin-top: 25px;
}

.mt-30,
.my-30 {
  margin-top: 30px;
}

.mt-35,
.my-35 {
  margin-top: 35px;
}

.mt-40,
.my-40 {
  margin-top: 40px;
}

.mt-45,
.my-45 {
  margin-top: 45px;
}

.mt-50,
.my-50 {
  margin-top: 50px;
}

.mt-55,
.my-55 {
  margin-top: 55px;
}

.mt-60,
.my-60 {
  margin-top: 60px;
}

.mt-65,
.my-65 {
  margin-top: 65px;
}

.mt-70,
.my-70 {
  margin-top: 70px;
}

.mt-75,
.my-75 {
  margin-top: 75px;
}

.mt-80,
.my-80 {
  margin-top: 80px;
}

.mt-85,
.my-85 {
  margin-top: 85px;
}

.mt-90,
.my-90 {
  margin-top: 90px;
}

.mt-95,
.my-95 {
  margin-top: 95px;
}

.mt-100,
.my-100 {
  margin-top: 100px;
}

.mt-105,
.my-105 {
  margin-top: 105px;
}

.mt-110,
.my-110 {
  margin-top: 110px;
}

.mt-115,
.my-115 {
  margin-top: 115px;
}

.mt-120,
.my-120 {
  margin-top: 120px;
}

.mt-125,
.my-125 {
  margin-top: 125px;
}

.mt-130,
.my-130 {
  margin-top: 130px;
}

.mt-135,
.my-135 {
  margin-top: 135px;
}

.mt-140,
.my-140 {
  margin-top: 140px;
}

.mt-145,
.my-145 {
  margin-top: 145px;
}

.mt-150,
.my-150 {
  margin-top: 150px;
}

.mt-155,
.my-155 {
  margin-top: 155px;
}

.mt-160,
.my-160 {
  margin-top: 160px;
}

.mt-165,
.my-165 {
  margin-top: 165px;
}

.mt-170,
.my-170 {
  margin-top: 170px;
}

.mt-175,
.my-175 {
  margin-top: 175px;
}

.mt-180,
.my-180 {
  margin-top: 180px;
}

.mt-185,
.my-185 {
  margin-top: 185px;
}

.mt-190,
.my-190 {
  margin-top: 190px;
}

.mt-195,
.my-195 {
  margin-top: 195px;
}

.mt-200,
.my-200 {
  margin-top: 200px;
}

.mt-205,
.my-205 {
  margin-top: 205px;
}

.mt-210,
.my-210 {
  margin-top: 210px;
}

.mt-215,
.my-215 {
  margin-top: 215px;
}

.mt-220,
.my-220 {
  margin-top: 220px;
}

.mt-225,
.my-225 {
  margin-top: 225px;
}

.mt-230,
.my-230 {
  margin-top: 230px;
}

.mt-235,
.my-235 {
  margin-top: 235px;
}

.mt-240,
.my-240 {
  margin-top: 240px;
}

.mt-245,
.my-245 {
  margin-top: 245px;
}

.mt-250,
.my-250 {
  margin-top: 250px;
}

/* Margin Right */
.mr-5,
.mx-5 {
  margin-right: 5px !important;
}

.mr-10,
.mx-10 {
  margin-right: 10px;
}

.mr-15,
.mx-15 {
  margin-right: 15px;
}

.mr-20,
.mx-20 {
  margin-right: 20px;
}

.mr-25,
.mx-25 {
  margin-right: 25px;
}

.mr-30,
.mx-30 {
  margin-right: 30px;
}

.mr-35,
.mx-35 {
  margin-right: 35px;
}

.mr-40,
.mx-40 {
  margin-right: 40px;
}

.mr-45,
.mx-45 {
  margin-right: 45px;
}

.mr-50,
.mx-50 {
  margin-right: 50px;
}

.mr-55,
.mx-55 {
  margin-right: 55px;
}

.mr-60,
.mx-60 {
  margin-right: 60px;
}

.mr-65,
.mx-65 {
  margin-right: 65px;
}

.mr-70,
.mx-70 {
  margin-right: 70px;
}

.mr-75,
.mx-75 {
  margin-right: 75px;
}

.mr-80,
.mx-80 {
  margin-right: 80px;
}

.mr-85,
.mx-85 {
  margin-right: 85px;
}

.mr-90,
.mx-90 {
  margin-right: 90px;
}

.mr-95,
.mx-95 {
  margin-right: 95px;
}

.mr-100,
.mx-100 {
  margin-right: 100px;
}

.mr-105,
.mx-105 {
  margin-right: 105px;
}

.mr-110,
.mx-110 {
  margin-right: 110px;
}

.mr-115,
.mx-115 {
  margin-right: 115px;
}

.mr-120,
.mx-120 {
  margin-right: 120px;
}

.mr-125,
.mx-125 {
  margin-right: 125px;
}

.mr-130,
.mx-130 {
  margin-right: 130px;
}

.mr-135,
.mx-135 {
  margin-right: 135px;
}

.mr-140,
.mx-140 {
  margin-right: 140px;
}

.mr-145,
.mx-145 {
  margin-right: 145px;
}

.mr-150,
.mx-150 {
  margin-right: 150px;
}

.mr-155,
.mx-155 {
  margin-right: 155px;
}

.mr-160,
.mx-160 {
  margin-right: 160px;
}

.mr-165,
.mx-165 {
  margin-right: 165px;
}

.mr-170,
.mx-170 {
  margin-right: 170px;
}

.mr-175,
.mx-175 {
  margin-right: 175px;
}

.mr-180,
.mx-180 {
  margin-right: 180px;
}

.mr-185,
.mx-185 {
  margin-right: 185px;
}

.mr-190,
.mx-190 {
  margin-right: 190px;
}

.mr-195,
.mx-195 {
  margin-right: 195px;
}

.mr-200,
.mx-200 {
  margin-right: 200px;
}

.mr-205,
.mx-205 {
  margin-right: 205px;
}

.mr-210,
.mx-210 {
  margin-right: 210px;
}

.mr-215,
.mx-215 {
  margin-right: 215px;
}

.mr-220,
.mx-220 {
  margin-right: 220px;
}

.mr-225,
.mx-225 {
  margin-right: 225px;
}

.mr-230,
.mx-230 {
  margin-right: 230px;
}

.mr-235,
.mx-235 {
  margin-right: 235px;
}

.mr-240,
.mx-240 {
  margin-right: 240px;
}

.mr-245,
.mx-245 {
  margin-right: 245px;
}

.mr-250,
.mx-250 {
  margin-right: 250px;
}

/* Margin Bottom */
.mb-5,
.my-5 {
  margin-bottom: 5px !important;
}

.mb-10,
.my-10 {
  margin-bottom: 10px;
}

.mb-15,
.my-15 {
  margin-bottom: 15px;
}

.mb-20,
.my-20 {
  margin-bottom: 20px;
}

.mb-25,
.my-25 {
  margin-bottom: 25px;
}

.mb-30,
.my-30 {
  margin-bottom: 30px;
}

.mb-35,
.my-35 {
  margin-bottom: 35px;
}

.mb-40,
.my-40 {
  margin-bottom: 40px;
}

.mb-45,
.my-45 {
  margin-bottom: 45px;
}

.mb-50,
.my-50 {
  margin-bottom: 50px;
}

.mb-55,
.my-55 {
  margin-bottom: 55px;
}

.mb-60,
.my-60 {
  margin-bottom: 60px;
}

.mb-65,
.my-65 {
  margin-bottom: 65px;
}

.mb-70,
.my-70 {
  margin-bottom: 70px;
}

.mb-75,
.my-75 {
  margin-bottom: 75px;
}

.mb-80,
.my-80 {
  margin-bottom: 80px;
}

.mb-85,
.my-85 {
  margin-bottom: 85px;
}

.mb-90,
.my-90 {
  margin-bottom: 90px;
}

.mb-95,
.my-95 {
  margin-bottom: 95px;
}

.mb-100,
.my-100 {
  margin-bottom: 100px;
}

.mb-105,
.my-105 {
  margin-bottom: 105px;
}

.mb-110,
.my-110 {
  margin-bottom: 110px;
}

.mb-115,
.my-115 {
  margin-bottom: 115px;
}

.mb-120,
.my-120 {
  margin-bottom: 120px;
}

.mb-125,
.my-125 {
  margin-bottom: 125px;
}

.mb-130,
.my-130 {
  margin-bottom: 130px;
}

.mb-135,
.my-135 {
  margin-bottom: 135px;
}

.mb-140,
.my-140 {
  margin-bottom: 140px;
}

.mb-145,
.my-145 {
  margin-bottom: 145px;
}

.mb-150,
.my-150 {
  margin-bottom: 150px;
}

.mb-155,
.my-155 {
  margin-bottom: 155px;
}

.mb-160,
.my-160 {
  margin-bottom: 160px;
}

.mb-165,
.my-165 {
  margin-bottom: 165px;
}

.mb-170,
.my-170 {
  margin-bottom: 170px;
}

.mb-175,
.my-175 {
  margin-bottom: 175px;
}

.mb-180,
.my-180 {
  margin-bottom: 180px;
}

.mb-185,
.my-185 {
  margin-bottom: 185px;
}

.mb-190,
.my-190 {
  margin-bottom: 190px;
}

.mb-195,
.my-195 {
  margin-bottom: 195px;
}

.mb-200,
.my-200 {
  margin-bottom: 200px;
}

.mb-205,
.my-205 {
  margin-bottom: 205px;
}

.mb-210,
.my-210 {
  margin-bottom: 210px;
}

.mb-215,
.my-215 {
  margin-bottom: 215px;
}

.mb-220,
.my-220 {
  margin-bottom: 220px;
}

.mb-225,
.my-225 {
  margin-bottom: 225px;
}

.mb-230,
.my-230 {
  margin-bottom: 230px;
}

.mb-235,
.my-235 {
  margin-bottom: 235px;
}

.mb-240,
.my-240 {
  margin-bottom: 240px;
}

.mb-245,
.my-245 {
  margin-bottom: 245px;
}

.mb-250,
.my-250 {
  margin-bottom: 250px;
}

/* Margin Left */
.ml-5,
.mx-5 {
  margin-left: 5px !important;
}

.ml-10,
.mx-10 {
  margin-left: 10px;
}

.ml-15,
.mx-15 {
  margin-left: 15px;
}

.ml-20,
.mx-20 {
  margin-left: 20px;
}

.ml-25,
.mx-25 {
  margin-left: 25px;
}

.ml-30,
.mx-30 {
  margin-left: 30px;
}

.ml-35,
.mx-35 {
  margin-left: 35px;
}

.ml-40,
.mx-40 {
  margin-left: 40px;
}

.ml-45,
.mx-45 {
  margin-left: 45px;
}

.ml-50,
.mx-50 {
  margin-left: 50px;
}

.ml-55,
.mx-55 {
  margin-left: 55px;
}

.ml-60,
.mx-60 {
  margin-left: 60px;
}

.ml-65,
.mx-65 {
  margin-left: 65px;
}

.ml-70,
.mx-70 {
  margin-left: 70px;
}

.ml-75,
.mx-75 {
  margin-left: 75px;
}

.ml-80,
.mx-80 {
  margin-left: 80px;
}

.ml-85,
.mx-85 {
  margin-left: 85px;
}

.ml-90,
.mx-90 {
  margin-left: 90px;
}

.ml-95,
.mx-95 {
  margin-left: 95px;
}

.ml-100,
.mx-100 {
  margin-left: 100px;
}

.ml-105,
.mx-105 {
  margin-left: 105px;
}

.ml-110,
.mx-110 {
  margin-left: 110px;
}

.ml-115,
.mx-115 {
  margin-left: 115px;
}

.ml-120,
.mx-120 {
  margin-left: 120px;
}

.ml-125,
.mx-125 {
  margin-left: 125px;
}

.ml-130,
.mx-130 {
  margin-left: 130px;
}

.ml-135,
.mx-135 {
  margin-left: 135px;
}

.ml-140,
.mx-140 {
  margin-left: 140px;
}

.ml-145,
.mx-145 {
  margin-left: 145px;
}

.ml-150,
.mx-150 {
  margin-left: 150px;
}

.ml-155,
.mx-155 {
  margin-left: 155px;
}

.ml-160,
.mx-160 {
  margin-left: 160px;
}

.ml-165,
.mx-165 {
  margin-left: 165px;
}

.ml-170,
.mx-170 {
  margin-left: 170px;
}

.ml-175,
.mx-175 {
  margin-left: 175px;
}

.ml-180,
.mx-180 {
  margin-left: 180px;
}

.ml-185,
.mx-185 {
  margin-left: 185px;
}

.ml-190,
.mx-190 {
  margin-left: 190px;
}

.ml-195,
.mx-195 {
  margin-left: 195px;
}

.ml-200,
.mx-200 {
  margin-left: 200px;
}

.ml-205,
.mx-205 {
  margin-left: 205px;
}

.ml-210,
.mx-210 {
  margin-left: 210px;
}

.ml-215,
.mx-215 {
  margin-left: 215px;
}

.ml-220,
.mx-220 {
  margin-left: 220px;
}

.ml-225,
.mx-225 {
  margin-left: 225px;
}

.ml-230,
.mx-230 {
  margin-left: 230px;
}

.ml-235,
.mx-235 {
  margin-left: 235px;
}

.ml-240,
.mx-240 {
  margin-left: 240px;
}

.ml-245,
.mx-245 {
  margin-left: 245px;
}

.ml-250,
.mx-250 {
  margin-left: 250px;
}

/* Responsive Padding Margin */
@media only screen and (max-width: 991px) {
  /* Padding Around */
  .rp-0 {
    padding: 0px !important;
  }
  .rp-5 {
    padding: 5px !important;
  }
  .rp-10 {
    padding: 10px;
  }
  .rp-15 {
    padding: 15px;
  }
  .rp-20 {
    padding: 20px;
  }
  .rp-25 {
    padding: 25px;
  }
  .rp-30 {
    padding: 30px;
  }
  .rp-35 {
    padding: 35px;
  }
  .rp-40 {
    padding: 40px;
  }
  .rp-45 {
    padding: 45px;
  }
  .rp-50 {
    padding: 50px;
  }
  .rp-55 {
    padding: 55px;
  }
  .rp-60 {
    padding: 60px;
  }
  .rp-65 {
    padding: 65px;
  }
  .rp-70 {
    padding: 70px;
  }
  .rp-75 {
    padding: 75px;
  }
  .rp-80 {
    padding: 80px;
  }
  .rp-85 {
    padding: 85px;
  }
  .rp-90 {
    padding: 90px;
  }
  .rp-95 {
    padding: 95px;
  }
  .rp-100 {
    padding: 100px;
  }
  .rp-105 {
    padding: 105px;
  }
  .rp-110 {
    padding: 110px;
  }
  .rp-115 {
    padding: 115px;
  }
  .rp-120 {
    padding: 120px;
  }
  .rp-125 {
    padding: 125px;
  }
  .rp-130 {
    padding: 130px;
  }
  .rp-135 {
    padding: 135px;
  }
  .rp-140 {
    padding: 140px;
  }
  .rp-145 {
    padding: 145px;
  }
  .rp-150 {
    padding: 150px;
  }
  /* Padding Top */
  .rpt-0,
  .rpy-0 {
    padding-top: 0px !important;
  }
  .rpt-5,
  .rpy-5 {
    padding-top: 5px !important;
  }
  .rpt-10,
  .rpy-10 {
    padding-top: 10px;
  }
  .rpt-15,
  .rpy-15 {
    padding-top: 15px;
  }
  .rpt-20,
  .rpy-20 {
    padding-top: 20px;
  }
  .rpt-25,
  .rpy-25 {
    padding-top: 25px;
  }
  .rpt-30,
  .rpy-30 {
    padding-top: 30px;
  }
  .rpt-35,
  .rpy-35 {
    padding-top: 35px;
  }
  .rpt-40,
  .rpy-40 {
    padding-top: 40px;
  }
  .rpt-45,
  .rpy-45 {
    padding-top: 45px;
  }
  .rpt-50,
  .rpy-50 {
    padding-top: 50px;
  }
  .rpt-55,
  .rpy-55 {
    padding-top: 55px;
  }
  .rpt-60,
  .rpy-60 {
    padding-top: 60px;
  }
  .rpt-65,
  .rpy-65 {
    padding-top: 65px;
  }
  .rpt-70,
  .rpy-70 {
    padding-top: 70px;
  }
  .rpt-75,
  .rpy-75 {
    padding-top: 75px;
  }
  .rpt-80,
  .rpy-80 {
    padding-top: 80px;
  }
  .rpt-85,
  .rpy-85 {
    padding-top: 85px;
  }
  .rpt-90,
  .rpy-90 {
    padding-top: 90px;
  }
  .rpt-95,
  .rpy-95 {
    padding-top: 95px;
  }
  .rpt-100,
  .rpy-100 {
    padding-top: 100px;
  }
  .rpt-105,
  .rpy-105 {
    padding-top: 105px;
  }
  .rpt-110,
  .rpy-110 {
    padding-top: 110px;
  }
  .rpt-115,
  .rpy-115 {
    padding-top: 115px;
  }
  .rpt-120,
  .rpy-120 {
    padding-top: 120px;
  }
  .rpt-125,
  .rpy-125 {
    padding-top: 125px;
  }
  .rpt-130,
  .rpy-130 {
    padding-top: 130px;
  }
  .rpt-135,
  .rpy-135 {
    padding-top: 135px;
  }
  .rpt-140,
  .rpy-140 {
    padding-top: 140px;
  }
  .rpt-145,
  .rpy-145 {
    padding-top: 145px;
  }
  .rpt-150,
  .rpy-150 {
    padding-top: 150px;
  }
  /* Padding Right */
  .rpr-0,
  .rpx-0 {
    padding-right: 0px !important;
  }
  .rpr-5,
  .rpx-5 {
    padding-right: 5px !important;
  }
  .rpr-10,
  .rpx-10 {
    padding-right: 10px;
  }
  .rpr-15,
  .rpx-15 {
    padding-right: 15px;
  }
  .rpr-20,
  .rpx-20 {
    padding-right: 20px;
  }
  .rpr-25,
  .rpx-25 {
    padding-right: 25px;
  }
  .rpr-30,
  .rpx-30 {
    padding-right: 30px;
  }
  .rpr-35,
  .rpx-35 {
    padding-right: 35px;
  }
  .rpr-40,
  .rpx-40 {
    padding-right: 40px;
  }
  .rpr-45,
  .rpx-45 {
    padding-right: 45px;
  }
  .rpr-50,
  .rpx-50 {
    padding-right: 50px;
  }
  .rpr-55,
  .rpx-55 {
    padding-right: 55px;
  }
  .rpr-60,
  .rpx-60 {
    padding-right: 60px;
  }
  .rpr-65,
  .rpx-65 {
    padding-right: 65px;
  }
  .rpr-70,
  .rpx-70 {
    padding-right: 70px;
  }
  .rpr-75,
  .rpx-75 {
    padding-right: 75px;
  }
  .rpr-80,
  .rpx-80 {
    padding-right: 80px;
  }
  .rpr-85,
  .rpx-85 {
    padding-right: 85px;
  }
  .rpr-90,
  .rpx-90 {
    padding-right: 90px;
  }
  .rpr-95,
  .rpx-95 {
    padding-right: 95px;
  }
  .rpr-100,
  .rpx-100 {
    padding-right: 100px;
  }
  .rpr-105,
  .rpx-105 {
    padding-right: 105px;
  }
  .rpr-110,
  .rpx-110 {
    padding-right: 110px;
  }
  .rpr-115,
  .rpx-115 {
    padding-right: 115px;
  }
  .rpr-120,
  .rpx-120 {
    padding-right: 120px;
  }
  .rpr-125,
  .rpx-125 {
    padding-right: 125px;
  }
  .rpr-130,
  .rpx-130 {
    padding-right: 130px;
  }
  .rpr-135,
  .rpx-135 {
    padding-right: 135px;
  }
  .rpr-140,
  .rpx-140 {
    padding-right: 140px;
  }
  .rpr-145,
  .rpx-145 {
    padding-right: 145px;
  }
  .rpr-150,
  .rpx-150 {
    padding-right: 150px;
  }
  /* Padding Bottom */
  .rpb-0,
  .rpy-0 {
    padding-bottom: 0px !important;
  }
  .rpb-5,
  .rpy-5 {
    padding-bottom: 5px !important;
  }
  .rpb-10,
  .rpy-10 {
    padding-bottom: 10px;
  }
  .rpb-15,
  .rpy-15 {
    padding-bottom: 15px;
  }
  .rpb-20,
  .rpy-20 {
    padding-bottom: 20px;
  }
  .rpb-25,
  .rpy-25 {
    padding-bottom: 25px;
  }
  .rpb-30,
  .rpy-30 {
    padding-bottom: 30px;
  }
  .rpb-35,
  .rpy-35 {
    padding-bottom: 35px;
  }
  .rpb-40,
  .rpy-40 {
    padding-bottom: 40px;
  }
  .rpb-45,
  .rpy-45 {
    padding-bottom: 45px;
  }
  .rpb-50,
  .rpy-50 {
    padding-bottom: 50px;
  }
  .rpb-55,
  .rpy-55 {
    padding-bottom: 55px;
  }
  .rpb-60,
  .rpy-60 {
    padding-bottom: 60px;
  }
  .rpb-65,
  .rpy-65 {
    padding-bottom: 65px;
  }
  .rpb-70,
  .rpy-70 {
    padding-bottom: 70px;
  }
  .rpb-75,
  .rpy-75 {
    padding-bottom: 75px;
  }
  .rpb-80,
  .rpy-80 {
    padding-bottom: 80px;
  }
  .rpb-85,
  .rpy-85 {
    padding-bottom: 85px;
  }
  .rpb-90,
  .rpy-90 {
    padding-bottom: 90px;
  }
  .rpb-95,
  .rpy-95 {
    padding-bottom: 95px;
  }
  .rpb-100,
  .rpy-100 {
    padding-bottom: 100px;
  }
  .rpb-105,
  .rpy-105 {
    padding-bottom: 105px;
  }
  .rpb-110,
  .rpy-110 {
    padding-bottom: 110px;
  }
  .rpb-115,
  .rpy-115 {
    padding-bottom: 115px;
  }
  .rpb-120,
  .rpy-120 {
    padding-bottom: 120px;
  }
  .rpb-125,
  .rpy-125 {
    padding-bottom: 125px;
  }
  .rpb-130,
  .rpy-130 {
    padding-bottom: 130px;
  }
  .rpb-135,
  .rpy-135 {
    padding-bottom: 135px;
  }
  .rpb-140,
  .rpy-140 {
    padding-bottom: 140px;
  }
  .rpb-145,
  .rpy-145 {
    padding-bottom: 145px;
  }
  .rpb-150,
  .rpy-150 {
    padding-bottom: 150px;
  }
  /* Padding Left */
  .rpl-0,
  .rpx-0 {
    padding-left: 0px !important;
  }
  .rpl-5,
  .rpx-5 {
    padding-left: 5px !important;
  }
  .rpl-10,
  .rpx-10 {
    padding-left: 10px;
  }
  .rpl-15,
  .rpx-15 {
    padding-left: 15px;
  }
  .rpl-20,
  .rpx-20 {
    padding-left: 20px;
  }
  .rpl-25,
  .rpx-25 {
    padding-left: 25px;
  }
  .rpl-30,
  .rpx-30 {
    padding-left: 30px;
  }
  .rpl-35,
  .rpx-35 {
    padding-left: 35px;
  }
  .rpl-40,
  .rpx-40 {
    padding-left: 40px;
  }
  .rpl-45,
  .rpx-45 {
    padding-left: 45px;
  }
  .rpl-50,
  .rpx-50 {
    padding-left: 50px;
  }
  .rpl-55,
  .rpx-55 {
    padding-left: 55px;
  }
  .rpl-60,
  .rpx-60 {
    padding-left: 60px;
  }
  .rpl-65,
  .rpx-65 {
    padding-left: 65px;
  }
  .rpl-70,
  .rpx-70 {
    padding-left: 70px;
  }
  .rpl-75,
  .rpx-75 {
    padding-left: 75px;
  }
  .rpl-80,
  .rpx-80 {
    padding-left: 80px;
  }
  .rpl-85,
  .rpx-85 {
    padding-left: 85px;
  }
  .rpl-90,
  .rpx-90 {
    padding-left: 90px;
  }
  .rpl-95,
  .rpx-95 {
    padding-left: 95px;
  }
  .rpl-100,
  .rpx-100 {
    padding-left: 100px;
  }
  .rpl-105,
  .rpx-105 {
    padding-left: 105px;
  }
  .rpl-110,
  .rpx-110 {
    padding-left: 110px;
  }
  .rpl-115,
  .rpx-115 {
    padding-left: 115px;
  }
  .rpl-120,
  .rpx-120 {
    padding-left: 120px;
  }
  .rpl-125,
  .rpx-125 {
    padding-left: 125px;
  }
  .rpl-130,
  .rpx-130 {
    padding-left: 130px;
  }
  .rpl-135,
  .rpx-135 {
    padding-left: 135px;
  }
  .rpl-140,
  .rpx-140 {
    padding-left: 140px;
  }
  .rpl-145,
  .rpx-145 {
    padding-left: 145px;
  }
  .rpl-150,
  .rpx-150 {
    padding-left: 150px;
  }
  /* Margin Around */
  .rm-0 {
    margin: 0px !important;
  }
  .rm-5 {
    margin: 5px !important;
  }
  .rm-10 {
    margin: 10px;
  }
  .rm-15 {
    margin: 15px;
  }
  .rm-20 {
    margin: 20px;
  }
  .rm-25 {
    margin: 25px;
  }
  .rm-30 {
    margin: 30px;
  }
  .rm-35 {
    margin: 35px;
  }
  .rm-40 {
    margin: 40px;
  }
  .rm-45 {
    margin: 45px;
  }
  .rm-50 {
    margin: 50px;
  }
  .rm-55 {
    margin: 55px;
  }
  .rm-60 {
    margin: 60px;
  }
  .rm-65 {
    margin: 65px;
  }
  .rm-70 {
    margin: 70px;
  }
  .rm-75 {
    margin: 75px;
  }
  .rm-80 {
    margin: 80px;
  }
  .rm-85 {
    margin: 85px;
  }
  .rm-90 {
    margin: 90px;
  }
  .rm-95 {
    margin: 95px;
  }
  .rm-100 {
    margin: 100px;
  }
  .rm-105 {
    margin: 105px;
  }
  .rm-110 {
    margin: 110px;
  }
  .rm-115 {
    margin: 115px;
  }
  .rm-120 {
    margin: 120px;
  }
  .rm-125 {
    margin: 125px;
  }
  .rm-130 {
    margin: 130px;
  }
  .rm-135 {
    margin: 135px;
  }
  .rm-140 {
    margin: 140px;
  }
  .rm-145 {
    margin: 145px;
  }
  .rm-150 {
    margin: 150px;
  }
  /* Margin Top */
  .rmt-0,
  .rmy-0 {
    margin-top: 0px !important;
  }
  .rmt-5,
  .rmy-5 {
    margin-top: 5px !important;
  }
  .rmt-10,
  .rmy-10 {
    margin-top: 10px;
  }
  .rmt-15,
  .rmy-15 {
    margin-top: 15px;
  }
  .rmt-20,
  .rmy-20 {
    margin-top: 20px;
  }
  .rmt-25,
  .rmy-25 {
    margin-top: 25px;
  }
  .rmt-30,
  .rmy-30 {
    margin-top: 30px;
  }
  .rmt-35,
  .rmy-35 {
    margin-top: 35px;
  }
  .rmt-40,
  .rmy-40 {
    margin-top: 40px;
  }
  .rmt-45,
  .rmy-45 {
    margin-top: 45px;
  }
  .rmt-50,
  .rmy-50 {
    margin-top: 50px;
  }
  .rmt-55,
  .rmy-55 {
    margin-top: 55px;
  }
  .rmt-60,
  .rmy-60 {
    margin-top: 60px;
  }
  .rmt-65,
  .rmy-65 {
    margin-top: 65px;
  }
  .rmt-70,
  .rmy-70 {
    margin-top: 70px;
  }
  .rmt-75,
  .rmy-75 {
    margin-top: 75px;
  }
  .rmt-80,
  .rmy-80 {
    margin-top: 80px;
  }
  .rmt-85,
  .rmy-85 {
    margin-top: 85px;
  }
  .rmt-90,
  .rmy-90 {
    margin-top: 90px;
  }
  .rmt-95,
  .rmy-95 {
    margin-top: 95px;
  }
  .rmt-100,
  .rmy-100 {
    margin-top: 100px;
  }
  .rmt-105,
  .rmy-105 {
    margin-top: 105px;
  }
  .rmt-110,
  .rmy-110 {
    margin-top: 110px;
  }
  .rmt-115,
  .rmy-115 {
    margin-top: 115px;
  }
  .rmt-120,
  .rmy-120 {
    margin-top: 120px;
  }
  .rmt-125,
  .rmy-125 {
    margin-top: 125px;
  }
  .rmt-130,
  .rmy-130 {
    margin-top: 130px;
  }
  .rmt-135,
  .rmy-135 {
    margin-top: 135px;
  }
  .rmt-140,
  .rmy-140 {
    margin-top: 140px;
  }
  .rmt-145,
  .rmy-145 {
    margin-top: 145px;
  }
  .rmt-150,
  .rmy-150 {
    margin-top: 150px;
  }
  /* Margin Right */
  .rmr-0,
  .rmx-0 {
    margin-right: 0px !important;
  }
  .rmr-5,
  .rmx-5 {
    margin-right: 5px !important;
  }
  .rmr-10,
  .rmx-10 {
    margin-right: 10px;
  }
  .rmr-15,
  .rmx-15 {
    margin-right: 15px;
  }
  .rmr-20,
  .rmx-20 {
    margin-right: 20px;
  }
  .rmr-25,
  .rmx-25 {
    margin-right: 25px;
  }
  .rmr-30,
  .rmx-30 {
    margin-right: 30px;
  }
  .rmr-35,
  .rmx-35 {
    margin-right: 35px;
  }
  .rmr-40,
  .rmx-40 {
    margin-right: 40px;
  }
  .rmr-45,
  .rmx-45 {
    margin-right: 45px;
  }
  .rmr-50,
  .rmx-50 {
    margin-right: 50px;
  }
  .rmr-55,
  .rmx-55 {
    margin-right: 55px;
  }
  .rmr-60,
  .rmx-60 {
    margin-right: 60px;
  }
  .rmr-65,
  .rmx-65 {
    margin-right: 65px;
  }
  .rmr-70,
  .rmx-70 {
    margin-right: 70px;
  }
  .rmr-75,
  .rmx-75 {
    margin-right: 75px;
  }
  .rmr-80,
  .rmx-80 {
    margin-right: 80px;
  }
  .rmr-85,
  .rmx-85 {
    margin-right: 85px;
  }
  .rmr-90,
  .rmx-90 {
    margin-right: 90px;
  }
  .rmr-95,
  .rmx-95 {
    margin-right: 95px;
  }
  .rmr-100,
  .rmx-100 {
    margin-right: 100px;
  }
  .rmr-105,
  .rmx-105 {
    margin-right: 105px;
  }
  .rmr-110,
  .rmx-110 {
    margin-right: 110px;
  }
  .rmr-115,
  .rmx-115 {
    margin-right: 115px;
  }
  .rmr-120,
  .rmx-120 {
    margin-right: 120px;
  }
  .rmr-125,
  .rmx-125 {
    margin-right: 125px;
  }
  .rmr-130,
  .rmx-130 {
    margin-right: 130px;
  }
  .rmr-135,
  .rmx-135 {
    margin-right: 135px;
  }
  .rmr-140,
  .rmx-140 {
    margin-right: 140px;
  }
  .rmr-145,
  .rmx-145 {
    margin-right: 145px;
  }
  .rmr-150,
  .rmx-150 {
    margin-right: 150px;
  }
  /* Margin Bottom */
  .rmb-0,
  .rmy-0 {
    margin-bottom: 0px !important;
  }
  .rmb-5,
  .rmy-5 {
    margin-bottom: 5px !important;
  }
  .rmb-10,
  .rmy-10 {
    margin-bottom: 10px;
  }
  .rmb-15,
  .rmy-15 {
    margin-bottom: 15px;
  }
  .rmb-20,
  .rmy-20 {
    margin-bottom: 20px;
  }
  .rmb-25,
  .rmy-25 {
    margin-bottom: 25px;
  }
  .rmb-30,
  .rmy-30 {
    margin-bottom: 30px;
  }
  .rmb-35,
  .rmy-35 {
    margin-bottom: 35px;
  }
  .rmb-40,
  .rmy-40 {
    margin-bottom: 40px;
  }
  .rmb-45,
  .rmy-45 {
    margin-bottom: 45px;
  }
  .rmb-50,
  .rmy-50 {
    margin-bottom: 50px;
  }
  .rmb-55,
  .rmy-55 {
    margin-bottom: 55px;
  }
  .rmb-60,
  .rmy-60 {
    margin-bottom: 60px;
  }
  .rmb-65,
  .rmy-65 {
    margin-bottom: 65px;
  }
  .rmb-70,
  .rmy-70 {
    margin-bottom: 70px;
  }
  .rmb-75,
  .rmy-75 {
    margin-bottom: 75px;
  }
  .rmb-80,
  .rmy-80 {
    margin-bottom: 80px;
  }
  .rmb-85,
  .rmy-85 {
    margin-bottom: 85px;
  }
  .rmb-90,
  .rmy-90 {
    margin-bottom: 90px;
  }
  .rmb-95,
  .rmy-95 {
    margin-bottom: 95px;
  }
  .rmb-100,
  .rmy-100 {
    margin-bottom: 100px;
  }
  .rmb-105,
  .rmy-105 {
    margin-bottom: 105px;
  }
  .rmb-110,
  .rmy-110 {
    margin-bottom: 110px;
  }
  .rmb-115,
  .rmy-115 {
    margin-bottom: 115px;
  }
  .rmb-120,
  .rmy-120 {
    margin-bottom: 120px;
  }
  .rmb-125,
  .rmy-125 {
    margin-bottom: 125px;
  }
  .rmb-130,
  .rmy-130 {
    margin-bottom: 130px;
  }
  .rmb-135,
  .rmy-135 {
    margin-bottom: 135px;
  }
  .rmb-140,
  .rmy-140 {
    margin-bottom: 140px;
  }
  .rmb-145,
  .rmy-145 {
    margin-bottom: 145px;
  }
  .rmb-150,
  .rmy-150 {
    margin-bottom: 150px;
  }
  /* Margin Left */
  .rml-0,
  .rmx-0 {
    margin-left: 0px !important;
  }
  .rml-5,
  .rmx-5 {
    margin-left: 5px !important;
  }
  .rml-10,
  .rmx-10 {
    margin-left: 10px;
  }
  .rml-15,
  .rmx-15 {
    margin-left: 15px;
  }
  .rml-20,
  .rmx-20 {
    margin-left: 20px;
  }
  .rml-25,
  .rmx-25 {
    margin-left: 25px;
  }
  .rml-30,
  .rmx-30 {
    margin-left: 30px;
  }
  .rml-35,
  .rmx-35 {
    margin-left: 35px;
  }
  .rml-40,
  .rmx-40 {
    margin-left: 40px;
  }
  .rml-45,
  .rmx-45 {
    margin-left: 45px;
  }
  .rml-50,
  .rmx-50 {
    margin-left: 50px;
  }
  .rml-55,
  .rmx-55 {
    margin-left: 55px;
  }
  .rml-60,
  .rmx-60 {
    margin-left: 60px;
  }
  .rml-65,
  .rmx-65 {
    margin-left: 65px;
  }
  .rml-70,
  .rmx-70 {
    margin-left: 70px;
  }
  .rml-75,
  .rmx-75 {
    margin-left: 75px;
  }
  .rml-80,
  .rmx-80 {
    margin-left: 80px;
  }
  .rml-85,
  .rmx-85 {
    margin-left: 85px;
  }
  .rml-90,
  .rmx-90 {
    margin-left: 90px;
  }
  .rml-95,
  .rmx-95 {
    margin-left: 95px;
  }
  .rml-100,
  .rmx-100 {
    margin-left: 100px;
  }
  .rml-105,
  .rmx-105 {
    margin-left: 105px;
  }
  .rml-110,
  .rmx-110 {
    margin-left: 110px;
  }
  .rml-115,
  .rmx-115 {
    margin-left: 115px;
  }
  .rml-120,
  .rmx-120 {
    margin-left: 120px;
  }
  .rml-125,
  .rmx-125 {
    margin-left: 125px;
  }
  .rml-130,
  .rmx-130 {
    margin-left: 130px;
  }
  .rml-135,
  .rmx-135 {
    margin-left: 135px;
  }
  .rml-140,
  .rmx-140 {
    margin-left: 140px;
  }
  .rml-145,
  .rmx-145 {
    margin-left: 145px;
  }
  .rml-150,
  .rmx-150 {
    margin-left: 150px;
  }
}

/*******************************************************/
/***************** 05. Custom Animation ****************/
/*******************************************************/
/** Header Section Animation **/
.main-header .logo,
.main-menu .navigation > li {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.main-header .logo {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.main-menu .navigation > li:nth-child(1) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.main-menu .navigation > li:nth-child(2) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.main-menu .navigation > li:nth-child(3) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.main-menu .navigation > li:nth-child(4) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.main-menu .navigation > li:nth-child(5) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.main-menu .navigation > li:nth-child(6) {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.main-menu .navigation > li:nth-child(7) {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.main-menu .navigation > li:nth-child(8) {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.main-menu .navigation > li:nth-child(9) {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.main-menu .navigation > li:nth-child(10) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

@media only screen and (max-width: 1199px) {
  .main-menu .navigation > li {
    -webkit-animation: none;
    animation: none;
  }
}

/* Animation Delay */
.delay-1-0s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.delay-2-0s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.delay-0-1s {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.delay-0-2s {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.delay-0-3s {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.delay-0-4s {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.delay-0-5s {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.delay-0-6s {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.delay-0-7s {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.delay-0-8s {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.delay-0-9s {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.delay-1-1s {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.delay-1-2s {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.delay-1-3s {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.delay-1-4s {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.delay-1-5s {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.delay-1-6s {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.delay-1-7s {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

.delay-1-8s {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.delay-1-9s {
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
}

/* Menu Sticky */
@-webkit-keyframes sticky {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}

@keyframes sticky {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}

/* Hero Circle */
@-webkit-keyframes hero_circle {
  0%,
  100% {
    -webkit-transform: translate(-40%, 40%) rotate(0deg);
    transform: translate(-40%, 40%) rotate(0deg);
  }
  50% {
    -webkit-transform: translate(-40%, 40%) rotate(-111deg);
    transform: translate(-40%, 40%) rotate(-111deg);
  }
}
@keyframes hero_circle {
  0%,
  100% {
    -webkit-transform: translate(-40%, 40%) rotate(0deg);
    transform: translate(-40%, 40%) rotate(0deg);
  }
  50% {
    -webkit-transform: translate(-40%, 40%) rotate(-111deg);
    transform: translate(-40%, 40%) rotate(-111deg);
  }
}

/* Preloader */
@-webkit-keyframes animate {
  0%,
  100% {
    -webkit-transform: rotateX(8.75deg) rotateY(35deg);
    transform: rotateX(8.75deg) rotateY(35deg);
  }
  50% {
    -webkit-transform: rotateX(35deg) rotateY(-35deg) rotate(180deg);
    transform: rotateX(35deg) rotateY(-35deg) rotate(180deg);
  }
}
@keyframes animate {
  0%,
  100% {
    -webkit-transform: rotateX(8.75deg) rotateY(35deg);
    transform: rotateX(8.75deg) rotateY(35deg);
  }
  50% {
    -webkit-transform: rotateX(35deg) rotateY(-35deg) rotate(180deg);
    transform: rotateX(35deg) rotateY(-35deg) rotate(180deg);
  }
}

@-webkit-keyframes animate_1 {
  0%,
  100% {
    -webkit-transform: translateZ(25px) rotateX(14deg);
    transform: translateZ(25px) rotateX(14deg);
  }
  33% {
    -webkit-transform: translateZ(-25px) scale(0.4);
    transform: translateZ(-25px) scale(0.4);
  }
  66% {
    -webkit-transform: translateZ(-25px);
    transform: translateZ(-25px);
  }
}

@keyframes animate_1 {
  0%,
  100% {
    -webkit-transform: translateZ(25px) rotateX(14deg);
    transform: translateZ(25px) rotateX(14deg);
  }
  33% {
    -webkit-transform: translateZ(-25px) scale(0.4);
    transform: translateZ(-25px) scale(0.4);
  }
  66% {
    -webkit-transform: translateZ(-25px);
    transform: translateZ(-25px);
  }
}

/* About Image Animation */
@-webkit-keyframes borderRadius1 {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}
@keyframes borderRadius1 {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

@-webkit-keyframes borderRadius2 {
  0% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
  50% {
    border-radius: 50% 60% 30% 60% / 30% 60% 70% 40%;
  }
  100% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
}

@keyframes borderRadius2 {
  0% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
  50% {
    border-radius: 50% 60% 30% 60% / 30% 60% 70% 40%;
  }
  100% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
}

@-webkit-keyframes shapeAnimationOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(0px, 150px) rotate(90deg);
    transform: translate(0px, 150px) rotate(90deg);
  }
  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg);
  }
  75% {
    -webkit-transform: translate(150px, 0px) rotate(270deg);
    transform: translate(150px, 0px) rotate(270deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(0px, 150px) rotate(90deg);
    transform: translate(0px, 150px) rotate(90deg);
  }
  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg);
  }
  75% {
    -webkit-transform: translate(150px, 0px) rotate(270deg);
    transform: translate(150px, 0px) rotate(270deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes shapeAnimationTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(-150px, 0px) rotate(270deg);
    transform: translate(-150px, 0px) rotate(270deg);
  }
  50% {
    -webkit-transform: translate(-150px, -150px) rotate(180deg);
    transform: translate(-150px, -150px) rotate(180deg);
  }
  75% {
    -webkit-transform: translate(0px, -150px) rotate(90deg);
    transform: translate(0px, -150px) rotate(90deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(-150px, 0px) rotate(270deg);
    transform: translate(-150px, 0px) rotate(270deg);
  }
  50% {
    -webkit-transform: translate(-150px, -150px) rotate(180deg);
    transform: translate(-150px, -150px) rotate(180deg);
  }
  75% {
    -webkit-transform: translate(0px, -150px) rotate(90deg);
    transform: translate(0px, -150px) rotate(90deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes shapeAnimationThree {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(50px, 150px) rotate(90deg);
    transform: translate(50px, 150px) rotate(90deg);
  }
  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg);
  }
  75% {
    -webkit-transform: translate(150px, 50px) rotate(270deg);
    transform: translate(150px, 50px) rotate(270deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationThree {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(50px, 150px) rotate(90deg);
    transform: translate(50px, 150px) rotate(90deg);
  }
  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg);
  }
  75% {
    -webkit-transform: translate(150px, 50px) rotate(270deg);
    transform: translate(150px, 50px) rotate(270deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes shapeAnimationFour {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(-150px -50px) rotate(90deg);
    transform: translate(-150px -50px) rotate(90deg);
  }
  50% {
    -webkit-transform: translate(-150px, -150px) rotate(180deg);
    transform: translate(-150px, -150px) rotate(180deg);
  }
  75% {
    -webkit-transform: translate(-50px, -150px) rotate(270deg);
    transform: translate(-50px, -150px) rotate(270deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationFour {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(-150px -50px) rotate(90deg);
    transform: translate(-150px -50px) rotate(90deg);
  }
  50% {
    -webkit-transform: translate(-150px, -150px) rotate(180deg);
    transform: translate(-150px, -150px) rotate(180deg);
  }
  75% {
    -webkit-transform: translate(-50px, -150px) rotate(270deg);
    transform: translate(-50px, -150px) rotate(270deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes shapeAnimationFive {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(-100px -100px) rotate(90deg);
    transform: translate(-100px -100px) rotate(90deg);
  }
  50% {
    -webkit-transform: translate(100px, 50px) rotate(180deg);
    transform: translate(100px, 50px) rotate(180deg);
  }
  75% {
    -webkit-transform: translate(-100px, 150px) rotate(270deg);
    transform: translate(-100px, 150px) rotate(270deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationFive {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    -webkit-transform: translate(-100px -100px) rotate(90deg);
    transform: translate(-100px -100px) rotate(90deg);
  }
  50% {
    -webkit-transform: translate(100px, 50px) rotate(180deg);
    transform: translate(100px, 50px) rotate(180deg);
  }
  75% {
    -webkit-transform: translate(-100px, 150px) rotate(270deg);
    transform: translate(-100px, 150px) rotate(270deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes down-up-one {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    -webkit-transform: rotateX(0deg) translateY(25px);
    transform: rotateX(0deg) translateY(25px);
  }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}

@keyframes down-up-one {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    -webkit-transform: rotateX(0deg) translateY(25px);
    transform: rotateX(0deg) translateY(25px);
  }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}

@-webkit-keyframes down-up-two {
  0% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px);
  }
  50% {
    -webkit-transform: rotateX(0deg) translate(25px, -25px);
    transform: rotateX(0deg) translate(25px, -25px);
  }
  100% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px);
  }
}

@keyframes down-up-two {
  0% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px);
  }
  50% {
    -webkit-transform: rotateX(0deg) translate(25px, -25px);
    transform: rotateX(0deg) translate(25px, -25px);
  }
  100% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px);
  }
}

@-webkit-keyframes moveLeftRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes moveLeftRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes zoomInOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes zoomInOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

/*******************************************************/
/******************* 06. Header style ******************/
/*******************************************************/
.main-header {
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.main-header .header-upper {
  z-index: 5;
  width: 100%;
  position: relative;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.main-header .header-upper .container-fluid {
  padding: 0;
}
@media only screen and (max-width: 991px) {
  .main-header .logo-outer {
    display: none;
  }
}
.main-header .logo {
  z-index: 9;
  padding: 2px 0;
  position: relative;
}
.main-header.fixed-header .header-upper {
  top: 0;
  left: 0;
  position: fixed;
  -webkit-animation: sticky 1s;
  animation: sticky 1s;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
}

.header-top {
  color: #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 991px) {
  .top-left {
    display: none;
  }
}

.top-left ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.top-left ul li {
  margin-right: 50px;
}
@media only screen and (max-width: 1500px) {
  .top-left ul li {
    margin-right: 15px;
  }
}

.top-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
@media only screen and (max-width: 991px) {
  .top-right {
    margin: auto;
  }
}
.top-right .office-time {
  margin-right: 75px;
}
@media only screen and (max-width: 375px) {
  .top-right .office-time {
    display: none;
  }
}
.top-right .office-time i {
  margin-right: 10px;
  color: #104cba;
}
@media only screen and (max-width: 1500px) {
  .top-right .office-time {
    margin-right: 15px;
  }
}

.nav-outer {
  width: 100%;
  position: relative;
}
@media only screen and (max-width: 991px) {
  .nav-outer {
    position: static;
  }
}
.nav-outer .menu-btn {
  margin-left: auto;
}
@media only screen and (max-width: 991px) {
  .nav-outer .menu-btn {
    display: none;
  }
}

/** Header Main Menu **/
@media only screen and (max-width: 991px) {
  .main-menu {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .main-menu .mobile-logo {
    max-width: 150px;
  }
}

@media only screen and (max-width: 991px) {
  .main-menu .collapse {
    overflow: auto;
  }
}

.main-menu .navbar-collapse {
  padding: 0px;
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse > ul {
    margin: 25px 0;
    max-height: 80vh;
    border-bottom: 1px solid #f3f3f3;
  }
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse {
    left: 0;
    width: 100%;
    padding: 0 15px;
    position: absolute;
    background: #ffffff;
    border-bottom: 1px solid #e0e0e0;
  }
}
.main-menu .navbar-collapse li {
  float: left;
  padding: 34px 25px;
}
@media only screen and (max-width: 1500px) {
  .main-menu .navbar-collapse li {
    padding: 34px 16px;
  }
}
@media only screen and (max-width: 1199px) {
  .main-menu .navbar-collapse li {
    padding: 30px 15px;
  }
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li {
    float: none;
    padding: 0 20px;
    background: white;
    border-top: 1px solid #f3f3f3;
  }
}
.main-menu .navbar-collapse li.dropdown .dropdown-btn {
  position: absolute;
  right: 10px;
  top: 0;
  width: 50px;
  height: 43px;
  border-left: 1px solid #f2f2f2;
  text-align: center;
  line-height: 43px;
  cursor: pointer;
  display: none;
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li.dropdown .dropdown-btn {
    display: block;
    background: white;
  }
}
.main-menu .navbar-collapse li a {
  position: relative;
  display: block;
  font-size: 16px;
  opacity: 1;
  font-weight: 500;
  color: #000000;
  text-transform: capitalize;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li a {
    padding: 10px 10px;
    line-height: 22px;
  }
}
.main-menu .navbar-collapse li a:hover,
.main-menu .navbar-collapse li.current a,
.main-menu .navbar-collapse li.current-menu-item a {
  color: #000000;
}
.main-menu .navbar-collapse li .btn-style-two {
  text-decoration: none;
  color: #000000;
}
.main-menu .navbar-collapse li .theme-btn {
  color: white;
  padding: 14px 20px;
  background: #000000;
}
.main-menu .navbar-collapse li .theme-btn:hover {
  color: white;
  background: #000000;
}
.main-menu .navbar-collapse li.for-mega {
  position: static;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li.for-mega {
    position: relative;
  }
}
.main-menu .navbar-collapse li .megamenu {
  position: absolute;
  left: 0px;
  top: 100%;
  width: 100%;
  z-index: 100;
  display: none;
  padding: 20px 0;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}
.main-menu .navbar-collapse li .megamenu:after {
  display: block;
  clear: both;
  content: "";
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li .megamenu {
    position: relative;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
    padding: 0;
  }
  .main-menu .navbar-collapse li .megamenu .container {
    max-width: 100%;
  }
  .main-menu .navbar-collapse li .megamenu .row {
    margin: 0px;
  }
}
.main-menu .navbar-collapse li .megamenu + .dropdown-btn {
  z-index: 100;
  border-bottom: 1px solid #f2f2f2;
}
.main-menu .navbar-collapse li .megamenu ul {
  display: block;
  position: relative;
  top: 0;
  width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.main-menu .navbar-collapse li .megamenu .mega-title {
  margin-left: 20px;
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li .megamenu .mega-title {
    margin-top: 12px;
    margin-left: 25px;
  }
}
.main-menu .navbar-collapse li ul {
  position: absolute;
  left: inherit;
  top: 100%;
  min-width: 220px;
  z-index: 100;
  display: none;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li ul {
    position: relative;
    display: none;
    width: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .main-menu .navbar-collapse li ul:after {
    display: block;
    clear: both;
    content: "";
  }
}
@media only screen and (max-width: 991px) and (max-width: 375px) {
  .main-menu .navbar-collapse li ul {
    min-width: 200px;
  }
}
.main-menu .navbar-collapse li ul li {
  width: 100%;
  padding: 7px 20px;
  border-bottom: 1px solid #f2f2f2;
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li ul li {
    padding: 0 15px;
  }
}
.main-menu .navbar-collapse li ul li ul {
  left: 100%;
  top: 0%;
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li ul li ul {
    left: auto;
  }
}

.main-menu .navbar-header {
  display: none;
}
@media only screen and (max-width: 991px) {
  .main-menu .navbar-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.main-menu .navbar-header .navbar-toggle {
  float: right;
  padding: 4px 0;
  cursor: pointer;
  background: transparent;
}
.main-menu .navbar-header .navbar-toggle .icon-bar {
  background: #000000;
  height: 2px;
  width: 30px;
  display: block;
  margin: 7px 5px;
}

.text-white .navbar-collapse > ul > li > a,
.text-white .navbar-collapse > ul > li > a:hover,
.text-white .navbar-collapse > ul > li.current > a {
  color: white;
}
@media only screen and (max-width: 991px) {
  .text-white .navbar-collapse > ul > li > a,
  .text-white .navbar-collapse > ul > li > a:hover,
  .text-white .navbar-collapse > ul > li.current > a {
    color: black;
  }
}

.text-white .top-right .office-time i {
  color: white;
}

.text-white .navbar-header .navbar-toggle .icon-bar {
  background: white;
}

.text-white .dropdown-btn span {
  color: black;
}

.text-white .mega-title {
  color: #000000;
}

/* Menu Icons */
.menu-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.menu-icons > button {
  margin: 0 15px;
  background: transparent;
}

/* Header Search */
.nav-search {
  position: relative;
}
.nav-search button {
  background: transparent;
}
.nav-search form {
  position: absolute;
  width: 320px;
  top: 100%;
  right: 0;
  z-index: 777;
  padding: 10px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
}
.nav-search form.hide {
  display: none;
}
.nav-search form input {
  border: none;
  padding: 15px 5px 15px 25px;
}
.nav-search form button {
  padding: 15px;
  color: #454545;
  border-left: 1px solid #cfdbf1;
}

/* Header Number */
.header-number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-number > i {
  font-size: 18px;
  margin-right: 20px;
  border: 1px solid #e5e7ef;
  width: 50px;
  height: 50px;
  background: transparent;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
}
.header-number .number-content {
  font-family: "Circular Std";
}
.header-number .number-content span {
  color: #333f7d;
  display: block;
  font-size: 14px;
}
.header-number .number-content a {
  color: #000f5c;
  font-size: 20px;
  text-decoration: underline;
}

/* Header One */
.header-one .logo-outer {
  margin-right: 45px;
}
@media only screen and (max-width: 1199px) {
  .header-one .logo-outer {
    margin-right: 25px;
    padding: 25px;
  }
}

.header-one .header-top {
  padding-left: 85px;
  padding-right: 230px;
}
@media only screen and (max-width: 1199px) {
  .header-one .header-top {
    font-size: 14px;
    padding-left: 25px;
  }
}

.header-one .header-inner {
  padding-right: 230px;
}

@media only screen and (max-width: 1500px) {
  .header-one .header-inner,
  .header-one .header-top {
    padding-right: 85px;
  }
}

@media only screen and (max-width: 1199px) {
  .header-one .header-inner,
  .header-one .header-top {
    padding-right: 25px;
  }
}

/* Header Two */
.header-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #4e597b;
}
.header-two .header-top {
  padding-left: 30px;
  padding-right: 70px;
  border-bottom: 1px solid #4e597b;
}
@media only screen and (max-width: 1500px) {
  .header-two .header-top {
    padding-right: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .header-two .header-top {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 1199px) {
  .header-two .header-top .top-left {
    display: none;
  }
}
@media only screen and (max-width: 1500px) {
  .header-two .header-top .top-left ul li:last-child {
    display: none;
  }
}
.header-two .logo-outer {
  border-right: 1px solid #4e597b;
}
@media only screen and (max-width: 1500px) {
  .header-two .logo-outer {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.header-two .header-upper {
  background: #222f5a;
}
.header-two .header-inner {
  padding-left: 5px;
  padding-right: 70px;
}
@media only screen and (max-width: 1500px) {
  .header-two .header-inner {
    padding: 0 30px 0 15px;
  }
}
.header-two.fixed-header {
  top: 0;
  position: fixed;
  -webkit-animation: sticky 1s;
  animation: sticky 1s;
}
.header-two.fixed-header .header-upper {
  position: relative;
  -webkit-animation: none;
  animation: none;
}
.header-two.fixed-header .header-top {
  display: none;
}
@media only screen and (max-width: 1199px) {
  .header-two .main-menu .navbar-collapse li {
    padding-right: 10px;
  }
}

.header-wrap {
  width: 100%;
}

/* Header Three */
.header-three .header-upper {
  position: absolute;
  border-bottom: 1px solid #485165;
}

.header-three.fixed-header .header-upper {
  background: #222f5a;
}

.header-three .top-left {
  display: block;
}
@media only screen and (max-width: 767px) {
  .header-three .top-left {
    display: none;
  }
}

.header-three .top-right {
  margin-right: 0;
}
@media only screen and (max-width: 767px) {
  .header-three .top-right {
    margin-right: auto;
  }
}
.header-three .top-right .office-time {
  display: block;
  margin-right: 0;
}

.header-three .main-menu {
  margin-left: auto;
}

.header-three .menu-btn {
  margin-left: 15px;
}

/* Header Four */
.header-four .container {
  max-width: 1450px;
}

.header-four .office-time {
  margin-right: 40px;
}
@media only screen and (max-width: 1500px) {
  .header-four .office-time {
    margin-right: 15px;
  }
}

.header-four .header-upper {
  position: absolute;
}

.header-four.fixed-header .header-upper {
  background: #222f5a;
}

@media only screen and (max-width: 1199px) {
  .header-four .nav-search,
  .header-four .top-left li:last-child {
    display: none;
  }
}

.header-four .menu-btn {
  margin-left: 15px;
}

/* Header Five */
.header-five {
  position: absolute;
}
.header-five .container {
  max-width: 1450px;
}
@media only screen and (min-width: 1501px) {
  .header-five .header-top-wrap {
    margin-right: 40px;
  }
}
@media only screen and (min-width: 1501px) {
  .header-five .header-top {
    margin-right: -20px;
    padding-left: 20px;
  }
}
.header-five .office-time {
  margin-right: 40px;
}
@media only screen and (max-width: 1500px) {
  .header-five .office-time {
    margin-right: 15px;
  }
}
.header-five .header-inner {
  position: relative;
  padding-right: 15px;
}
@media only screen and (max-width: 991px) {
  .header-five .header-inner {
    padding-right: 0;
  }
}
.header-five .header-inner:after {
  content: "";
  top: 0;
  right: 0;
  z-index: -1;
  background: white;
  width: 100vw;
  height: 100%;
  position: absolute;
  -webkit-box-shadow: 0px 10px 60px rgba(16, 76, 186, 0.1);
  box-shadow: 0px 10px 60px rgba(16, 76, 186, 0.1);
}
@media only screen and (max-width: 991px) {
  .header-five .header-inner:after {
    right: -15px;
  }
}
.header-five .main-menu .navigation > li {
  padding-top: 26px;
  padding-bottom: 26px;
}
@media only screen and (max-width: 991px) {
  .header-five .main-menu .navigation > li {
    padding: 0;
  }
}
@media only screen and (max-width: 1199px) {
  .header-five .nav-search,
  .header-five .top-left li:last-child {
    display: none;
  }
}
.header-five .menu-btn {
  margin-left: 15px;
}
.header-five.fixed-header .header-upper {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Header Six */
.header-six {
  position: absolute;
}
@media only screen and (min-width: 1501px) {
  .header-six {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 1199px) {
  .header-six .menu-icons {
    display: none;
  }
}
.header-six .menu-icons button {
  color: #2f97f7;
}
.header-six.fixed-header .header-upper {
  background: white;
}
.header-six .main-menu {
  margin-left: auto;
}
@media only screen and (min-width: 1501px) {
  .header-six .main-menu .navbar-collapse li {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.header-six .menu-btn {
  margin-left: 20px;
}

/* Header Seven */
.header-seven {
  position: absolute;
  border-bottom: 1px solid #e5e7ef;
}
.header-seven .header-upper {
  padding-left: 110px;
  padding-right: 80px;
}
@media only screen and (max-width: 1650px) and (min-width: 1500px) {
  .header-seven .header-upper {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (max-width: 1500px) {
  .header-seven .header-upper {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.header-seven .main-menu {
  margin-right: auto;
  margin-left: 100px;
}
@media only screen and (max-width: 1500px) {
  .header-seven .main-menu {
    margin-left: 50px;
  }
}
@media only screen and (max-width: 1199px) {
  .header-seven .main-menu {
    margin-right: 0;
    margin-left: auto;
  }
}
@media only screen and (min-width: 1501px) {
  .header-seven .main-menu .navbar-collapse .navigation > li {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.header-seven .main-menu .navbar-collapse li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media only screen and (max-width: 991px) {
  .header-seven .main-menu .navbar-collapse li {
    display: block;
  }
}
.header-seven .main-menu .navbar-collapse li a {
  font-size: 18px;
  font-weight: 500;
  font-family: "Circular Std";
}
.header-seven .main-menu .navbar-collapse li .dropdown-btn {
  right: 0;
  width: auto;
  height: auto;
  display: block;
  line-height: 1;
  border-left: none;
  position: relative;
  margin-left: 2px;
}
.header-seven .main-menu .navbar-collapse li .dropdown-btn:before {
  content: "+";
  color: #000f5c;
  font-size: 18px;
  font-weight: 500;
  font-family: "Circular Std";
}
@media only screen and (max-width: 991px) {
  .header-seven .main-menu .navbar-collapse li .dropdown-btn {
    top: 0;
    width: 50px;
    height: 42px;
    line-height: 42px;
    position: absolute;
    border-left: 1px solid #f2f2f2;
  }
}
.header-seven .main-menu .navbar-collapse li .dropdown-btn span {
  display: none;
}
@media only screen and (max-width: 991px) {
  .header-seven .main-menu .navbar-collapse li .dropdown {
    display: block;
  }
}
.header-seven .header-number {
  margin-right: 100px;
}
@media only screen and (max-width: 1650px) and (min-width: 1500px) {
  .header-seven .header-number {
    margin-right: 50px;
  }
}
@media only screen and (max-width: 1500px) {
  .header-seven .header-number {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1199px) {
  .header-seven .header-number {
    display: none;
  }
}
.header-seven .menu-btn {
  margin-left: 0;
}
@media only screen and (max-width: 1500px) {
  .header-seven .menu-btn {
    display: none;
  }
}
.header-seven:not(.fixed-header) .header-upper {
  padding-top: 20px;
  padding-bottom: 20px;
}
@media only screen and (max-width: 1500px) {
  .header-seven:not(.fixed-header) .header-upper {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.header-seven.fixed-header .header-upper {
  background: white;
}

/* Header Eight */
.header-eight .container {
  max-width: 1530px;
}

.header-eight .logo-outer {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  z-index: 500;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  margin-top: -66px;
  position: relative;
  background: #f43336;
  border-radius: 0 0 50px 50px;
}
@media only screen and (max-width: 991px) {
  .header-eight .logo-outer {
    margin-top: 0;
    display: block;
    margin-bottom: -15px;
  }
}
.header-eight .logo-outer a {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  padding: 40px 60px;
  display: inline-block;
}
@media only screen and (max-width: 1199px) {
  .header-eight .logo-outer a {
    max-width: 200px;
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (max-width: 991px) {
  .header-eight .logo-outer a {
    max-width: 175px;
    padding: 25px 40px;
  }
}

.header-eight .header-top {
  padding-left: 300px;
}
@media only screen and (max-width: 991px) {
  .header-eight .header-top {
    padding-left: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .header-eight .top-left {
    display: none;
  }
}

.header-eight .top-left li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 30px;
}
.header-eight .top-left li i {
  color: #f43336;
  margin-right: 10px;
}
.header-eight .top-left li:not(:last-child) {
  padding-right: 30px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.header-eight .top-left select {
  padding: 0;
  color: white;
  border: none;
  cursor: pointer;
  background: transparent;
}
.header-eight .top-left select option {
  color: #454545;
}

.header-eight .top-right .hotline {
  margin-right: 30px;
  padding-right: 30px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}
@media only screen and (max-width: 575px) {
  .header-eight .top-right .hotline {
    display: none;
  }
}
.header-eight .top-right .hotline i,
.header-eight .top-right .hotline span {
  margin-right: 5px;
}

.header-eight .top-right .social-style-one i {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.header-eight .top-right .social-style-one a:hover i {
  color: #f43336;
}

.header-eight .menu-btn {
  margin-left: 35px;
}
@media only screen and (max-width: 1500px) {
  .header-eight .menu-btn {
    display: none;
  }
}

@media only screen and (min-width: 991px) {
  .header-eight .main-menu .navbar-collapse .navigation > li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .header-eight .main-menu .navbar-collapse .navigation > li > .dropdown-btn {
    width: auto;
    height: auto;
    right: 0;
    line-height: 1;
    margin-left: 3px;
    border-left: none;
    position: relative;
  }
}

.header-eight .main-menu .navbar-collapse li.dropdown .dropdown-btn {
  display: block;
  font-size: 10px;
}
.header-eight .main-menu .navbar-collapse li.dropdown .dropdown-btn span:before {
  content: "\f067";
}

@media only screen and (max-width: 1500px) {
  .header-eight .nav-search {
    margin-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .header-eight .nav-search {
    display: none;
  }
}

.header-eight .navbar-header button {
  margin-left: auto;
}

.header-eight .mobile-logo {
  max-width: 100px;
}

.header-eight.fixed-header .logo-outer {
  margin-top: 0;
  margin-bottom: -18px;
}
@media only screen and (max-width: 991px) {
  .header-eight.fixed-header .logo-outer {
    margin-bottom: -15px;
  }
}
.header-eight.fixed-header .logo-outer a {
  padding-top: 35px;
  padding-bottom: 35px;
}
@media only screen and (max-width: 991px) {
  .header-eight.fixed-header .logo-outer a {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

/* Header Nine */
.header-nine {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
@media only screen and (min-width: 1501px) {
  .header-nine {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.header-nine.fixed-header .header-upper {
  background: #080f17;
}
.header-nine .container {
  max-width: 1550px;
}
.header-nine .logo-outer {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}
@media only screen and (min-width: 1501px) {
  .header-nine .main-menu {
    margin-right: 50px;
  }
}
.header-nine .main-menu .navbar-collapse li ul,
.header-nine .main-menu .navbar-collapse li .megamenu {
  background: #182633;
}
.header-nine .main-menu .navbar-collapse li a {
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Urbanist", sans-serif;
}
.header-nine .main-menu .navbar-collapse li.dropdown .dropdown-btn {
  display: block;
  font-size: 8px;
  border-bottom: none;
  background: transparent;
  border-color: rgba(255, 255, 255, 0.1);
}
.header-nine .main-menu .navbar-collapse li.dropdown .dropdown-btn span {
  color: white;
}
.header-nine .main-menu .navbar-collapse li.dropdown .dropdown-btn span:before {
  content: "\f067";
}
.header-nine .main-menu .navbar-collapse li ul li {
  border-color: rgba(255, 255, 255, 0.1);
}
@media only screen and (min-width: 991px) {
  .header-nine .main-menu .navbar-collapse .navigation > li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 18px;
    padding-right: 18px;
  }
  .header-nine .main-menu .navbar-collapse .navigation > li > .dropdown-btn {
    width: auto;
    height: auto;
    right: 0;
    top: -1px;
    line-height: 1;
    margin-left: 4px;
    border-left: none;
    position: relative;
  }
}
@media only screen and (max-width: 991px) {
  .header-nine .main-menu .navbar-collapse {
    border: none;
    background: #182633;
  }
  .header-nine .main-menu .navbar-collapse > ul {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .header-nine .main-menu .navbar-collapse > ul li {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.1);
  }
  .header-nine .main-menu .navbar-collapse > ul li > a:hover,
  .header-nine .main-menu .navbar-collapse > ul li.current > a {
    color: #20c997;
  }
}
.header-nine .mega-title {
  color: white;
}
.header-nine .menu-icons > button,
.header-nine .nav-search > button {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.2);
}
.header-nine .menu-icons > button .number,
.header-nine .nav-search > button .number {
  top: -4px;
  right: -3px;
  position: absolute;
  width: 20px;
  height: 20px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(-21.46%, #a146e8),
    color-stop(36.39%, #6c63d0),
    to(#387db8)
  );
  background: -webkit-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: -o-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: linear-gradient(90deg, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
}
@media only screen and (max-width: 1500px) {
  .header-nine .menu-icons,
  .header-nine .nav-search {
    display: none;
  }
}
.header-nine .menu-btn {
  margin-left: 25px;
}
@media only screen and (max-width: 1199px) {
  .header-nine .menu-btn {
    display: none;
  }
}

/*******************************************************/
/******************* 07. Hero Section ******************/
/*******************************************************/
.hero-section {
  z-index: 1;
  overflow: hidden;
  position: relative;
}
.hero-section .left-circle {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  border: 3px solid #39446b;
  width: 400px;
  height: 400px;
  background: transparent;
  line-height: 400px;
  border-radius: 50%;
  text-align: center;
  -webkit-transform: translate(-40%, 40%);
  -ms-transform: translate(-40%, 40%);
  transform: translate(-40%, 40%);
  -webkit-animation: hero_circle 30s infinite;
  animation: hero_circle 30s infinite;
}
@media only screen and (max-width: 1199px) {
  .hero-section .left-circle {
    left: auto;
    right: -150px;
  }
}
.hero-section .left-circle:after {
  position: absolute;
  content: "";
  top: 50%;
  right: -32px;
  width: 65px;
  height: 65px;
  background: #104cba;
  line-height: 65px;
  border-radius: 50%;
  text-align: center;
}

.hero-content .sub-title {
  font-size: 18px;
  font-weight: 500;
}

@media only screen and (max-width: 1199px) {
  .hero-content h1 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .hero-content h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 480px) {
  .hero-content h1 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 375px) {
  .hero-content h1 {
    font-size: 30px;
  }
}

.hero-image {
  max-width: 450px;
  margin-left: -65px;
  position: relative;
}
@media only screen and (max-width: 1199px) {
  .hero-image {
    margin-left: 0;
  }
}
.hero-image img {
  border-radius: 200px 200px 0 0;
}
.hero-image:before {
  content: "";
  width: 100%;
  height: 100%;
  right: -30px;
  position: absolute;
  border-radius: 200px 200px 0 0;
  border: 4px solid #104cba;
}
.hero-image:after {
  content: "";
  right: 0;
  top: 50%;
  opacity: 0.05;
  position: absolute;
  -webkit-transform: translate(50%);
  -ms-transform: translate(50%);
  transform: translate(50%);
  width: 175px;
  height: 175px;
  background: #e5e5e5;
  line-height: 175px;
  border-radius: 50%;
  text-align: center;
}
.hero-image .circle-one {
  width: 90px;
  height: 90px;
  background: #104cba;
  line-height: 90px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  left: 30px;
  top: 15px;
}
.hero-image .circle-two {
  top: 35%;
  right: -45px;
  position: absolute;
  width: 30px;
  height: 30px;
  background: white;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
}

/* Hero Two */
.hero-section-two:before {
  background: #222f5a;
}

/* Hero Three */
.hero-section-three:before {
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#060020), to(transparent));
  background: -webkit-linear-gradient(left, #060020, transparent);
  background: -o-linear-gradient(left, #060020, transparent);
  background: linear-gradient(to right, #060020, transparent);
}

.hero-line-shape {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30%;
  z-index: -1;
}

@media only screen and (max-width: 480px) {
  .hero-section-form {
    padding-left: 25px;
    padding-right: 25px;
  }
}

/* Hero Five*/
.hero-content-five .sub-title {
  font-weight: 500;
  color: #104cba;
}

@media only screen and (min-width: 1501px) {
  .hero-content-five h1 {
    line-height: 1;
    font-size: 85px;
  }
}

@media only screen and (max-width: 991px) {
  .hero-content-five h1 {
    font-size: 62px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-five h1 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 480px) {
  .hero-content-five h1 {
    font-size: 40px;
  }
}

.hero-content-five .list-style-one li {
  font-size: 18px;
  font-weight: 400;
}
@media only screen and (max-width: 767px) {
  .hero-content-five .list-style-one li {
    font-size: 16px;
  }
}
.hero-content-five .list-style-one li:before {
  width: 25px;
  height: 25px;
  margin-top: 0;
  font-size: 14px;
  line-height: 25px;
}

.hero-five-image {
  position: absolute;
  width: 50vw;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
}
@media only screen and (max-width: 575px) {
  .hero-five-image {
    display: none;
  }
}

/* Hero Six */
@media only screen and (min-width: 1501px) {
  .hero-section-six {
    padding-top: 180px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-section-six {
    padding-bottom: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-six {
    padding-bottom: 35px;
  }
}

.hero-content-six .sub-title {
  font-size: 18px;
  text-transform: uppercase;
  color: #2f97f7;
}

.hero-content-six p {
  font-size: 18px;
  line-height: 30px;
}

.hero-content-six h1 {
  text-transform: capitalize;
}
@media only screen and (max-width: 1199px) {
  .hero-content-six h1 {
    font-size: 60px;
  }
}
@media only screen and (max-width: 991px) {
  .hero-content-six h1 {
    font-size: 52px;
  }
}
@media only screen and (max-width: 575px) {
  .hero-content-six h1 {
    font-size: 44px;
  }
}
@media only screen and (max-width: 480px) {
  .hero-content-six h1 {
    font-size: 38px;
  }
}

.hero-content-six .video-play i {
  font-size: 16px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: transparent;
  color: #2f97f7;
  border: 1px solid #2f97f7;
}

.hero-content-six .video-play:hover i {
  color: white;
  background: #2f97f7;
}

.hero-six-images {
  padding-left: 5%;
  position: relative;
}
@media only screen and (min-width: 1501px) {
  .hero-six-images {
    margin-right: -175px;
  }
}
.hero-six-images img {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.hero-six-images img:nth-child(1) {
  width: 96%;
  -webkit-animation: bounce 15s infinite linear;
  animation: bounce 15s infinite linear;
}
.hero-six-images img:nth-child(2) {
  width: 52%;
  display: block;
  margin: -35% 5% 0 auto;
  -webkit-animation: shake 15s infinite linear;
  animation: shake 15s infinite linear;
}
.hero-six-images img:nth-child(3) {
  left: 0;
  width: 39%;
  bottom: 20%;
  position: absolute;
  -webkit-animation: bounce 5s infinite linear;
  animation: bounce 5s infinite linear;
}
.hero-six-images img:nth-child(4) {
  top: 8%;
  right: 0;
  width: 80%;
  z-index: -1;
  position: absolute;
  -webkit-animation: down-up-two 5s infinite linear;
  animation: down-up-two 5s infinite linear;
}

.hero-shapes img {
  z-index: -1;
  position: absolute;
}
.hero-shapes img:nth-child(1) {
  left: 0;
  top: 35%;
  max-width: 10%;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation: down-up-one 5s infinite linear;
  animation: down-up-one 5s infinite linear;
}
.hero-shapes img:nth-child(2) {
  left: 18%;
  bottom: 12%;
  -webkit-animation: shapeAnimationTwo 20s infinite linear;
  animation: shapeAnimationTwo 20s infinite linear;
}
.hero-shapes img:nth-child(3) {
  top: 20%;
  left: 45%;
  -webkit-animation: shapeAnimationThree 20s infinite linear;
  animation: shapeAnimationThree 20s infinite linear;
}
.hero-shapes img:nth-child(4) {
  right: 33%;
  bottom: 12%;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation: shapeAnimationFour 20s infinite linear;
  animation: shapeAnimationFour 20s infinite linear;
}
.hero-shapes img:nth-child(5) {
  top: 15%;
  right: 10%;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation: shapeAnimationFive 20s infinite linear;
  animation: shapeAnimationFive 20s infinite linear;
}

/* Hero Seven */
.hero-section-seven {
  background-position: center;
}
@media only screen and (max-width: 1500px) {
  .hero-section-seven {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 991px) {
  .hero-section-seven {
    padding-bottom: 150px;
  }
}

.hero-content-seven {
  max-width: 585px;
}
@media only screen and (max-width: 1199px) {
  .hero-content-seven {
    max-width: 500px;
  }
}
@media only screen and (max-width: 991px) {
  .hero-content-seven {
    max-width: 635px;
    padding-top: 75px;
    margin-bottom: 35px;
  }
}
.hero-content-seven h1 {
  font-size: 90px;
  font-weight: 500;
  line-height: 1.11;
  margin-bottom: 25px;
}
@media only screen and (max-width: 1500px) {
  .hero-content-seven h1 {
    font-size: 80px;
  }
}
@media only screen and (max-width: 1199px) {
  .hero-content-seven h1 {
    font-size: 70px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-content-seven h1 {
    font-size: 60px;
  }
}
@media only screen and (max-width: 575px) {
  .hero-content-seven h1 {
    font-size: 55px;
  }
}
@media only screen and (max-width: 480px) {
  .hero-content-seven h1 {
    font-size: 50px;
  }
}
@media only screen and (max-width: 375px) {
  .hero-content-seven h1 {
    font-size: 42px;
  }
}
.hero-content-seven p {
  font-size: 20px;
  line-height: 1.7;
}

.hero-right-part {
  position: absolute;
  bottom: 50%;
  width: 45vw;
  right: 0;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}
@media only screen and (max-width: 1199px) {
  .hero-right-part {
    width: 50vw;
  }
}
@media only screen and (max-width: 991px) {
  .hero-right-part {
    width: 90%;
    position: relative;
    -webkit-transform: translate(15px);
    -ms-transform: translate(15px);
    transform: translate(15px);
  }
}
.hero-right-part .data-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 260px;
  background: white;
  padding: 25px 30px;
  position: absolute;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
}
@media only screen and (max-width: 480px) {
  .hero-right-part .data-item {
    max-width: 215px;
    padding: 15px 20px;
  }
}
.hero-right-part .data-item.one {
  left: 15%;
  top: 0;
}
@media only screen and (max-width: 375px) {
  .hero-right-part .data-item.one {
    top: -25px;
  }
}
.hero-right-part .data-item.two {
  left: 0;
  top: 42%;
}
.hero-right-part .data-item.three {
  left: 15%;
  top: 95%;
}
.hero-right-part .data-item img {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  margin-right: 20px;
}
.hero-right-part .data-item h4 {
  margin-bottom: 0;
}
@media only screen and (max-width: 480px) {
  .hero-right-part .data-item h4 {
    font-size: 16px;
  }
}

/* Hero Eight */
.hero-section-eight {
  position: relative;
  z-index: 1;
}
.hero-section-eight:before {
  left: 0;
  top: 0;
  content: "";
  opacity: 0.2;
  width: 500px;
  height: 500px;
  z-index: -1;
  position: absolute;
  -webkit-filter: blur(250px);
  filter: blur(250px);
  background: #20c997;
  -webkit-transform: translate(-25%, -50%);
  -ms-transform: translate(-25%, -50%);
  transform: translate(-25%, -50%);
}

.hero-content-eight h1 {
  font-size: 60px;
}
@media only screen and (max-width: 1199px) {
  .hero-content-eight h1 {
    font-size: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-content-eight h1 {
    font-size: 45px;
  }
}
@media only screen and (max-width: 480px) {
  .hero-content-eight h1 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 375px) {
  .hero-content-eight h1 {
    font-size: 30px;
  }
}

.hero-content-eight p {
  line-height: 2;
  font-size: 18px;
}

.hero-content-eight .hero-btns .theme-btn {
  margin-right: 12px;
}

.hero-eight-image {
  z-index: 1;
  text-align: right;
  position: relative;
  margin-right: -100px;
}
@media only screen and (max-width: 1199px) {
  .hero-eight-image {
    margin-right: 0;
  }
}
@media only screen and (max-width: 991px) {
  .hero-eight-image {
    text-align: center;
  }
}
.hero-eight-image:before {
  right: 0;
  content: "";
  bottom: -20%;
  width: 500px;
  height: 500px;
  z-index: -1;
  opacity: 0.1;
  background: white;
  position: absolute;
  -webkit-filter: blur(100px);
  filter: blur(100px);
}
@media only screen and (max-width: 991px) {
  .hero-eight-image:before {
    right: 50%;
    -webkit-transform: translate(50%);
    -ms-transform: translate(50%);
    transform: translate(50%);
  }
}

.hero-shape-one {
  position: absolute;
  bottom: 15%;
  left: 10%;
  z-index: -1;
  -webkit-animation: shapeAnimationTwo 20s infinite linear;
  animation: shapeAnimationTwo 20s infinite linear;
}

.hero-shape-two {
  position: absolute;
  top: 25%;
  left: 50%;
  z-index: -1;
  -webkit-animation: shapeAnimationThree 20s infinite linear;
  animation: shapeAnimationThree 20s infinite linear;
}

/*******************************************************/
/******************* 08. Page Banner *******************/
/*******************************************************/
.page-banner {
  min-height: 550px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
@media only screen and (max-width: 991px) {
  .page-banner {
    min-height: 450px;
  }
}
@media only screen and (max-width: 767px) {
  .page-banner {
    min-height: 400px;
  }
}
.page-banner:before {
  background: #222f5a;
}
.page-banner .page-title {
  color: white;
  text-transform: capitalize;
}
@media only screen and (max-width: 991px) {
  .page-banner .page-title {
    font-size: 55px;
  }
}
@media only screen and (max-width: 767px) {
  .page-banner .page-title {
    font-size: 45px;
  }
}
@media only screen and (max-width: 375px) {
  .page-banner .page-title {
    font-size: 38px;
  }
}
.page-banner .breadcrumb {
  margin: 0;
  padding: 0;
  background: transparent;
  text-transform: capitalize;
}
.page-banner .breadcrumb .breadcrumb-item {
  color: white;
  font-size: 18px;
  font-weight: 500;
}
.page-banner .breadcrumb .breadcrumb-item a {
  color: white;
}
.page-banner .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "-";
  color: white;
}

/*******************************************************/
/****************** 09. About Section ******************/
/*******************************************************/
.about-section {
  z-index: 1;
  position: relative;
}
.about-section:after {
  content: "";
  height: 90%;
  width: 40%;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: absolute;
  background: url(../images/about/about-bg-shape.png) no-repeat bottom;
}
.about-section.style-four:after,
.about-section.style-five:after {
  background: url(../images/about/about-four-bg-shape.png) no-repeat bottom;
}
.about-section .fact-counter-inner {
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}
@media only screen and (max-width: 991px) {
  .about-section .fact-counter-inner {
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
  }
}

.about-image-shape {
  max-width: 555px;
  position: relative;
  z-index: 1;
}
.about-image-shape:before {
  content: "";
  height: 70%;
  width: 86%;
  left: -9px;
  z-index: -1;
  bottom: 42px;
  position: absolute;
  background: #104cba;
  -webkit-animation: borderRadius1 8s ease-in-out infinite;
  animation: borderRadius1 8s ease-in-out infinite;
  border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
}
.about-image-shape:after {
  content: "";
  height: 97%;
  width: 76%;
  top: 13px;
  left: 13px;
  z-index: -1;
  position: absolute;
  background: #273540;
  -webkit-animation: borderRadius2 8s ease-in-out infinite;
  animation: borderRadius2 8s ease-in-out infinite;
  border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
}

.about-content .list-style-one {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.about-content .list-style-one li {
  width: 49%;
  margin-top: 30px;
}
@media only screen and (max-width: 480px) {
  .about-content .list-style-one li {
    width: 100%;
  }
}

.about-content > i {
  color: black;
  display: block;
  margin-right: 25px;
  margin-bottom: 10px;
}

.about-content .feature-item {
  border: none;
  max-width: 470px;
  padding: 0 0 30px;
  border-bottom: 1px solid #e6e8e9;
}
.about-content .feature-item:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #e6e8e9;
}

/* About Two */
.about-wrap {
  position: relative;
}
.about-wrap:before {
  position: absolute;
  width: 80%;
  height: 100%;
  content: "";
  right: 0;
  top: 75px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
}
@media only screen and (max-width: 991px) {
  .about-wrap:before {
    display: none;
  }
}

.about-two-image,
.about-three-image {
  max-width: 500px;
  position: relative;
}
.about-two-image:before,
.about-three-image:before {
  content: "";
  z-index: -1;
  left: -45px;
  bottom: -35px;
  top:90px;
  position: absolute;
  width: 130px;
  height: 100px;
  /* background: url(../images/about/about-bg-dots.png) no-repeat; */
}
.about-two-image img,
.about-three-image img {
  border-radius: 220px 220px 0 0;
}

/* About Three */
.about-three-image:before {
  bottom: 15%;
}

.about-three-image img {
  width: 80%;
  border-radius: 5px;
}
.about-three-image img:last-child {
  margin: -33% 0 0 10%;
}

/* About Four */
.about-four-images .about-bg-circle {
  z-index: -1;
  margin-top: -45px;
  position: relative;
}

.about-four-images .bg-circle-dtos,
.about-four-images .about-bg-circle {
  max-width: 60%;
}

.about-tab-section .fact-counter-inner {
  -webkit-transform: translateY(-120px);
  -ms-transform: translateY(-120px);
  transform: translateY(-120px);
}
@media only screen and (max-width: 991px) {
  .about-tab-section .fact-counter-inner {
    -webkit-transform: translateY(-95px);
    -ms-transform: translateY(-95px);
    transform: translateY(-95px);
  }
}

.about-tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 2px solid #1a2935;
}
@media only screen and (max-width: 767px) {
  .about-tab {
    border-bottom: none;
  }
}
.about-tab li:last-child a {
  padding-right: 0;
}
.about-tab li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
  padding: 0 20px 25px 0;
}
@media only screen and (max-width: 767px) {
  .about-tab li a {
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
}
.about-tab li a.active i {
  color: #104cba;
}
.about-tab li a.active:before {
  width: 100%;
}
.about-tab li a:before {
  content: "";
  height: 3px;
  width: 0;
  left: 0;
  top: 100%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  background: #104cba;
}
.about-tab li a i {
  font-size: 40px;
  margin-right: 20px;
}
@media only screen and (max-width: 991px) {
  .about-tab li a i {
    font-size: 30px;
    margin-right: 10px;
  }
}
.about-tab li a h3 {
  margin: 0;
}
@media only screen and (max-width: 991px) {
  .about-tab li a h3 {
    font-size: 16px;
  }
}

.about-tab-content {
  padding-top: 65px;
}

/* About Five */
.about-fiver-image .about-bg-circle {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  -webkit-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

/* About Six */
.about-content-six .list-style-one li {
  font-size: 18px;
  font-weight: 400;
}
@media only screen and (max-width: 767px) {
  .about-content-six .list-style-one li {
    font-size: 16px;
  }
}
.about-content-six .list-style-one li:before {
  width: 25px;
  height: 25px;
  margin-top: 0;
  font-size: 14px;
  line-height: 25px;
}

.about-shape-six {
  z-index: 1;
  max-width: 520px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .about-shape-six {
    margin-top: 55px;
  }
}
.about-shape-six .about-graph {
  position: absolute;
  bottom: -5%;
  left: -8%;
  width: 50%;
  -webkit-animation: bounce 15s infinite linear;
  animation: bounce 15s infinite linear;
}
.about-shape-six:before {
  content: "";
  opacity: 0.1;
  z-index: -1;
  top: 0;
  right: 0;
  position: absolute;
  width: 95%;
  height: 95%;
  background: #104cba;
  line-height: 95%;
  border-radius: 50%;
  text-align: center;
  -webkit-animation: down-up-one 5s infinite linear;
  animation: down-up-one 5s infinite linear;
}

/* customization */
.customization-images {
  max-width: 570px;
  position: relative;
}
.customization-images img:nth-child(1) {
  margin-left: -9%;
  margin-right: 11%;
}
.customization-images img:nth-child(2) {
  display: block;
  margin: -48% 0 0 auto;
}
.customization-images:after {
  content: "";
  height: 90%;
  width: 100%;
  top: 10%;
  left: -10%;
  z-index: -1;
  position: absolute;
  background: #f9f6fd;
  -webkit-animation: jello 20s linear infinite;
  animation: jello 20s linear infinite;
  border-radius: 10% 40% 40% 60% / 40% 60% 35% 65%;
}

.customization-content .list-style-three {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.customization-content .list-style-three li {
  width: 48%;
  margin-bottom: 18px;
}
@media only screen and (max-width: 480px) {
  .customization-content .list-style-three li {
    width: 100%;
  }
}
.customization-content .list-style-three li:before {
  font-size: 10px;
  margin-top: 5px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background: white;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  color: #2f97f7;
  border: 1px solid #2f97f7;
}

/* About Seven */
.about-seven .circle-drop {
  top: 25%;
  right: 10%;
  position: absolute;
  width: 20px;
  height: 20px;
  background: #49deff;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  -webkit-animation: zoomInOut 2s linear infinite;
  animation: zoomInOut 2s linear infinite;
}

.about-seven-content .list-style-three li {
  color: black;
  font-weight: 700;
  margin-bottom: 20px;
}
.about-seven-content .list-style-three li:before {
  width: 25px;
  height: 25px;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  color: #2969e6;
  margin-top: 2px;
  border: 1px solid;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
}

/* Solution Place */
@media only screen and (min-width: 1501px) {
  .solution-place-image {
    text-align: right;
    margin-right: -200px;
  }
}

/* About Eight */
.about-eight-images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 550px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
  padding-bottom: 155px;
}
@media only screen and (max-width: 480px) {
  .about-eight-images {
    display: block;
  }
}
.about-eight-images img {
  max-width: 46%;
}
@media only screen and (max-width: 480px) {
  .about-eight-images img {
    max-width: 100%;
  }
}
.about-eight-images img:first-child {
  margin-top: 90px;
  margin-right: 25px;
}
@media only screen and (max-width: 480px) {
  .about-eight-images img:first-child {
    margin-bottom: 30px;
    margin-right: 0;
    margin-top: 0;
  }
}
.about-eight-images .left-content {
  bottom: 0;
  right: 40px;
  overflow: hidden;
  max-width: 320px;
  border-radius: 10px;
  z-index: 1;
  position: relative;
  position: absolute;
  background-size: cover;
}
.about-eight-images .left-content::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.9;
  background-color: #1d52b4;
}
@media only screen and (max-width: 480px) {
  .about-eight-images .left-content {
    right: 0;
  }
}
.about-eight-images .left-content p {
  margin-bottom: 0;
}

.about-eight-content {
  max-width: 475px;
}

.feature-about-bg {
  position: relative;
  z-index: 1;
}
.feature-about-bg:after,
.feature-about-bg:before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  left: 0;
  top: 0;
  z-index: -1;
}
.feature-about-bg:before {
  opacity: 0.05;
  background: url(../images/about/about-eight-bg.png) no-repeat center/cover;
}
.feature-about-bg:after {
  opacity: 0.4;
  background: #f7f7f7;
}

/*******************************************************/
/***************** 10. Services Section ****************/
/*******************************************************/
.service-box {
  margin-bottom: 30px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
.service-box:hover .service-normal {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}
.service-box:hover .service-hover {
  bottom: 0;
}

.service-normal,
.service-hover {
  padding: 25px;
  border-radius: 5px;
}

.service-normal {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid #e7edf8;
}
.service-normal h6 {
  margin-bottom: 15px;
}
.service-normal .btn-circle {
  margin-top: 10px;
  color: #104cba;
  display: inline-block;
  width: 33px;
  height: 33px;
  background: #e5eef6;
  line-height: 33px;
  border-radius: 50%;
  text-align: center;
}

.service-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.service-hover h3 {
  margin-bottom: 20px;
}
.service-hover .theme-btn {
  display: block;
  margin-top: 30px;
  padding: 10px 20px;
  background: #285ec1;
}

.service-normal.style-two {
  text-align: center;
  margin-bottom: 20px;
  padding: 25px 15px 10px;
}

/* Service Style Two */
.service-two-wrap {
  z-index: 1;
  position: relative;
  margin-bottom: -40px;
  -webkit-transform: translateY(-40%);
  -ms-transform: translateY(-40%);
  transform: translateY(-40%);
}
@media only screen and (max-width: 1199px) {
  .service-two-wrap {
    -webkit-transform: translateY(-80px);
    -ms-transform: translateY(-80px);
    transform: translateY(-80px);
  }
}

.service-style-two {
  background: white;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 25px 15px 15px;
}

/* Service Style Three */
.services-three {
  z-index: 1;
  position: relative;
}
.services-three:after {
  content: "";
  height: 90%;
  width: 40%;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: absolute;
  background: url(../images/about/about-bg-shape.png) no-repeat bottom;
}
@media only screen and (max-width: 991px) {
  .services-three:after {
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
  }
}

.service-three-item {
  margin-bottom: 35px;
}
.service-three-item .icon {
  width: 105px;
  height: 105px;
  background: white;
  line-height: 105px;
  border-radius: 50%;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 30px;
}
.service-three-item .icon i {
  margin: 10px 0 0;
}
.service-three-item h4 {
  margin-bottom: 20px;
}
.service-three-item p {
  opacity: 0.6;
}

/* Service Style Four */
.service-item-four {
  z-index: 1;
  position: relative;
  margin-bottom: 30px;
  padding: 0 25px 20px;
}
.service-item-four img {
  width: 100%;
  border-radius: 5px;
}
.service-item-four:before {
  position: absolute;
  content: "";
  height: 90%;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 5px;
  background: white;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
}

.service-four-content {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.service-four-content i {
  font-size: 50px;
  color: #104cba;
}

.service-title-area {
  margin-right: 15px;
}
.service-title-area .category {
  color: #104cba;
}
.service-title-area h3 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  .service-title-area h3 {
    font-size: 18px;
  }
}

/* Service Five */
.who-we-are.overlay:before {
  opacity: 1;
  height: 130%;
  background: #0e1e2a;
}
@media only screen and (max-width: 991px) {
  .who-we-are.overlay:before {
    height: 110%;
  }
}

.service-item-five {
  margin-bottom: 30px;
}
.service-item-five img {
  width: 100%;
}
.service-item-five:hover .service-content-five {
  background: white;
  border-color: white;
}
.service-item-five:hover .service-content-five .icon i {
  color: #104cba;
}
.service-item-five:hover .service-content-five h3 {
  color: #000000;
}
.service-item-five:hover .service-content-five p {
  color: #454545;
}

.service-content-five {
  padding: 35px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid #273540;
}
.service-content-five .icon {
  font-size: 45px;
  margin-bottom: 15px;
}
.service-content-five i,
.service-content-five p,
.service-content-five h3 {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.service-content-five .theme-btn {
  width: 100%;
  margin-top: 8px;
  padding-top: 9px;
  padding-bottom: 9px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* Service Six */
.service-item-six {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 400px;
  margin-bottom: 30px;
}
.service-item-six .icon {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  color: #2f97f7;
  font-size: 25px;
  padding-top: 3px;
  margin-right: 20px;
  width: 60px;
  height: 60px;
  background: #2f97f71f;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
}
.service-item-six .service-content h5 {
  margin-bottom: 15px;
}
.service-item-six:last-child {
  margin-bottom: 0;
}
.service-item-six:last-child .icon {
  color: #9e59ff;
  background: #9e59ff1f;
}

/* Service Seven */
.services-seven {
  background-position: center;
}
.services-seven .circle-drop-one {
  top: 15%;
  left: 10%;
  position: absolute;
  width: 15px;
  height: 15px;
  background: #2969e6;
  line-height: 15px;
  border-radius: 50%;
  text-align: center;
  -webkit-animation: zoomInOut 3s linear infinite;
  animation: zoomInOut 3s linear infinite;
}
.services-seven .circle-drop-two {
  bottom: 10%;
  left: 30%;
  position: absolute;
  width: 10px;
  height: 10px;
  background: #ff7a41;
  line-height: 10px;
  border-radius: 50%;
  text-align: center;
  -webkit-animation: zoomInOut 2s linear infinite;
  animation: zoomInOut 2s linear infinite;
}
.services-seven .service-triangle {
  top: 20%;
  right: 20%;
  position: absolute;
  -webkit-animation: shapeAnimationTwo 20s linear infinite;
  animation: shapeAnimationTwo 20s linear infinite;
}
.services-seven .service-line {
  bottom: 15%;
  right: 10%;
  position: absolute;
  -webkit-animation: down-up-one 5s linear infinite;
  animation: down-up-one 5s linear infinite;
}

.service-item-seven {
  padding: 50px;
  margin-bottom: 30px;
  background: #ffffff;
  -webkit-box-shadow: 0px 20px 70px rgba(0, 15, 92, 0.07);
  box-shadow: 0px 20px 70px rgba(0, 15, 92, 0.07);
}
@media only screen and (max-width: 767px) {
  .service-item-seven {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.service-item-seven h3 {
  font-size: 24px;
  font-weight: 500;
  margin: 30px 0 30px;
}

.more-about-services {
  font-weight: 600;
  text-decoration: underline;
}

/* Service Eight */
.services-eight:after,
.services-eight:before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  left: 0;
  top: 0;
  z-index: -1;
}

.services-eight:before {
  -webkit-filter: brightness(0.5);
  filter: brightness(0.5);
  background: url(../images/services/service-eight-bg.png) no-repeat center/cover;
}

.services-eight:after {
  opacity: 0.95;
  background: #f8f8f8;
}

.service-slider-wrap {
  margin-left: -15px;
  margin-right: -15px;
}
.service-slider-wrap .slick-dots {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.service-slider-wrap .slick-dots li {
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  width: 22px;
  height: 22px;
  background: transparent;
  line-height: 22px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid transparent;
}
.service-slider-wrap .slick-dots li button {
  text-indent: 100px;
  background: transparent;
}
.service-slider-wrap .slick-dots li:before {
  content: "";
  width: 5px;
  height: 5px;
  left: 50%;
  top: 50%;
  position: absolute;
  border-radius: 50%;
  background: #f43336;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.service-slider-wrap .slick-dots li.slick-active {
  border-color: #f43336;
}
.service-slider-wrap .slick-dots li.slick-active:before {
  background: #f43336;
}

.service-item-eight {
  padding: 30px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
  margin-bottom: 30px;
  border-radius: 5px;
  margin-left: 15px;
  margin-right: 15px;
}
.service-item-eight .icon {
  font-size: 50px;
  color: #f43336;
  margin-bottom: 22px;
  width: 100px;
  height: 100px;
  background: #f8f8f8;
  line-height: 100px;
  border-radius: 50%;
  text-align: center;
}
.service-item-eight .icon img {
  display: inline-block;
}
.service-item-eight .icon i:before {
  line-height: inherit;
}
.service-item-eight .learn-more {
  font-weight: 600;
}
.service-item-eight .learn-more i {
  float: right;
  font-size: 16px;
  color: #f43336;
}
.service-item-eight p {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.service-item-eight:hover {
  background: #030a15;
}
.service-item-eight:hover h3 a,
.service-item-eight:hover p {
  color: white;
}
.service-item-eight:hover .learn-more {
  color: #f43336;
}
.service-item-eight:hover .learn-more:hover {
  text-decoration: underline;
}

/* Service Details */
.service-details-content h2,
.service-details-content h3,
.service-details-content h4,
.service-details-content h5,
.service-details-content h6,
.service-details-content p {
  margin-bottom: 15px;
}

.service-details-content h2 {
  font-size: 30px;
  font-weight: 500;
}

.service-details-content h3 {
  font-size: 24px;
  font-weight: 500;
}

.service-details-content .list-style-three {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.service-details-content .list-style-three li {
  width: 45%;
  margin-bottom: 15px;
}
@media only screen and (max-width: 480px) {
  .service-details-content .list-style-three li {
    width: 100%;
  }
}

.service-details-content .row {
  margin-left: -10px;
  margin-right: -10px;
}
.service-details-content .row > div {
  padding-left: 10px;
  padding-right: 10px;
}

/*******************************************************/
/****************** 11. Fact Counter *******************/
/*******************************************************/
.success-item {
  margin-bottom: 50px;
}
.success-item .count-text {
  font-size: 48px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 20px;
  display: inline-block;
}
.success-item .count-text.plus:after {
  content: "+";
}
.success-item .count-text.k:after {
  content: "k";
}
.success-item p {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0;
}
.success-item.style-three {
  margin-bottom: 0;
  padding-top: 80px;
  padding-bottom: 80px;
  border-left: 1px solid #285ec1;
  border-bottom: 1px solid #285ec1;
}

.text-white .count-text {
  color: white;
}

/* Style Two */
.fact-counter-color .success-item .count-text {
  color: #104cba;
}

.fact-counter-color .success-item p {
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .counter-left-content {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.counter-right {
  border-top: 1px solid #285ec1;
}
.counter-right .row {
  margin-left: 0;
  margin-right: 0;
}
.counter-right .row > div {
  padding-left: 0;
  padding-right: 0;
}

/* Success Circle Style */
.success-item.circle-style {
  -webkit-box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.07);
  margin-left: auto;
  margin-right: auto;
  width: 180px;
  height: 180px;
  background: white;
  line-height: 180px;
  border-radius: 50%;
  text-align: center;
  padding: 45px 0;
  line-height: 1;
  border: 10px solid rgba(231, 231, 233, 0.56);
}
.success-item.circle-style .count-text {
  display: block;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #2f97f7;
  font-family: "Rubik", sans-serif;
}
.success-item.circle-style.color-two .count-text {
  color: #104cba;
}
.success-item.circle-style.color-three .count-text {
  color: #9e59ff;
}
.success-item.circle-style.color-four .count-text {
  color: #12b571;
}

/* Counter Four */
.counter-four-inner {
  z-index: 1;
  background: white;
  position: relative;
  padding: 65px 50px 15px;
  -webkit-box-shadow: 0px 4px 70px rgba(0, 15, 92, 0.06);
  box-shadow: 0px 4px 70px rgba(0, 15, 92, 0.06);
}
@media only screen and (max-width: 375px) {
  .counter-four-inner {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.counter-four-inner:after,
.counter-four-inner:before {
  top: 0;
  content: "";
  z-index: -1;
  height: 100%;
  width: 100px;
  position: absolute;
  background-size: 100% 100%;
  background-position: center;
  background-image: url(../images/shapes/counter-side-line.png);
}
@media only screen and (max-width: 991px) {
  .counter-four-inner:after,
  .counter-four-inner:before {
    display: none;
  }
}
.counter-four-inner:before {
  left: 25%;
}
.counter-four-inner:after {
  left: 57%;
}

.success-item.style-four {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.success-item.style-four > i {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  color: #44c5e5;
  margin-right: 20px;
  width: 50px;
  height: 50px;
  background: rgba(68, 197, 229, 0.15);
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
}
.success-item.style-four .count-text {
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-family: "Circular Std";
}
.success-item.style-four .count-text.m-plus:after {
  content: "m+";
}
.success-item.style-four .count-text.percent:after {
  content: "%";
}
.success-item.style-four .normal-text {
  font-size: 16px;
  font-weight: 600;
}

/* Counter Five */
.success-item.style-five {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  z-index: 1;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 130px;
  margin-bottom: 25px;
  border-right: 1px dashed rgba(255, 255, 255, 0.2);
}
@media only screen and (max-width: 1199px) {
  .success-item.style-five {
    padding-right: 0;
    border-right: none;
  }
}
.success-item.style-five .icon {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  font-size: 50px;
  color: #f43336;
  margin-right: 30px;
}
.success-item.style-five .count-text {
  margin-bottom: 15px;
  font-family: "Rubik", sans-serif;
}
.success-item.style-five .count-text.bg-text {
  position: absolute;
  font-size: 120px;
  opacity: 0.1;
  left: 50%;
  top: 50%;
  z-index: -1;
  color: transparent;
  text-transform: uppercase;
  -webkit-text-stroke: 1px white;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.success-item.style-five span:not(.count-text) {
  display: block;
  font-size: 18px;
}

/*******************************************************/
/***************** 12. Feature Section *****************/
/*******************************************************/
.feature-item {
  padding: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
  border-radius: 5px;
  position: relative;
  margin-bottom: 30px;
  border: 2px solid #e6ecf7;
}
.feature-item:hover {
  border-color: white;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
}
@media only screen and (max-width: 375px) {
  .feature-item {
    display: block;
  }
}
.feature-item .icon {
  margin-right: 25px;
}
.feature-item p {
  margin-bottom: 0;
}
.feature-item .learn-more {
  margin-top: 12px;
}
.feature-item .feature-btn {
  width: 45px;
  height: 45px;
  background: white;
  line-height: 45px;
  border-radius: 50%;
  text-align: center;
  color: #104cba;
  position: absolute;
  right: 30px;
  top: -20px;
  -webkit-box-shadow: 10px 0px 60px 0px rgba(16, 76, 186, 0.3);
  box-shadow: 10px 0px 60px 0px rgba(16, 76, 186, 0.3);
}

/* Feature Style Two */
.feature-item-two {
  background: white;
  padding: 40px 35px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.07);
}
.feature-item-two .icon i {
  color: #ff6600;
  font-size: 30px;
  padding-top: 3px;
  display: inline-block;
  width: 70px;
  height: 70px;
  background: #ff47571f;
  line-height: 70px;
  border-radius: 50%;
  text-align: center;
}
.feature-item-two .feature-line {
  width: 80px;
  height: 1px;
  position: relative;
  background: #ff6600;
  margin: 20px 0 15px;
  display: inline-block;
}
.feature-item-two .feature-line .animate-bar {
  height: 100%;
  width: 10px;
  left: -5px;
  top: 0;
  background: white;
  position: absolute;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: moveLeftRight;
  animation-name: moveLeftRight;
}
.feature-item-two p {
  margin-bottom: 0;
}

.feature-item-two.color-two .icon i {
  color: #2f97f7;
  background: #2f97f71f;
}

.feature-item-two.color-two .feature-line {
  background: #2f97f7;
}

.feature-item-two.color-three .icon i {
  color: #12b571;
  background: #12b5711f;
}

.feature-item-two.color-three .feature-line {
  background: #12b571;
}

.feature-item-two.color-four .icon i {
  color: #3938b9;
  background: #3938b91f;
}

.feature-item-two.color-four .feature-line {
  background: #3938b9;
}

/* Feature Three */
.feature-item-three {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 50px;
  border-right: 1px dashed rgba(3, 10, 21, 0.2);
}
@media only screen and (max-width: 1199px) {
  .feature-item-three {
    border-right: none;
  }
}
@media only screen and (max-width: 375px) {
  .feature-item-three {
    display: block;
  }
}
.feature-item-three .icon {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  font-size: 50px;
  color: #f43336;
  margin-right: 25px;
  margin-bottom: 15px;
  width: 100px;
  height: 100px;
  background: white;
  line-height: 100px;
  border-radius: 50%;
  text-align: center;
  -webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.07);
}
.feature-item-three .icon i:before {
  line-height: inherit;
}
.feature-item-three h3 {
  margin-bottom: 6px;
}
.feature-item-three p {
  margin-bottom: 0;
}

/*******************************************************/
/****************** 13. Work Progress ******************/
/*******************************************************/
@media only screen and (max-width: 575px) {
  .work-progress-area .section-title-with-btn {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 575px) {
  .work-progress-area .section-title-with-btn h2 {
    margin-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .work-progress-inner {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.work-progress-item {
  margin: 50px auto 0;
  text-align: center;
  max-width: 190px;
}
.work-progress-item .icon {
  position: relative;
  margin-bottom: 20px;
  display: inline-block;
  width: 130px;
  height: 130px;
  background: #285ec1;
  line-height: 130px;
  border-radius: 50%;
  text-align: center;
}
@media only screen and (max-width: 575px) {
  .work-progress-item .icon {
    width: 100px;
    height: 100px;
    background: #285ec1;
    line-height: 100px;
    border-radius: 50%;
    text-align: center;
  }
}
.work-progress-item .icon i {
  color: white;
  margin: 12px 0 0;
}
@media only screen and (max-width: 575px) {
  .work-progress-item .icon i {
    font-size: 45px;
  }
}
.work-progress-item .icon .progress-step {
  top: 5px;
  right: 5px;
  font-weight: 500;
  position: absolute;
  width: 30px;
  height: 30px;
  background: white;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  color: #104cba;
}

.progress-bg-line {
  position: relative;
  z-index: 1;
}
.progress-bg-line:after {
  position: absolute;
  width: 90%;
  height: 80px;
  content: "";
  top: 50%;
  left: 50%;
  z-index: -1;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  background: url(../images/background/progress-bg-line.png) no-repeat center/cover;
}
@media only screen and (max-width: 991px) {
  .progress-bg-line:after {
    display: none;
  }
}

/* Work Progess Two */
.work-progress-two {
  -webkit-transform: translateY(95px);
  -ms-transform: translateY(95px);
  transform: translateY(95px);
}

.work-progress-inner-two {
  background: white;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
}

.progress-item-two {
  z-index: 1;
  position: relative;
  text-align: center;
  padding: 0 40px 60px;
  border-right: 1px solid #e6e8eb;
}
@media only screen and (max-width: 991px) {
  .progress-item-two {
    border: none;
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .progress-item-two {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.progress-item-two .icon {
  color: white;
  font-size: 60px;
  padding-top: 8px;
  margin: 0 auto -35px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 125px;
  height: 125px;
  background: #104cba;
  line-height: 125px;
  border-radius: 50%;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .progress-item-two .icon {
    width: 100px;
    height: 100px;
    padding-top: 0;
    font-size: 45px;
  }
}
.progress-item-two .learn-more {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}
.progress-item-two .learn-more i {
  color: #104cba;
}
.progress-item-two .progress-step {
  position: absolute;
  font-size: 125px;
  font-weight: 900;
  opacity: 0.06;
  left: 50%;
  z-index: -1;
  bottom: 5px;
  line-height: 1;
  color: #104cba;
  font-family: "Nunito", sans-serif;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}

/* Work Progress Three */
.work-progress-three {
  background-position: 0 100%;
  background-repeat: no-repeat;
}

.progress-item-two.style-two {
  border: none;
  margin-top: -65px;
  padding: 0 15px 30px;
}
.progress-item-two.style-two .progress-step {
  left: 0;
  top: 55px;
  z-index: 1;
  color: #f7f7f7;
  font-weight: 400;
  position: relative;
}
.progress-item-two.style-two .icon {
  background: white;
  margin-bottom: 25px;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.progress-item-two.style-two .icon i {
  color: #104cba;
}
.progress-item-two.style-two .learn-more,
.progress-item-two.style-two .learn-more i {
  color: white;
}
.progress-item-two.style-two p {
  opacity: 0.8;
}

/* Work Progress Four */
.work-progress-area-four .container {
  max-width: 1380px;
}

/* Work Progress Five */
.work-progress-area-five {
  background: #040b11;
}
.work-progress-area-five:before {
  top: 50%;
  left: 50%;
  content: "";
  z-index: -1;
  opacity: 0.1;
  position: absolute;
  width: 600px;
  height: 400px;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  background: #20c997;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.work-progress-item-five {
  padding: 40px;
  border-radius: 5px;
  margin-bottom: 30px;
  background: #1b2429;
  border: 1px solid rgba(255, 255, 255, 0.15);
}
@media only screen and (max-width: 767px) {
  .work-progress-item-five {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .work-progress-item-five {
    margin-top: 0;
  }
}
.work-progress-item-five .icon-number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.work-progress-item-five .icon-number .icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.15);
}
.work-progress-item-five .icon-number .number {
  opacity: 0.1;
  font-size: 48px;
  font-weight: 700;
  font-family: "Urbanist", sans-serif;
}
.work-progress-item-five h3 {
  margin-top: 30px;
  margin-bottom: 8px;
}
.work-progress-item-five p {
  margin-bottom: 0;
}

/*******************************************************/
/***************** 14. Pricing Section *****************/
/*******************************************************/
.pricing-item {
  border-radius: 5px;
  text-align: center;
  margin-bottom: 30px;
  padding: 15px 15px 45px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
}
.pricing-item:hover .pricing-header {
  background-color: #104cba;
  background-image: url(../images/background/pricing-bg.png);
}
.pricing-item:hover .price,
.pricing-item:hover .pricing-time,
.pricing-item:hover .pricing-title {
  color: white;
}
.pricing-item ul {
  margin: 45px 0;
}
.pricing-item ul li {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin-bottom: 18px;
}

.pricing-header {
  padding: 25px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  background-color: #e5eef6;
}
.pricing-header .pricing-title {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.pricing-header .price {
  line-height: 1;
  font-size: 48px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  color: #104cba;
}
.pricing-header .price:before {
  content: "$";
  font-size: 24px;
}
.pricing-header .pricing-time {
  opacity: 0.5;
  color: black;
  margin: 10px 0 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

/* Pricing Style Two */
.pricing-item.style-two {
  overflow: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 50px;
  border: 1px solid #ececec;
  z-index: 1;
  position: relative;
}
.pricing-item.style-two::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: #0e1e2a;
}
.pricing-item.style-two:before {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
}
.pricing-item.style-two:after {
  position: absolute;
  content: "";
  left: 50%;
  top: -200px;
  z-index: -1;
  -webkit-transition: 0.5;
  -o-transition: 0.5;
  transition: 0.5;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  width: 400px;
  height: 400px;
  background: #e5eef6;
  line-height: 400px;
  border-radius: 50%;
  text-align: center;
}
.pricing-item.style-two:hover {
  color: white;
  margin-top: -25px;
  margin-bottom: 30px;
  padding-bottom: 55px;
}
.pricing-item.style-two:hover .pricing-header {
  padding-top: 35px;
  padding-bottom: 35px;
}
.pricing-item.style-two:hover:before {
  opacity: 1;
}
.pricing-item.style-two:hover:after {
  top: -180px;
  background: #104cba;
}
.pricing-item.style-two .pricing-header {
  background: transparent;
}

/* Pricing Style Three */
.pricing-section-three {
  z-index: 1;
  position: relative;
}
.pricing-section-three::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 1;
  background-color: #e5eef6;
}
.pricing-section-three:before {
  top: -40%;
  background-repeat: no-repeat;
  background-image: url(../images/background/price-bg-balls.png);
}
.pricing-section-three .container:after {
  content: "";
  top: 5%;
  z-index: -1;
  right: -100px;
  position: absolute;
  width: 200px;
  height: 200px;
  background: transparent;
  line-height: 200px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #104cba;
}

.pricing-item-three {
  overflow: hidden;
  background: white;
  text-align: center;
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 20px 0 50px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
}
.pricing-item-three:hover .pricing-type {
  top: 0;
  opacity: 1;
}
.pricing-item-three .pricing-type {
  position: absolute;
  font-size: 14px;
  right: 25px;
  top: -110px;
  opacity: 0;
  color: white;
  font-weight: 500;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 17px 1px 27px 0;
  text-transform: uppercase;
  background: #104cba;
  -webkit-writing-mode: vertical-lr;
}
.pricing-item-three .pricing-type:after {
  bottom: -16px;
  left: 2px;
  content: "";
  width: 25px;
  height: 25px;
  background: white;
  position: absolute;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.pricing-item-three .icon i {
  margin-top: 10px;
}
.pricing-item-three ul {
  margin-bottom: 45px;
}
.pricing-item-three ul li {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin-bottom: 18px;
}
.pricing-item-three .pricing-header {
  background: white;
}

/* Price Style Five */
.monthly-yearly {
  padding: 13px 20px;
  background: white;
  border-radius: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.monthly-yearly .custom-control {
  padding-left: 25px;
}
.monthly-yearly label {
  color: #949494;
  font-size: 18px;
  margin-bottom: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  font-weight: 500;
  text-transform: capitalize;
}
.monthly-yearly .custom-control-label::before {
  background-color: #e7edf8;
  border: none;
}
.monthly-yearly .custom-radio .custom-control-input:checked ~ .custom-control-label {
  color: #000000;
}
.monthly-yearly .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #104cba;
  background-image: none;
  border-radius: 50%;
}

/* Price Style Six */
.pricing-item-six {
  padding: 50px 25px;
  margin-bottom: 30px;
  background: #f4f8fb;
}
.pricing-item-six .pricing-title {
  font-weight: 400;
  margin-bottom: 25px;
  color: #2f97f7;
}
.pricing-item-six .price {
  display: block;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 50px;
  color: #000000;
}
.pricing-item-six .price:before {
  content: "$";
}
.pricing-item-six .save-up {
  color: #9e59ff;
  background: white;
  padding: 3px 20px;
  margin-bottom: 50px;
  display: inline-block;
}
.pricing-item-six p {
  color: #6c727d;
  font-size: 18px;
  margin-bottom: 50px;
}
.pricing-item-six.popular-plan {
  margin-top: -50px;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-left: -30px;
  margin-right: -30px;
  background: #104cba;
}
@media only screen and (max-width: 575px) {
  .pricing-item-six.popular-plan {
    margin-left: 0;
    margin-right: 0;
  }
}
.pricing-item-six.popular-plan .price,
.pricing-item-six.popular-plan .pricing-title {
  color: white;
}
.pricing-item-six.popular-plan p {
  color: #ced0d4;
}

/* Price Style Seven */
.pricing-seven {
  background-repeat: no-repeat;
  background-position: center bottom;
}
.pricing-seven .circle-drop {
  top: 30%;
  left: 15%;
  position: absolute;
  width: 10px;
  height: 10px;
  background: #ffc80b;
  line-height: 10px;
  border-radius: 50%;
  text-align: center;
  -webkit-animation: zoomInOut 2s linear infinite;
  animation: zoomInOut 2s linear infinite;
}

.pricing-tab {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.pricing-tab:before {
  content: "";
  width: 50px;
  height: 24px;
  position: absolute;
  background: #2969e6;
  border-radius: 20px;
  left: 58px;
  top: 0;
}
.pricing-tab:after {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  background: white;
  left: 88px;
  top: 5px;
  z-index: 2;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.pricing-tab.for-yearly:after {
  left: 63px;
}
.pricing-tab a {
  line-height: 1;
  padding: 5px 0;
  font-size: 14px;
  font-family: "Circular Std";
}
.pricing-tab a.active {
  color: #2969e6;
}
.pricing-tab a:first-child {
  margin-right: 45px;
}
.pricing-tab a:last-child {
  margin-left: 45px;
}

.pricing-item-seven {
  padding: 50px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 10px 70px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 70px rgba(0, 0, 0, 0.05);
}
@media only screen and (max-width: 480px) {
  .pricing-item-seven {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (max-width: 375px) {
  .pricing-item-seven {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.pricing-item-seven .pricing-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
  padding-bottom: 25px;
  font-family: "Circular Std";
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #e5e7ef;
}
.pricing-item-seven .pricing-top .price {
  color: #000f5c;
  line-height: 1;
  font-size: 48px;
}
.pricing-item-seven .pricing-top .price:before {
  content: "$";
}
.pricing-item-seven .price-top-left h4 {
  line-height: 1;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.pricing-item-seven .price-top-left span {
  color: #000f5c;
  font-size: 14px;
}
.pricing-item-seven .list-style-three {
  margin-bottom: 45px;
}
.pricing-item-seven .list-style-three li {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
.pricing-item-seven .list-style-three li:before {
  color: #000f5c;
  font-size: 12px;
  margin-right: 10px;
  border: 1px solid #e5e7ef;
  width: 25px;
  height: 25px;
  background: transparent;
  line-height: 25px;
  border-radius: 50%;
  text-align: center;
}
.pricing-item-seven .theme-btn.style-six {
  width: 100%;
  color: #000f5c;
  font-weight: 600;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.pricing-item-seven .theme-btn.style-six:hover {
  color: white;
  background: #ff7a41;
  border-color: #ff7a41;
}

/*******************************************************/
/***************** 15. Gallery Section *****************/
/*******************************************************/
.gallery-section:before {
  opacity: 1;
  height: 50%;
  background: #104cba;
}

.gallery-item {
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
}
.gallery-item:hover .gallery-content {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.gallery-item img {
  width: 100%;
}
.gallery-item .gallery-content {
  left: 20px;
  bottom: 20px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  width: calc(100% - 40px);
  padding: 22px 22px 10px;
  background: rgba(0, 0, 0, 0.8);
  -webkit-transform: translateY(calc(100% + 30px));
  -ms-transform: translateY(calc(100% + 30px));
  transform: translateY(calc(100% + 30px));
}
.gallery-item .gallery-content .category {
  font-size: 12px;
  margin-bottom: 5px;
  display: inline-block;
}

/* Gallery Style Two */
.gallery-section-two:before,
.gallery-section-three:before {
  opacity: 1;
  height: 70%;
  background: #e5eef6;
}

.gallery-item.style-two {
  margin-bottom: 30px;
  border-radius: 5px;
}
.gallery-item.style-two .gallery-content {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  width: 100%;
  height: 80%;
  left: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding: 25px 0 25px 30px;
  background: -webkit-gradient(linear, left bottom, left top, from(#222f5a), to(transparent));
  background: -webkit-linear-gradient(bottom, #222f5a, transparent);
  background: -o-linear-gradient(bottom, #222f5a, transparent);
  background: linear-gradient(to top, #222f5a, transparent);
}
.gallery-item.style-two .gallery-content h5 {
  padding-bottom: 15px;
  border-bottom: 1px solid #58657c;
}
.gallery-item.style-two .gallery-content .gallery-content-inner {
  width: 100%;
}

/* Gallery Style Three */
@media only screen and (min-width: 1501px) {
  .gellery-section-title {
    width: 20%;
  }
}

@media only screen and (min-width: 1501px) {
  .gallery-three-wrap {
    left: 20%;
    position: relative;
    margin-top: -325px;
  }
}

@media only screen and (max-width: 1500px) {
  .gallery-three-wrap {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.gallery-carousel-arrow .slick-arrow {
  margin-right: 20px;
  width: 55px;
  height: 55px;
  background: white;
  line-height: 55px;
  border-radius: 50%;
  text-align: center;
  color: #104cba;
}

.gallery-item.style-three {
  padding-left: 15px;
  padding-right: 15px;
}
.gallery-item.style-three:hover .gallery-content {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  background: #104cba;
}
.gallery-item.style-three:hover .gallery-content a {
  color: white;
}
.gallery-item.style-three .gallery-content {
  left: 30px;
  background: white;
  border-radius: 5px;
  width: calc(100% - 60px);
  -webkit-transform: translateY(5px);
  -ms-transform: translateY(5px);
  transform: translateY(5px);
}
.gallery-item.style-three img {
  border-radius: 5px;
}

/* Gallery Style Four */
.gallery-item.style-four {
  margin-bottom: 30px;
}
.gallery-item.style-four .gallery-content {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  opacity: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  background: rgba(14, 30, 42, 0.85);
}
.gallery-item.style-four:hover .gallery-content {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

/* Gallery Style Five */
@media only screen and (max-width: 1500px) {
  .gallery-section-five {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.gallery-section-five .container {
  max-width: 1600px;
}

.gallery-section-five:before {
  opacity: 1;
  height: 120%;
  background-color: #e5eef6;
  background-image: url(../images/gallery/gallery-bg.png);
}

.gallery-item-five {
  position: relative;
  margin-bottom: 30px;
}
.gallery-item-five img {
  width: 100%;
}
.gallery-item-five .gallery-content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: -webkit-gradient(linear, left bottom, left top, from(#060020), to(transparent));
  background: -webkit-linear-gradient(bottom, #060020, transparent);
  background: -o-linear-gradient(bottom, #060020, transparent);
  background: linear-gradient(to top, #060020, transparent);
}
.gallery-item-five .gallery-content h3 {
  margin-bottom: 5px;
}
.gallery-item-five .gallery-content .gallery-btn {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 5px;
  position: absolute;
  right: 22px;
  top: -20px;
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: #104cba;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}
.gallery-item-five:hover .gallery-btn {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

/* Gallery Style Six */
.protect-ready-bg {
  z-index: 3;
}
.protect-ready-bg:after,
.protect-ready-bg:before {
  width: 100%;
  height: 75%;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
}
.protect-ready-bg:before {
  z-index: -2;
  opacity: 0.65;
  background: #f8f8f8;
}
.protect-ready-bg:after {
  z-index: -3;
  opacity: 0.09;
  background: url(../images/protects/protects-bg.png) no-repeat center/cover;
}

.project-section {
  z-index: 1;
  position: relative;
}
.project-section::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 1;
  background-color: #f8f8f8;
}
.project-section:before {
  top: -40%;
  height: 140%;
}
@media only screen and (min-width: 1501px) {
  .project-section {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.project-item {
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
}
.project-item::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: #030a15;
}
.project-item:before {
  z-index: 1;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.project-item img {
  width: 100%;
}
.project-item .project-content {
  left: 20px;
  bottom: 0;
  opacity: 0;
  z-index: 2;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
  position: absolute;
  border-radius: 5px;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  padding: 30px 40px 35px;
  width: calc(100% - 40px);
}
@media only screen and (max-width: 1500px) {
  .project-item .project-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.project-item .project-content h3 {
  font-size: 24px;
  margin-bottom: 2px;
}
@media only screen and (max-width: 375px) {
  .project-item .project-content h3 {
    font-size: 20px;
  }
}
.project-item .project-content .category a {
  color: #f43336;
}
.project-item:hover:before {
  opacity: 0.75;
}
.project-item:hover .project-content {
  opacity: 1;
  bottom: 20px;
}

/* Portfolio Page */
.portfolio-filter {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.portfolio-filter li {
  margin: 3px;
  font-size: 18px;
  cursor: pointer;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  font-weight: 500;
  padding: 3px 18px;
  border-radius: 5px;
  color: #000000;
}
@media only screen and (max-width: 480px) {
  .portfolio-filter li {
    font-size: 16px;
  }
}
.portfolio-filter li:hover,
.portfolio-filter li.current {
  color: white;
  background: #104cba;
}

.portfolio-wrap .gallery-item.style-three {
  padding: 0;
  margin-bottom: 30px;
}

/* Portfolio Details */
.portfolio-details-content h2 {
  font-size: 30px;
  margin-bottom: 15px;
}

.portfolio-details-content p {
  margin-bottom: 30px;
}

.next-prev-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 35px;
  padding-bottom: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #e6ecf7;
  border-bottom: 1px solid #e6ecf7;
}
.next-prev-wrap a {
  margin-bottom: 10px;
  display: inline-block;
}
.next-prev-wrap a span {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 24px;
}
@media only screen and (max-width: 480px) {
  .next-prev-wrap a span {
    font-size: 18px;
  }
}
.next-prev-wrap a i {
  font-size: 18px;
}
@media only screen and (max-width: 480px) {
  .next-prev-wrap a i {
    font-size: 16px;
  }
}

/*******************************************************/
/****************** 16. News Section *******************/
/*******************************************************/
.news-item {
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
}
.news-item img {
  width: 100%;
}

.news-content {
  padding: 22px 22px 2px;
  border-bottom: 1px solid #e3e3e3;
}

.post-meta-item {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 12px;
}
.post-meta-item li {
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin: 0 30px 5px 0;
}
.post-meta-item li:last-child {
  margin-right: 0;
}
.post-meta-item li i {
  margin-right: 10px;
  color: #104cba;
}
.post-meta-item li b {
  font-weight: 500;
}

.news-author {
  font-size: 15px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 15px 22px;
}
.news-author img {
  width: 35px;
  height: 35px;
  background: white;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  margin-right: 15px;
}

/* News Style Two */
.news-section-two {
  background-position: 0 100%;
  background-repeat: no-repeat;
}

.news-item.style-two {
  overflow: visible;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.news-item.style-two .news-content {
  background: white;
  position: relative;
  border-radius: 5px;
  border-bottom: none;
  padding: 0 30px 10px;
  margin: -25px 25px 0 0;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.07);
}
@media only screen and (max-width: 375px) {
  .news-item.style-two .news-content {
    margin-right: 15px;
  }
}
.news-item.style-two .news-content .news-author {
  padding: 0;
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
}

/* News Style Three */
.news-item.style-three {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.news-item.style-three * {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.news-item.style-three .news-content {
  border-bottom: none;
  padding: 33px 30px 18px;
}
@media only screen and (max-width: 375px) {
  .news-item.style-three .news-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.news-item.style-three:hover {
  background: #104cba;
}
.news-item.style-three:hover * {
  color: white;
}
.news-item.style-three:hover p,
.news-item.style-three:hover .post-meta-item a {
  opacity: 0.5;
}

/* News Style Four */
.blog-four-left h3 {
  font-size: 24px;
  font-weight: 400;
}

.blog-carousel {
  margin-left: -15px;
  margin-right: -15px;
}

.blog-style-four {
  margin: 15px;
  border-radius: 10px 10px 0px 0px;
}
.blog-style-four .image img {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}
.blog-style-four .blog-four-content {
  padding: 25px 40px 40px;
  border-radius: 0px 0px 10px 10px;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}
@media only screen and (max-width: 375px) {
  .blog-style-four .blog-four-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.blog-style-four .blog-four-content .date {
  font-size: 14px;
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
}
.blog-style-four .blog-four-content .date i {
  margin-right: 8px;
}
.blog-style-four .blog-four-content h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 375px) {
  .blog-style-four .blog-four-content h3 {
    font-size: 20px;
  }
}

.blog-next-prev button {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-right: 10px;
  width: 60px;
  height: 60px;
  background: white;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
  -webkit-box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
}
.blog-next-prev button:focus,
.blog-next-prev button:hover {
  color: white;
  background: #2969e6;
}

/* News Style Five */
.news-five-item {
  position: relative;
  margin-bottom: 30px;
}
.news-five-item img {
  width: 100%;
  border-radius: 5px;
}
.news-five-item .content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 40px;
  border-radius: 5px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(3, 10, 21, 0.4)), to(#030a15));
  background: -webkit-linear-gradient(top, rgba(3, 10, 21, 0.4) 0%, #030a15 100%);
  background: -o-linear-gradient(top, rgba(3, 10, 21, 0.4) 0%, #030a15 100%);
  background: linear-gradient(180deg, rgba(3, 10, 21, 0.4) 0%, #030a15 100%);
}
@media only screen and (max-width: 1199px) and (min-width: 991px) {
  .news-five-item .content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (max-width: 375px) {
  .news-five-item .content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.news-five-item .content * {
  color: white;
}
.news-five-item .content .date {
  width: 65px;
  height: 65px;
  line-height: 1.2;
  padding-top: 10px;
  text-align: center;
  border-radius: 10px;
  background: #f43336;
  font-family: "Nunito", sans-serif;
  margin: 0px -10px auto auto;
}
.news-five-item .content .date span {
  display: block;
  font-size: 20px;
  font-weight: 700;
}
.news-five-item .content h3 {
  font-size: 24px;
}
@media only screen and (max-width: 375px) {
  .news-five-item .content h3 {
    font-size: 20px;
  }
}
.news-five-item .content .learn-more {
  text-decoration: underline;
}
.news-five-item .content .learn-more i {
  float: right;
  font-size: 16px;
  color: #f43336;
}
.news-five-item .post-meta-item {
  margin-bottom: 10px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(248, 248, 248, 0.1);
}
@media only screen and (max-width: 375px) {
  .news-five-item .post-meta-item {
    display: none;
  }
}
.news-five-item .post-meta-item li {
  font-size: 16px;
}
.news-five-item .post-meta-item li i {
  color: #f43336;
}

/* News Style Six */
.news-six-area {
  z-index: 1;
  position: relative;
  background: #040b11;
}
.news-six-area:before {
  top: 10%;
  left: 0;
  content: "";
  z-index: -1;
  opacity: 0.1;
  position: absolute;
  width: 800px;
  height: 400px;
  -webkit-filter: blur(200px);
  filter: blur(200px);
  background: #20c997;
}

.news-six-item {
  margin-top: 80px;
  border-radius: 5px;
  margin-bottom: 30px;
  background: #1b2429;
  padding: 0 40px 18px;
  border: 1px solid rgba(255, 255, 255, 0.15);
}
@media only screen and (max-width: 767px) {
  .news-six-item {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.news-six-item .image {
  margin: -80px -25px 25px;
}
@media only screen and (max-width: 767px) {
  .news-six-item .image {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.news-six-item .image img {
  width: 100%;
  border-radius: 5px;
}
.news-six-item .post-meta-item {
  margin-top: 15px;
}
.news-six-item .post-meta-item i {
  color: #6c63d0;
  font-size: 16px;
}
.news-six-item .post-meta-item a {
  color: #cacccf;
}

/* Blog Page */
.blog-page-area,
.blog-details-area {
  position: relative;
  z-index: 1;
}
.blog-page-area .container > .row,
.blog-details-area .container > .row {
  margin-left: -30px;
  margin-right: -30px;
}
.blog-page-area .container > .row > div,
.blog-details-area .container > .row > div {
  padding-left: 30px;
  padding-right: 30px;
}

.blog-details-content:after,
.blog-standard-content:after {
  content: "";
  top: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 110vw;
  background: white;
  position: absolute;
}

.blog-header,
.blog-footer {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.blog-header .social-style-one,
.blog-header .social-style-two,
.blog-footer .social-style-one,
.blog-footer .social-style-two {
  font-size: 14px;
  margin-bottom: 15px;
}
.blog-header .social-style-one span,
.blog-header .social-style-two span,
.blog-footer .social-style-one span,
.blog-footer .social-style-two span {
  font-size: 16px;
  margin-right: 15px;
}
.blog-header .social-style-one a:not(:hover),
.blog-footer .social-style-one a:not(:hover) {
  color: #8a8a8a;
}
.blog-header .social-style-two a,
.blog-footer .social-style-two a {
  width: 30px;
  height: 30px;
  opacity: 1;
  color: white;
  line-height: 30px;
  background: #104cba;
}

.blog-footer .popular-tags b {
  font-weight: 500;
  color: #000000;
}

.blog-footer .popular-tags a:after {
  content: ",";
}

.blog-footer .popular-tags a:last-child:after {
  content: "";
}

.blog-standard-item .image,
.blog-details-content .image {
  margin-bottom: 35px;
}
.blog-standard-item .image img,
.blog-details-content .image img {
  width: 100%;
}

.blog-standard-item h3,
.blog-details-content h3 {
  font-size: 30px;
  font-weight: 500;
}
@media only screen and (max-width: 575px) {
  .blog-standard-item h3,
  .blog-details-content h3 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 375px) {
  .blog-standard-item h3,
  .blog-details-content h3 {
    font-size: 20px;
  }
}

.blog-standard-item p,
.blog-details-content p {
  margin-bottom: 25px;
}

.blog-standard-item {
  margin-bottom: 55px;
}
.blog-standard-item .video-blog {
  position: relative;
}
.blog-standard-item .video-blog .video-play {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.blog-standard-item .video-blog .video-play i {
  color: white;
  font-size: 20px;
  width: 100px;
  height: 100px;
  background: #104cba;
  line-height: 100px;
  border-radius: 50%;
  text-align: center;
}
@media only screen and (max-width: 480px) {
  .blog-standard-item .video-blog .video-play i {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}
.blog-standard-item.without-image {
  padding: 35px 25px;
  border: 1px solid #e9e9e9;
}
.blog-standard-item.quote-blog {
  z-index: 1;
  padding: 50px;
  position: relative;
  background: #104cba;
}
@media only screen and (max-width: 375px) {
  .blog-standard-item.quote-blog {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.blog-standard-item.quote-blog h3 {
  margin-bottom: 0;
}
.blog-standard-item.quote-blog h3 a {
  color: white;
}
.blog-standard-item.quote-blog:after {
  position: absolute;
  content: "\f10e";
  font-weight: 700;
  left: 50%;
  top: 50%;
  z-index: -1;
  color: white;
  opacity: 0.25;
  font-size: 100px;
  font-family: "Font Awesome 5 Free";
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* Blog Details */
blockquote {
  font-size: 20px;
  line-height: 1.5;
  padding-left: 30px;
  margin: 30px 0 35px;
  color: #000000;
  border-left: 5px solid #104cba;
}
@media only screen and (max-width: 480px) {
  blockquote {
    font-size: 18px;
    padding-left: 20px;
  }
}
@media only screen and (max-width: 375px) {
  blockquote {
    font-size: 16px;
  }
}
blockquote .author {
  display: block;
  font-size: 16px;
  margin-top: 10px;
  font-weight: 500;
}

.next-prev-blog {
  z-index: 0;
  display: block;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 15px;
  padding: 30px 40px 20px;
  background: #e5eef6;
}
.next-prev-blog h4 {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
@media only screen and (max-width: 375px) {
  .next-prev-blog h4 {
    font-size: 18px;
  }
}
.next-prev-blog:hover {
  background: rgba(16, 76, 186, 0.9);
}
.next-prev-blog:hover h4 {
  color: white;
}

/* Comments */
.comments-wrap {
  max-width: 700px;
}

.comment-title {
  position: relative;
  font-size: 30px;
  font-weight: 500;
}
@media only screen and (max-width: 375px) {
  .comment-title {
    font-size: 25px;
  }
}
.comment-title:after {
  content: "";
  width: 45px;
  height: 2px;
  left: 0;
  position: absolute;
  top: calc(100% + 10px);
  background: #104cba;
}
.comment-title:before {
  content: "";
  width: 5px;
  height: 2px;
  left: 10px;
  z-index: 1;
  background: white;
  position: absolute;
  top: calc(100% + 10px);
}

.comment-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
}
@media only screen and (max-width: 480px) {
  .comment-item {
    display: block;
  }
}
.comment-item .author-image {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  margin: 5px 30px 20px 0;
}
.comment-item .author-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.comment-item .comment-details .name-date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.comment-item .comment-details .name-date h5 {
  font-weight: 500;
  margin-bottom: 5px;
}
.comment-item .comment-details .name-date .date {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 5px 25px;
  display: inline-block;
  color: #104cba;
}
.comment-item .comment-details p {
  margin-bottom: 5px;
}
.comment-item .comment-details .reply {
  font-size: 14px;
  font-weight: 500;
}
.comment-item.child-comment {
  margin-left: 55px;
}
@media only screen and (max-width: 375px) {
  .comment-item.child-comment {
    margin-left: 25px;
  }
}

/* Comment Form */
.comment-form .form-group {
  position: relative;
}
.comment-form .form-group label {
  position: absolute;
  right: 15px;
  top: 12px;
  padding: 5px 10px;
  color: #104cba;
}

/*******************************************************/
/********************* 17. Wedgets *********************/
/*******************************************************/
.widget {
  background: white;
  padding: 35px 30px;
  margin-bottom: 55px;
}
@media only screen and (max-width: 375px) {
  .widget {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.widget:last-child {
  margin-bottom: 0;
}
.widget .list-style-two li {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.widget .list-style-two li:last-child {
  margin-bottom: 0;
}
.widget .list-style-two li span {
  margin-left: 10px;
}

.widget-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 35px;
  position: relative;
}
.widget-title:after {
  content: "";
  width: 45px;
  height: 2px;
  left: 0;
  position: absolute;
  top: calc(100% + 10px);
  background: #104cba;
}
.widget-title:before {
  content: "";
  width: 5px;
  height: 2px;
  left: 10px;
  z-index: 1;
  background: white;
  position: absolute;
  top: calc(100% + 10px);
}

/* Search Widget */
.widget-search {
  padding: 5px 0;
}
.widget-search form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.widget-search form input {
  border: none;
  padding-right: 0;
}
.widget-search form button {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  padding: 5px 15px;
  margin-right: 10px;
  color: #104cba;
  background: transparent;
}

/* Recent Post */
.widget-recent-post .widget-news-item {
  padding-bottom: 25px;
  border-bottom: 1px solid #d7d7d7;
}
.widget-recent-post .widget-news-item:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.widget-recent-post .widget-news-item img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

/* Tag Cloud */
.tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px -10px 0;
}
.tags a {
  color: #8a8a8a;
  padding: 5px 20px;
  border-radius: 5px;
  background: #eef3f9;
  margin: 0 10px 10px 0;
}
.tags a:hover {
  color: white;
  background: #104cba;
}

/* Call Action */
.widget-call-action {
  text-align: center;
  padding: 100px 50px;
  z-index: 1;
  position: relative;
  background: url(../images/news/call-action-widget.jpg) no-repeat center/cover;
}
.widget-call-action::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.85;
  background-color: #104cba;
}
@media only screen and (max-width: 1199px) {
  .widget-call-action {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.widget-call-action h2 {
  color: white;
  font-size: 45px;
  font-weight: 500;
  margin-bottom: 35px;
}
@media only screen and (max-width: 375px) {
  .widget-call-action h2 {
    font-size: 35px;
  }
}
.widget-call-action p {
  color: white;
  margin-bottom: 35px;
}

/* Portfolio Info */
.widget-portfolio-info li {
  margin-bottom: 20px;
}
.widget-portfolio-info li h4 {
  margin-bottom: 5px;
}
.widget-portfolio-info li p {
  font-size: 18px;
  margin-bottom: 0;
}
@media only screen and (max-width: 375px) {
  .widget-portfolio-info li p {
    font-size: 16px;
  }
}
.widget-portfolio-info li:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 375px) {
  .widget-portfolio-info {
    padding-left: 30px;
    padding-right: 30px;
  }
}

/* Service Lise */
.widget-services {
  padding: 0;
}
.widget-services li {
  margin-bottom: 10px;
}
.widget-services li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 18px;
  padding: 20px 30px;
  border-radius: 5px;
  border: 2px solid #e6ecf7;
}
@media only screen and (max-width: 375px) {
  .widget-services li a {
    font-size: 16px;
  }
}
.widget-services li a:after {
  color: #8e959b;
  content: "\f061";
  font-size: 16px;
  font-weight: 700;
  margin-left: auto;
  font-family: "Font Awesome 5 Free";
}
.widget-services li:hover a,
.widget-services li.active a {
  border-color: #104cba;
}

/* Shop Widget */
.widget-product-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
}
.widget-product-item > a {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 80px;
  margin-right: 15px;
  -webkit-box-shadow: 0px 0px 33px 0px rgba(173, 173, 173, 0.28);
  box-shadow: 0px 0px 33px 0px rgba(173, 173, 173, 0.28);
}
.widget-product-item:last-child {
  margin-bottom: 0;
}
.widget-product-item .widget-product-content h6 {
  margin-bottom: 8px;
  text-transform: capitalize;
}
.widget-product-item .widget-product-content .rating {
  margin-bottom: 5px;
}

.shop-price {
  font-size: 14px;
}
.shop-price .price:before,
.shop-price .current-price:before {
  content: "$";
}
.shop-price .price:after,
.shop-price .current-price:after {
  content: ".00";
}
.shop-price .current-price {
  color: #104cba;
}

/* Shop Sidebar */
.shop-sidebar .widget {
  background-color: #eff2f6;
}

.shop-sidebar .widget-search {
  padding: 35px 30px;
}
.shop-sidebar .widget-search form {
  background: white;
  border-radius: 5px;
}
.shop-sidebar .widget-search form input {
  padding-right: 0;
  padding-left: 20px;
}

/*******************************************************/
/****************** 18. Client Logo ********************/
/*******************************************************/
.logo-carousel-wrap .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.logo-carousel-wrap .slick-track .logo-item {
  padding-left: 15px;
  padding-right: 15px;
}
.logo-carousel-wrap .slick-track .logo-item img {
  display: inline-block;
}

/* Logo style two */
.logo-carousel-wrap.style-two {
  -webkit-transform: translateY(-120px);
  -ms-transform: translateY(-120px);
  transform: translateY(-120px);
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
}
@media only screen and (max-width: 991px) {
  .logo-carousel-wrap.style-two {
    -webkit-transform: translateY(-80px);
    -ms-transform: translateY(-80px);
    transform: translateY(-80px);
  }
}

/*******************************************************/
/************** 19. Testimonials Section ***************/
/*******************************************************/
.testimonial-wrap {
  margin-left: -15px;
  margin-right: -15px;
}

.testimonial-item {
  background: white;
  margin-left: 15px;
  margin-right: 15px;
  padding: 40px 45px 20px;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
}
@media only screen and (max-width: 480px) {
  .testimonial-item {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.author-description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 22px;
}
.author-description img {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 80px;
  height: 80px;
  background: white;
  line-height: 80px;
  border-radius: 50%;
  text-align: center;
  margin-right: 22px;
}
@media only screen and (max-width: 375px) {
  .author-description img {
    width: 50px;
    height: 50px;
  }
}
.author-description .designation {
  margin-right: 20px;
}
.author-description .designation h5 {
  margin: 3px 0 6px;
}
.author-description i {
  opacity: 0.5;
  font-size: 50px;
  margin-left: auto;
  color: #104cba;
}
@media only screen and (max-width: 375px) {
  .author-description i {
    display: none;
  }
}

/* Testimonial Two */
.testimonial-section-two .container {
  max-width: 900px;
}

.testimonial-two-wrap {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.testimonial-two-wrap .row {
  margin-left: 0;
  margin-right: 0;
}
.testimonial-two-wrap .row > div {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (max-width: 480px) {
  .testimonial-two-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.testimonial-two-active .author-description {
  margin-top: 22px;
}
.testimonial-two-active .author-description img {
  width: 60px;
  height: 60px;
}
@media only screen and (max-width: 375px) {
  .testimonial-two-active .author-description img {
    width: 50px;
    height: 50px;
  }
}
.testimonial-two-active .author-description .designation span {
  font-weight: 500;
  color: #104cba;
}
@media only screen and (max-width: 375px) {
  .testimonial-two-active .author-description .designation h5 {
    font-size: 16px;
  }
}

.testimonial-two-active .slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}
.testimonial-two-active .slick-dots li {
  width: 15px;
  height: 15px;
  overflow: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-right: 10px;
  border-radius: 50%;
  border: 3px solid #dee7f6;
}
.testimonial-two-active .slick-dots li button {
  background: transparent;
  text-indent: 999px;
}
.testimonial-two-active .slick-dots li.slick-active {
  border-color: #104cba;
}

/* Testimonial Three */
.testimonial-three-wrap {
  overflow: hidden;
  border-radius: 20px;
}

@media only screen and (max-width: 480px) {
  .testimonial-three-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.testimonial-three-active .designation {
  padding-top: 110px;
}

.testimonial-three-thumbs {
  display: inline-block;
  margin-top: -110px;
  margin-bottom: 0;
  bottom: 95px;
}
@media only screen and (max-width: 767px) {
  .testimonial-three-thumbs {
    bottom: 100px;
  }
}
@media only screen and (max-width: 480px) {
  .testimonial-three-thumbs {
    bottom: 120px;
  }
}
.testimonial-three-thumbs img {
  width: 70px;
  height: 70px;
  margin: 10px;
  cursor: pointer;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  border: 2px solid transparent;
}
@media only screen and (max-width: 480px) {
  .testimonial-three-thumbs img {
    width: 50px;
    height: 50px;
  }
}
.testimonial-three-thumbs img.slick-current {
  border-color: #104cba;
}

/* Testimonial Four */
.testimonial-four-active {
  margin-left: -15px;
  margin-right: -15px;
}
.testimonial-four-active.slick-initialized .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.testimonial-four-active.slick-initialized .slick-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.testimonial-four-active .slick-dots {
  margin-top: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media only screen and (max-width: 991px) {
  .testimonial-four-active .slick-dots {
    margin-top: 20px;
  }
}
.testimonial-four-active .slick-dots li {
  margin: 5px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  border-radius: 50%;
  border: 1px solid rgba(0, 15, 92, 0.2);
}
.testimonial-four-active .slick-dots li:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  left: 9px;
  top: 9px;
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  background: #2969e6;
}
.testimonial-four-active .slick-dots li.slick-active {
  border-color: #2969e6;
}
.testimonial-four-active .slick-dots li.slick-active:before {
  opacity: 1;
}
.testimonial-four-active .slick-dots li button {
  text-indent: 100px;
  background-color: transparent;
}

.testimonial-four-item {
  margin: 15px;
  float: none;
  height: auto;
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
}

.testimonial-four-content {
  padding: 50px 40px 40px;
}
@media only screen and (max-width: 375px) {
  .testimonial-four-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.testimonial-four-author {
  margin-top: auto;
  position: relative;
  padding: 0 40px 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media only screen and (max-width: 375px) {
  .testimonial-four-author {
    display: block;
    padding-left: 25px;
    padding-right: 25px;
  }
}
.testimonial-four-author:after {
  top: -15px;
  right: 40px;
  opacity: 0.1;
  color: #000f5c;
  font-weight: 600;
  content: "\f10e";
  font-size: 75px;
  position: absolute;
  font-family: "Font Awesome 5 Free";
}
.testimonial-four-author img {
  width: 50%;
}
.testimonial-four-author h4 {
  margin-bottom: 0;
}
.testimonial-four-author span {
  font-size: 14px;
  font-weight: 600;
}

.testimonial-four-author-designation {
  padding: 45px 0 15px 10px;
}
@media only screen and (max-width: 375px) {
  .testimonial-four-author-designation {
    padding: 20px 0 45px;
  }
}

/* Testimonial Five */
.testimonial-five {
  z-index: 1;
  position: relative;
}
.testimonial-five::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.75;
  background-color: #030a15;
}

.testimonial-five-wrap {
  z-index: 1;
  margin-bottom: -55px;
  -webkit-box-shadow: 0px 10px 70px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 70px rgba(0, 0, 0, 0.1);
}
.testimonial-five-wrap:before {
  top: 50px;
  z-index: -1;
  right: 100px;
  opacity: 0.05;
  line-height: 1;
  color: #030a15;
  content: "\f10e";
  font-weight: 600;
  font-size: 150px;
  position: absolute;
  font-family: "Font Awesome 5 Free";
}
@media only screen and (max-width: 575px) {
  .testimonial-five-wrap:before {
    right: 50px;
    font-size: 100px;
  }
}
@media only screen and (max-width: 575px) {
  .testimonial-five-wrap {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.testimonial-five-wrap .slick-arrow {
  z-index: 1;
  right: 70px;
  bottom: 90px;
  font-size: 20px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  width: 60px;
  height: 60px;
  background: #f8f8f8;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
}
.testimonial-five-wrap .slick-arrow:hover,
.testimonial-five-wrap .slick-arrow:focus {
  color: white;
  background: #f43336;
}
.testimonial-five-wrap .slick-arrow.prev {
  right: 140px;
}
@media only screen and (max-width: 575px) {
  .testimonial-five-wrap .slick-arrow {
    width: 40px;
    height: 40px;
    right: 25px;
    font-size: 18px;
    line-height: 40px;
  }
  .testimonial-five-wrap .slick-arrow.prev {
    right: 75px;
  }
}

.testimonial-five-item p {
  font-size: 20px;
  line-height: 1.7;
}
@media only screen and (max-width: 375px) {
  .testimonial-five-item p {
    font-size: 16px;
  }
}

.testimonial-five-item .author-description {
  margin-bottom: 0;
  padding-top: 25px;
  padding-right: 150px;
}
@media only screen and (max-width: 575px) {
  .testimonial-five-item .author-description {
    padding-right: 100px;
  }
}
@media only screen and (max-width: 480px) {
  .testimonial-five-item .author-description {
    display: block;
  }
}
.testimonial-five-item .author-description img {
  width: 100px;
  height: 100px;
}
@media only screen and (max-width: 480px) {
  .testimonial-five-item .author-description img {
    margin-bottom: 10px;
  }
}
.testimonial-five-item .author-description h3 {
  font-size: 24px;
  margin-bottom: 0;
}
@media only screen and (max-width: 480px) {
  .testimonial-five-item .author-description h3 {
    font-size: 20px;
  }
}
.testimonial-five-item .author-description span {
  font-size: 14px;
  color: #f43336;
}

/* Testimonial Six */
.testimonial-six-item {
  padding: 40px;
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
  background: #1b2429;
  border: 1px solid rgba(255, 255, 255, 0.15);
}
@media only screen and (max-width: 375px) {
  .testimonial-six-item {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.testimonial-six-item .author-description {
  margin-bottom: 0;
  margin-top: 33px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.testimonial-six-item .author-description h3 {
  margin-bottom: 0;
}
@media only screen and (max-width: 375px) {
  .testimonial-six-item .author-description h3 {
    font-size: 18px;
  }
}
.testimonial-six-item .author-description span {
  color: #cacccf;
  font-size: 12px;
}
.testimonial-six-item .author-description img {
  margin-right: 0;
}
.testimonial-six-item:before {
  right: 25%;
  bottom: 22%;
  opacity: 0.1;
  line-height: 1;
  font-size: 55px;
  content: "\f10e";
  font-weight: 600;
  position: absolute;
  font-family: "Font Awesome 5 Free";
}

.testi-author-images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.testi-author-images > * {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 4px solid #1b2429;
}
.testi-author-images > *:not(:first-child) {
  margin-left: -30px;
}
.testi-author-images .plus {
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(16, 76, 186, 0.8);
}

/*******************************************************/
/****************** 20. Team Members *******************/
/*******************************************************/
.team-member {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-align: center;
  margin-bottom: 30px;
}
.team-member .image img {
  border-radius: 5px;
}
.team-member:hover .member-designation {
  color: white;
  background: #104cba;
}
.team-member:hover .member-designation h5 a {
  color: white;
}
.team-member .member-designation {
  padding: 20px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-top: 12px;
  border-radius: 5px;
  background: #e5eef6;
}
.team-member .member-designation h5 {
  margin-bottom: 8px;
}

/* Team Two */
.team-member.style-two .member-designation {
  position: relative;
  margin: -22px 22px 0;
  background: #222f5a;
}

.team-member.style-two:hover {
  margin-bottom: 41px;
}
.team-member.style-two:hover .member-designation {
  margin-top: -33px;
  background: #104cba;
}

/* Team Three */
.team-member-three {
  text-align: center;
}
.team-member-three .image img {
  width: 100%;
}
.team-member-three .member-designation {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 28px;
  margin-left: 30px;
  margin-right: 30px;
}
@media only screen and (max-width: 767px) {
  .team-member-three .member-designation {
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.team-member-three .member-designation h3 {
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .team-member-three .member-designation h3 {
    font-size: 20px;
  }
}
.team-member-three .member-designation .social-style-one {
  opacity: 0;
  display: block;
  margin-top: 10px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.team-member-three .member-designation .social-style-one a {
  margin-right: 30px;
}
.team-member-three .member-designation .social-style-one a:last-child {
  margin-right: 0;
}
.team-member-three:hover .member-designation {
  background: white;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-box-shadow: -11px 12px 33px 0px rgba(32, 88, 191, 0.08);
  box-shadow: -11px 12px 33px 0px rgba(32, 88, 191, 0.08);
}
.team-member-three:hover .social-style-one {
  opacity: 1;
}

/* Team Four */
.team-member-four {
  background: white;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.07);
}
.team-member-four .image {
  overflow: hidden;
  z-index: 1;
  position: relative;
}
.team-member-four .image::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 1;
  background-color: #0a1426;
}
.team-member-four .image img {
  width: 100%;
}
.team-member-four .image:before {
  height: 0;
  z-index: 1;
  top: auto;
  bottom: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: -webkit-gradient(linear, left bottom, left top, from(#0a1426), to(transparent));
  background: -webkit-linear-gradient(bottom, #0a1426, transparent);
  background: -o-linear-gradient(bottom, #0a1426, transparent);
  background: linear-gradient(to top, #0a1426, transparent);
}
.team-member-four .social-style-two {
  position: absolute;
  left: 0;
  z-index: 2;
  width: 100%;
  bottom: -20px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.team-member-four .social-style-two a {
  opacity: 1;
  background: white;
  color: #000000;
  margin-left: 5px;
  margin-right: 5px;
}
.team-member-four .social-style-two a:hover {
  color: white;
  background: #2f97f7;
}
.team-member-four .member-designation {
  padding: 25px;
}
.team-member-four .member-designation h5 {
  margin-bottom: 8px;
  text-transform: capitalize;
}
.team-member-four:hover .social-style-two {
  bottom: 30px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.team-member-four:hover .image:before {
  height: 100%;
}

/* Team Profile */
.team-profile-content .name {
  margin-bottom: 5px;
}
@media only screen and (max-width: 480px) {
  .team-profile-content .name {
    font-size: 30px;
  }
}
@media only screen and (max-width: 375px) {
  .team-profile-content .name {
    font-size: 25px;
  }
}

.team-profile-content .designation {
  font-size: 22px;
  display: inline-block;
  margin-bottom: 35px;
}
@media only screen and (max-width: 375px) {
  .team-profile-content .designation {
    font-size: 18px;
  }
}

.team-profile-content .list-style-three {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.team-profile-content .list-style-three li {
  width: 45%;
  margin-bottom: 15px;
}
@media only screen and (max-width: 480px) {
  .team-profile-content .list-style-three li {
    width: 100%;
  }
}

.team-profile-content .social-style-two a {
  opacity: 1;
  margin-right: 20px;
  color: #000000;
  background: #e5eef6;
}
.team-profile-content .social-style-two a:hover {
  color: white;
  background: #104cba;
}

/*******************************************************/
/**************** 21. Subscribe Section ****************/
/*******************************************************/
.subscribe-form {
  padding: 2px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 2px solid #88a6dd;
}
@media only screen and (max-width: 480px) {
  .subscribe-form {
    display: block;
  }
}
.subscribe-form input {
  border: none;
  color: white;
  opacity: 0.8;
  background: transparent;
  padding: 12px 10px 12px 30px;
}
.subscribe-form input::-webkit-input-placeholder {
  color: white;
  opacity: 0.8;
}
.subscribe-form input:-ms-input-placeholder {
  color: white;
  opacity: 0.8;
}
.subscribe-form input::-ms-input-placeholder {
  color: white;
  opacity: 0.8;
}
.subscribe-form input::placeholder {
  color: white;
  opacity: 0.8;
}
.subscribe-form button {
  font-weight: 500;
  background: white;
  border-radius: 5px;
  padding: 11px 45px;
  color: #000000;
}
@media only screen and (max-width: 480px) {
  .subscribe-form button {
    width: 100%;
  }
}

/* Subscribe Form Two */
.subscribe-form-two {
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: white;
  border-radius: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 480px) {
  .subscribe-form-two {
    display: block;
    margin-right: 0;
  }
}
.subscribe-form-two input {
  border: none;
  background: transparent;
}
.subscribe-form-two .theme-btn {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  padding: 13px 28px;
  background: #ff6600;
  margin-right: -50px;
}
@media only screen and (max-width: 480px) {
  .subscribe-form-two .theme-btn {
    width: 100%;
    margin-right: 0;
  }
}

.subscribe-two .shape-dots,
.subscribe-two .shape-stones {
  width: 15%;
  max-width: 85px;
  position: absolute;
}

.subscribe-two .shape-dots {
  top: 50px;
  right: 10%;
  -webkit-animation: bounce 10s infinite linear;
  animation: bounce 10s infinite linear;
}

.subscribe-two .shape-stones {
  -webkit-animation: shake 15s infinite linear;
  animation: shake 15s infinite linear;
}

/*******************************************************/
/***************** 22. Call To Action ******************/
/*******************************************************/
.call-to-action .section-title h2 {
  font-size: 30px;
}
@media only screen and (max-width: 375px) {
  .call-to-action .section-title h2 {
    font-size: 25px;
  }
}

/* Call Action Two */
@media only screen and (min-width: 1501px) {
  .call-to-action-two .section-title h2 {
    font-size: 48px;
  }
}

/* Call Action Three */
.call-action-three {
  z-index: 1;
  position: relative;
}
.call-action-three::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.95;
  background-color: #f43336;
}
.call-action-three h2 {
  font-size: 30px;
  text-transform: uppercase;
}
@media only screen and (max-width: 375px) {
  .call-action-three h2 {
    font-size: 25px;
  }
}

/* Footer Call to Action */
.footer-call-action-inner {
  padding-left: 60px;
  padding-right: 60px;
}
@media only screen and (max-width: 480px) {
  .footer-call-action-inner {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.footer-call-action-content h2 {
  font-size: 30px;
}
@media only screen and (max-width: 375px) {
  .footer-call-action-content h2 {
    font-size: 25px;
  }
}

/* Call Action Four */
.call-action-four {
  background-size: cover;
}
@media only screen and (max-width: 575px) {
  .call-action-four {
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media only screen and (max-width: 375px) {
  .call-action-four {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.call-action-four .section-title {
  max-width: 700px;
}
.call-action-four .right-image {
  z-index: -1;
  right: -10px;
  bottom: -40px;
  max-width: 40%;
  position: absolute;
}

/*******************************************************/
/**************** 23. Contact Section ******************/
/*******************************************************/

.contact-form-error {
  color: red;
}

.contact-form-success {
  color: green;
}

.contact-section-inner {
  -webkit-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transform: translateY(-100px);
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 480px) {
  .contact-section-inner {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.contact-section-inner > .row {
  margin-left: -22.5px;
  margin-right: -22.5px;
}
.contact-section-inner > .row > div {
  padding-left: 22.5px;
  padding-right: 22.5px;
}

.contact-image-number {
  position: relative;
  height: 100%;
  overflow: hidden;
  min-height: 400px;
  border-radius: 5px;
}
.contact-image-number .contact-number {
  position: absolute;
  left: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 50%;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: center;
  padding-bottom: 30px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#104cba), to(transparent));
  background-image: -webkit-linear-gradient(bottom, #104cba, transparent);
  background-image: -o-linear-gradient(bottom, #104cba, transparent);
  background-image: linear-gradient(to top, #104cba, transparent);
}
.contact-image-number .contact-number i {
  margin-right: 15px;
  width: 40px;
  height: 40px;
  background: white;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  color: #104cba;
}
.contact-image-number .contact-number a {
  color: white;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 7px;
}
@media only screen and (max-width: 375px) {
  .contact-image-number .contact-number a {
    font-size: 16px;
  }
}
.contact-image-number.style-two {
  padding: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media only screen and (max-width: 480px) {
  .contact-image-number.style-two {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
  }
  .contact-image-number.style-two h5 a {
    word-break: break-all;
  }
}
.contact-image-number.style-two:before {
  opacity: 0.9;
  background-color: #104cba;
}
.contact-image-number.style-two h3 {
  font-size: 30px;
}
@media only screen and (max-width: 480px) {
  .contact-image-number.style-two h3 {
    font-size: 25px;
  }
}
.contact-image-number .contact-info li {
  margin-top: 35px;
}
.contact-image-number .contact-info li i {
  color: white;
  background: rgba(255, 255, 255, 0.25);
}
.contact-image-number .contact-info li span {
  opacity: 0.7;
}

/* Contact Two */
.contact-two .contact-section-inner {
  margin-top: -50px;
  -webkit-transform: translateY(130px);
  -ms-transform: translateY(130px);
  transform: translateY(130px);
  -webkit-box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.07);
}
@media only screen and (max-width: 375px) {
  .contact-two .contact-section-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 991px) {
  .contact-two .contact-section-inner {
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
  }
}

/* Contact Three */
.contact-section-three .container {
  max-width: 1380px;
}

@media only screen and (max-width: 375px) {
  .contact-form-three {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.contact-form-three button {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

/* Contact Page */
.contact-info-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 55px 0 25px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 10px solid #f1f1f1;
}

.contact-info-item {
  width: 33.33%;
  text-align: center;
  margin-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  border-right: 5px solid #f1f1f1;
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .contact-info-item {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .contact-info-item {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 480px) {
  .contact-info-item {
    width: 100%;
    border-right: none;
  }
}
.contact-info-item:last-child {
  border-right: none;
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .contact-info-item:nth-child(2) {
    border-right: none;
  }
}
@media only screen and (max-width: 767px) {
  .contact-info-item:nth-child(2) {
    border-right: none;
  }
}
.contact-info-item i {
  font-size: 40px;
  margin-bottom: 15px;
  display: inline-block;
  color: #104cba;
}
.contact-info-item p {
  color: #8a8a8a;
  font-size: 18px;
  font-weight: 500;
  max-width: 235px;
  margin-left: auto;
  margin-right: auto;
}
.contact-info-item p a {
  color: #8a8a8a;
}

.contact-form-left {
  z-index: 1;
  position: relative;
  min-height: 400px;
}
.contact-form-left::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.6;
  background-color: #104cba;
}
.contact-form-left h2 {
  width: 80%;
  left: 10%;
  color: white;
  bottom: 40px;
  font-size: 40px;
  padding-left: 30px;
  position: absolute;
  border-left: 5px solid white;
}
@media only screen and (max-width: 375px) {
  .contact-form-left h2 {
    font-size: 35px;
  }
}

.contact-page-map {
  margin-bottom: -10px;
}
@media only screen and (max-width: 1199px) {
  .contact-page-map iframe {
    height: 500px;
  }
}
@media only screen and (max-width: 767px) {
  .contact-page-map iframe {
    height: 400px;
  }
}

/*******************************************************/
/******************** 24. Shop Page ********************/
/*******************************************************/
.shop-shorter {
  padding: 20px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #eff2f6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.shop-shorter .sort-text {
  margin: 10px;
}
.shop-shorter .grid-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 20px;
}
.shop-shorter .grid-list li {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.shop-shorter .products-dropdown select {
  border-radius: 0;
  border-width: 1px;
  padding-top: 13px;
  padding-bottom: 13px;
}

.product-item {
  margin-bottom: 40px;
}
.product-item .image {
  position: relative;
  margin-bottom: 25px;
  z-index: 1;
  position: relative;
}
.product-item .image::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.57;
  background-color: #104cba;
}
.product-item .image:before {
  height: 0;
  top: auto;
  bottom: 0;
  z-index: 1;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.product-item .image .sale {
  position: absolute;
  left: 5px;
  top: 5px;
  z-index: 2;
  color: white;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 15px;
  border-radius: 5px;
  background: #104cba;
}
.product-item .image img {
  width: 100%;
}
.product-item .image .theme-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  opacity: 0;
  background: white;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.product-item .title-price {
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.product-item .title-price h5 {
  margin-bottom: 0;
}
.product-item .title-price .price {
  color: #104cba;
}
.product-item:hover .theme-btn {
  opacity: 1;
}
.product-item:hover .image:before {
  height: 100%;
}

/* Product Details */
@media only screen and (max-width: 480px) {
  .shop-details-content h2 {
    font-size: 30px;
  }
}

.product-thumb .product-thumb-item {
  cursor: pointer;
}

.product-thumb .slick-dots {
  margin-top: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.product-thumb .slick-dots li {
  height: 4px;
  width: 18px;
  margin: 3px;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-indent: 2000px;
  background: #0e1e2a;
}
.product-thumb .slick-dots li button {
  display: none;
}
.product-thumb .slick-dots li:hover,
.product-thumb .slick-dots li.slick-active {
  background: #104cba;
}

.descriptions .shop-price {
  font-size: 20px;
}

.descriptions .price {
  margin-right: 10px;
  color: #104cba;
}

.descriptions .add-to-cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.descriptions .add-to-cart input {
  width: auto;
  border: none;
  margin-right: 10px;
  background: #e5eef6;
}

.descriptions .product-meta li {
  margin-bottom: 5px;
}
.descriptions .product-meta li a:after {
  content: ",";
}
.descriptions .product-meta li a:last-child:after {
  content: "";
}

.product-information-tab li {
  margin-right: 5px;
}
.product-information-tab li a {
  color: white;
  padding: 12px 25px;
  border-radius: 5px;
  display: inline-block;
  background: #0e1e2a;
}
.product-information-tab li a:hover,
.product-information-tab li a.active {
  background: #104cba;
}

.review-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (max-width: 480px) {
  .review-item {
    display: block;
  }
}
.review-item .reviewer-img {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 100px;
  margin: 0 15px 15px 0;
}
.review-item .reviewer-img img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.reviewer-review {
  padding: 13px 20px 20px;
  border: 1px solid #e4e1e3;
}
.reviewer-review .reviewer-header {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.reviewer-review .reviewer-header h6 {
  margin: 8px 10px 8px 0;
}
.reviewer-review .reviewer-header .date {
  margin-right: auto;
  padding: 4px 10px 4px 0;
}

.your-rating .ratings,
.reviewer-review .ratings {
  font-size: 13px;
}
.your-rating .ratings i,
.reviewer-review .ratings i {
  color: #104cba;
}

/*******************************************************/
/****************** 25. FAQs Section ******************/
/*******************************************************/
.faq-accordion .card {
  border: none;
  border-radius: 0;
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
}

.faq-accordion .card-header {
  border: none;
  color: #000f5c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 20px;
  font-weight: 500;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 25px 40px;
  background: transparent;
  font-family: "Circular Std";
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media only screen and (max-width: 480px) {
  .faq-accordion .card-header {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (max-width: 375px) {
  .faq-accordion .card-header {
    font-size: 18px;
  }
}

.faq-accordion .card-body {
  margin-top: -15px;
  padding: 0 40px 10px;
}
@media only screen and (max-width: 480px) {
  .faq-accordion .card-body {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.faq-accordion .toggle-btn {
  width: 30px;
  height: 30px;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  margin-left: 8px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
@media only screen and (max-width: 375px) {
  .faq-accordion .toggle-btn {
    width: 20px;
    height: 20px;
  }
}
.faq-accordion .toggle-btn:after,
.faq-accordion .toggle-btn:before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: #000f5c;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.faq-accordion .toggle-btn:before {
  width: 2px;
  z-index: 1;
  height: 100%;
}
.faq-accordion .toggle-btn:after {
  height: 2px;
  width: 100%;
}

.faq-triangle {
  top: 20%;
  left: 10%;
  position: absolute;
  -webkit-animation: shapeAnimationFive 20s linear infinite;
  animation: shapeAnimationFive 20s linear infinite;
}

.faq-box-shape {
  top: 20%;
  right: 20%;
  position: absolute;
  width: 10px;
  height: 10px;
  background: #ff7a41;
  -webkit-animation: shapeAnimationThree 20s linear infinite;
  animation: shapeAnimationThree 20s linear infinite;
}

/*******************************************************/
/******************* 26. 404 Error *********************/
/*******************************************************/
.error-content {
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
}
.error-content h2 {
  font-size: 48px;
  margin: 50px 0 30px;
}
@media only screen and (max-width: 991px) {
  .error-content h2 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .error-content h2 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 480px) {
  .error-content h2 {
    font-size: 30px;
  }
}
.error-content .theme-btn {
  padding-left: 45px;
  padding-right: 45px;
}

/*******************************************************/
/******************* 27. Main Slider *******************/
/*******************************************************/
.main-slider-wrap .image {
  width: 100%;
  height: 100%;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.main-slider-wrap .image img {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  max-width: none;
  min-width: 100%;
  min-height: 100%;
}
.main-slider-wrap .image:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  background: -webkit-gradient(linear, left top, right top, from(#03091f), to(rgba(3, 9, 31, 0.5)));
  background: -webkit-linear-gradient(left, #03091f 0%, rgba(3, 9, 31, 0.5) 100%);
  background: -o-linear-gradient(left, #03091f 0%, rgba(3, 9, 31, 0.5) 100%);
  background: linear-gradient(90deg, #03091f 0%, rgba(3, 9, 31, 0.5) 100%);
}

.main-slider-wrap .slider-caption {
  z-index: 2;
  min-height: 828px;
  position: relative;
  padding-top: 92px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 100px;
}
@media only screen and (max-width: 1500px) {
  .main-slider-wrap .slider-caption {
    min-height: 700px;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider-wrap .slider-caption {
    min-height: 600px;
  }
}

.main-slider-wrap .slick-arrow {
  top: 50%;
  z-index: 2;
  opacity: 0.2;
  font-size: 22px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  color: #f43336;
  position: absolute;
  width: 60px;
  height: 60px;
  background: white;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media only screen and (max-width: 575px) {
  .main-slider-wrap .slick-arrow {
    width: 40px;
    height: 40px;
    font-size: 18px;
    line-height: 40px;
  }
}
.main-slider-wrap .slick-arrow i {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  color: #f43336;
}
.main-slider-wrap .slick-arrow.prev {
  left: 200px;
}
@media only screen and (max-width: 1500px) {
  .main-slider-wrap .slick-arrow.prev {
    left: 25px;
  }
}
.main-slider-wrap .slick-arrow.next {
  right: 200px;
}
@media only screen and (max-width: 1500px) {
  .main-slider-wrap .slick-arrow.next {
    right: 25px;
  }
}
.main-slider-wrap .slick-arrow:hover,
.main-slider-wrap .slick-arrow:focus {
  background: #f43336;
}
.main-slider-wrap .slick-arrow:hover i,
.main-slider-wrap .slick-arrow:focus i {
  color: white;
}

.main-slider-wrap:hover .slick-arrow {
  opacity: 1;
}

.main-slider-content {
  max-width: 610px;
}
.main-slider-content .sub-title {
  margin-bottom: 10px;
  display: inline-block;
  text-transform: uppercase;
}
.main-slider-content h1 {
  margin-bottom: 20px;
}
@media only screen and (max-width: 1500px) {
  .main-slider-content h1 {
    font-size: 60px;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider-content h1 {
    font-size: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider-content h1 {
    font-size: 45px;
  }
}
@media only screen and (max-width: 575px) {
  .main-slider-content h1 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 480px) {
  .main-slider-content h1 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 375px) {
  .main-slider-content h1 {
    font-size: 30px;
  }
}
.main-slider-content p {
  font-size: 18px;
  max-width: 555px;
}
@media only screen and (max-width: 480px) {
  .main-slider-content p {
    font-size: 16px;
  }
}
.main-slider-content .slider-btns {
  padding-top: 20px;
}
.main-slider-content .slider-btns .theme-btn {
  margin-top: 15px;
}
.main-slider-content .slider-btns .theme-btn:not(:last-child) {
  margin-right: 30px;
}
.main-slider-content * {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.slick-active * {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.slick-active h1 {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.slick-active p {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.slick-active .slider-btns {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

/*******************************************************/
/***************** 28. Protects Section ****************/
/*******************************************************/
.protect-left-part {
  margin-left: -45px;
  position: relative;
  padding-right: 20px;
  display: inline-block;
}
@media only screen and (max-width: 1199px) {
  .protect-left-part {
    margin-left: 0;
  }
}
.protect-left-part .video-play {
  width: 165px;
  height: 165px;
  text-align: center;
  line-height: 165px;
  border-radius: 50%;
  background: url(../images/protects/video.jpg) no-repeat center/cover;
  z-index: 1;
  position: relative;
  position: absolute;
  right: 0;
  bottom: 60px;
}
.protect-left-part .video-play::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.8;
  background-color: #030a15;
}
@media only screen and (max-width: 575px) {
  .protect-left-part .video-play {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.protect-left-part .video-play:before {
  border-radius: 50%;
}
.protect-left-part .video-play i {
  width: 60px;
  height: 60px;
  color: white;
  line-height: 60px;
  background: #f43336;
}
.protect-left-part .video-play i:after {
  left: 50%;
  top: 50%;
  content: "";
  position: absolute;
  border: 1px solid white;
  width: 45px;
  height: 45px;
  background: transparent;
  line-height: 45px;
  border-radius: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.protect-content {
  max-width: 425px;
}

.protect-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 25px 30px;
  border-radius: 5px;
  margin-left: -30px;
  margin-right: -30px;
}
@media only screen and (max-width: 1199px) {
  .protect-item {
    background: white;
  }
}
@media only screen and (max-width: 375px) {
  .protect-item {
    display: block;
  }
}
.protect-item .icon {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  color: white;
  font-size: 40px;
  margin-right: 30px;
  margin-bottom: 15px;
  width: 80px;
  height: 80px;
  background: #f43336;
  line-height: 80px;
  border-radius: 50%;
  text-align: center;
}
.protect-item p {
  margin-bottom: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.protect-item:hover {
  background: #030a15;
}
.protect-item:hover h4 a,
.protect-item:hover p {
  color: white;
}

/* Ready Section */
.ready-content {
  z-index: 2;
  background: white;
  position: relative;
  padding: 55px 70px 60px 100px;
  -webkit-box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 1199px) and (min-width: 991px) {
  .ready-content {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (min-width: 991px) {
  .ready-content {
    margin-right: -50px;
  }
}
@media only screen and (max-width: 767px) {
  .ready-content {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (max-width: 375px) {
  .ready-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.ready-content .list-style-three {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.ready-content .list-style-three li {
  width: 48%;
  margin-bottom: 20px;
}
@media only screen and (max-width: 575px) {
  .ready-content .list-style-three li {
    width: 100%;
  }
}

@media only screen and (min-width: 991px) {
  .ready-image {
    margin-left: -100px;
  }
}

.ready-image img {
  width: 100%;
}

/*******************************************************/
/***************** 29. Actions Section *****************/
/*******************************************************/
.actions-section {
  z-index: 1;
  position: relative;
}
.actions-section:before {
  left: 10%;
  top: 50%;
  content: "";
  opacity: 0.2;
  z-index: -1;
  width: 500px;
  height: 300px;
  position: absolute;
  -webkit-filter: blur(150px);
  filter: blur(150px);
  background: #20c997;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.actions-section:after {
  left: 0;
  top: 0;
  content: "";
  z-index: -2;
  width: 100%;
  height: 130%;
  position: absolute;
  background: #040b11;
}

.actions-active {
  margin-left: -15px;
  margin-right: -15px;
}
.actions-active .action-item {
  margin-left: 15px;
  margin-right: 15px;
}

.action-item {
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 30px;
  background: #1b2429;
  border: 1px solid rgba(255, 255, 255, 0.15);
}
.action-item .image {
  position: relative;
}
.action-item .image img {
  width: 100%;
  border-radius: 5px;
}
.action-item .image .count-down {
  left: 15px;
  bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: white;
  border-radius: 5px;
  position: absolute;
  padding-top: 7px;
  padding-bottom: 7px;
  width: calc(100% - 30px);
}
.action-item .image .count-down li {
  width: 25%;
  line-height: 1;
  font-size: 10px;
  text-align: center;
  color: #454545;
}
.action-item .image .count-down li span {
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: #000000;
}
.action-item .image .count-down li:not(:last-child) {
  border-right: 1px solid rgba(7, 1, 47, 0.1);
}
.action-item .content {
  padding: 20px 15px 5px;
}
.action-item .content .bid-dots {
  margin-bottom: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.action-item .content .bid-dots .bid {
  font-size: 14px;
  font-weight: 500;
  font-family: "Urbanist", sans-serif;
}
.action-item .content .bid-dots .bid b {
  font-weight: 700;
}
.action-item .content .bid-dots .dots {
  width: 30px;
  height: 16px;
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
}
.action-item .content .bid-dots .dots span {
  width: 3px;
  height: 3px;
  margin: 1.5px;
  background: white;
  border-radius: 50%;
}
.action-item .content .author-wish {
  margin-top: 15px;
  padding-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px dashed rgba(255, 255, 255, 0.2);
}
.action-item .content .author-wish .author {
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.action-item .content .author-wish .author img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-right: 10px;
}
.action-item .content .author-wish .author h6 {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 700;
  letter-spacing: -0.03em;
}
.action-item .content .author-wish .author span {
  font-size: 12px;
  color: #a0a4a8;
}
.action-item .content .author-wish .wish {
  font-size: 14px;
}
.action-item .content .author-wish .wish i {
  margin-right: 5px;
}

.collection-section {
  position: relative;
  z-index: 1;
}
.collection-section:after,
.collection-section:before {
  content: "";
  z-index: -1;
  opacity: 0.1;
  position: absolute;
  width: 600px;
  height: 400px;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  background: #20c997;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.collection-section:before {
  right: 0;
  top: 0;
}
.collection-section:after {
  left: 0;
  top: 50%;
}

.collection-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
}
.collection-filter li {
  z-index: 1;
  cursor: pointer;
  font-weight: 600;
  position: relative;
  padding: 5px 18px;
  border-radius: 2px;
  background: #1b2429;
  margin-right: 20px;
  margin-bottom: 10px;
  font-family: "Urbanist", sans-serif;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
}
@media only screen and (max-width: 1199px) {
  .collection-filter li {
    margin-right: 10px;
  }
}
@media only screen and (max-width: 375px) {
  .collection-filter li {
    font-size: 14px;
  }
}
.collection-filter li:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 2px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(-21.46%, #a146e8),
    color-stop(36.39%, #6c63d0),
    to(#387db8)
  );
  background: -webkit-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: -o-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: linear-gradient(90deg, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
}
.collection-filter li:hover:before,
.collection-filter li.current:before {
  opacity: 1;
}

/* Cagegory Collection */
.collection-category-area:after,
.collection-category-area:before {
  content: "";
  z-index: -1;
  opacity: 0.1;
  position: absolute;
  width: 600px;
  height: 400px;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  background: #20c997;
}

.collection-category-area:before {
  left: 0;
  bottom: -10%;
}

.collection-category-area:after {
  top: 10%;
  right: 0;
}

.collection-category-active {
  margin-left: -15px;
  margin-right: -15px;
}
.collection-category-active .collection-category-item {
  margin-left: 15px;
  margin-right: 15px;
}
.collection-category-active .slick-arrow {
  border: none;
  position: absolute;
  bottom: calc(50% + 33px);
}
.collection-category-active .slick-arrow.prev {
  left: 15px;
}
.collection-category-active .slick-arrow.next {
  right: 15px;
}
.collection-category-active .slick-arrow:before {
  opacity: 1;
}

.collection-category-item {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 30px;
  background: #1b2429;
  border: 1px solid rgba(255, 255, 255, 0.15);
}
.collection-category-item .category-images .row {
  margin-left: -5px;
  margin-right: -5px;
}
.collection-category-item .category-images .row > div {
  padding-left: 5px;
  padding-right: 5px;
}
.collection-category-item .category-images a {
  width: 100%;
  margin-bottom: 10px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.collection-category-item .category-images a img {
  width: 100%;
  border-radius: 5px;
}
.collection-category-item .title-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 20px;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.collection-category-item .title-dots h4 {
  margin-bottom: 8px;
}
.collection-category-item .title-dots .dots {
  width: 26px;
  height: 34px;
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.2);
}
.collection-category-item .title-dots .dots span {
  width: 3px;
  height: 3px;
  margin: 1.5px 3px;
  background: white;
  border-radius: 50%;
}

/*******************************************************/
/***************** 30. Sellers Section *****************/
/*******************************************************/
.sellers-section {
  overflow: hidden;
}
.sellers-section .container:before {
  top: 0;
  left: 50%;
  content: "";
  z-index: -1;
  opacity: 0.1;
  position: absolute;
  width: 600px;
  height: 400px;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  background: #20c997;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}
.sellers-section .container:after {
  left: 50%;
  top: 50%;
  content: "";
  z-index: -2;
  position: absolute;
  background: #0f1625;
  width: calc(100% + 230px);
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.sellers-active {
  margin-left: -15px;
  margin-right: -15px;
}
.sellers-active .seller-item {
  margin-left: 15px;
  margin-right: 15px;
}

.seller-item {
  padding: 20px;
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
  background: #1b2429;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.15);
}
.seller-item .number {
  top: 20px;
  right: 20px;
  color: #a0a4a8;
  font-size: 14px;
  position: absolute;
  font-family: "Urbanist", sans-serif;
  width: 30px;
  height: 30px;
  background: rgba(196, 196, 196, 0.1);
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
}
.seller-item .image {
  position: relative;
  display: inline-block;
}
.seller-item .image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.seller-item .image .check {
  bottom: 0;
  right: 15%;
  font-size: 8px;
  position: absolute;
  width: 20px;
  height: 20px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(-21.46%, #a146e8),
    color-stop(36.39%, #6c63d0),
    to(#387db8)
  );
  background: -webkit-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: -o-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: linear-gradient(90deg, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
}
.seller-item h5 {
  margin: 15px 0 0;
}
.seller-item .items {
  color: #cacccf;
  font-size: 14px;
  font-weight: 500;
  font-family: "Urbanist", sans-serif;
}

/*******************************************************/
/**************** 31. Main Footer Style ****************/
/*******************************************************/
.footer-widget-area {
  background-color: #1256d1;
}

.footer-logo {
  margin-top: -10px;
}

.footer-widget {
  margin-bottom: 50px;
}

.footer-title {
  margin-bottom: 28px;
}

.newsletter-widget form {
  padding: 7px;
  max-width: 310px;
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 35px;
  border: 1px solid #3a4a65;
}
.newsletter-widget form input {
  color: white;
  height: auto;
  border: none;
  background: transparent;
  padding: 5px 5px 5px 20px;
}
.newsletter-widget form input::-webkit-input-placeholder {
  color: white;
}
.newsletter-widget form input:-ms-input-placeholder {
  color: white;
}
.newsletter-widget form input::-ms-input-placeholder {
  color: white;
}
.newsletter-widget form input::placeholder {
  color: white;
}
.newsletter-widget form button {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 45px;
  height: 45px;
  background: #417fef;
  line-height: 45px;
  border-radius: 50%;
  text-align: center;
}

@media only screen and (max-width: 1199px) {
  .newsletter-widget p br {
    display: none;
  }
}

.contact-info li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.contact-info li i {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  color: #ced1d5;
  margin: 5px 12px 0 0;
  width: 40px;
  height: 40px;
  background: #223452;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
}

.widget-news-item {
  max-width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 25px;
}
.widget-news-item:last-child {
  margin-bottom: 0;
}
.widget-news-item img {
  max-width: 75px;
  margin-right: 22px;
}

.widget-news-content h6,
.widget-news-content h5 {
  margin-bottom: 5px;
}

.footer-top .contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .footer-top .contact-info {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.footer-top .contact-info li {
  width: 33.33%;
  padding-top: 15px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-right: 1px solid #223452;
}
.footer-top .contact-info li:first-child {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.footer-top .contact-info li:last-child {
  margin-right: 0;
  border-right: none;
}
@media only screen and (max-width: 767px) {
  .footer-top .contact-info li {
    width: auto;
    margin-right: 25px;
    border-right: none;
  }
}
.footer-top .contact-info li i {
  color: white;
  background: #104cba;
  border-color: white;
}

/** Footer Copyright **/
.copyright-inner {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 18px 0 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.copyright-inner p {
  opacity: 0.7;
  font-size: 14px;
  margin-bottom: 10px;
}
.copyright-inner .social-style-one a {
  margin-right: 33px;
}

/* Footer One */
.footer-one .newsletter-widget {
  padding: 40px 30px;
  text-align: center;
  border-radius: 5px;
  background: #1359db;
}
.footer-one .newsletter-widget form {
  border: 1px solid #4d79cc;
}

/* Footer Two */
.footer-two {
  background-color: #0e1e2a;
}
.footer-two .footer-widget-area {
  background: transparent;
}
.footer-two .copyright-area {
  border-top: 1px solid #223452;
}
.footer-two p,
.footer-two .text,
.footer-two .list-style-two,
.footer-two .contact-info span,
.footer-two .newsletter-widget input::-webkit-input-placeholder {
  opacity: 0.75;
}
.footer-two p,
.footer-two .text,
.footer-two .list-style-two,
.footer-two .contact-info span,
.footer-two .newsletter-widget input:-ms-input-placeholder {
  opacity: 0.75;
}
.footer-two p,
.footer-two .text,
.footer-two .list-style-two,
.footer-two .contact-info span,
.footer-two .newsletter-widget input::-ms-input-placeholder {
  opacity: 0.75;
}
.footer-two p,
.footer-two .text,
.footer-two .list-style-two,
.footer-two .contact-info span,
.footer-two .newsletter-widget input::placeholder {
  opacity: 0.75;
}

/* Footer Three */
.footer-three p,
.footer-three .date,
.footer-three .text,
.footer-three .list-style-three,
.footer-three .contact-info span,
.footer-three .list-style-two *:not(i) {
  opacity: 0.75;
}

.main-footer .list-style-three li:before {
  color: white;
  font-size: 14px;
  content: "\f054";
}

/* Footer Four */
.footer-sign-up {
  -webkit-transform: translateY(-90px);
  -ms-transform: translateY(-90px);
  transform: translateY(-90px);
}
@media only screen and (max-width: 767px) {
  .footer-sign-up .footer-signup-inner {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (max-width: 375px) {
  .footer-sign-up .footer-signup-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.footer-sign-up .container {
  max-width: 1330px;
}
.footer-sign-up .sign-in-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: white;
  position: relative;
  border-radius: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 15px 10px 0;
}
@media only screen and (max-width: 480px) {
  .footer-sign-up .sign-in-form {
    padding: 5px;
  }
}
.footer-sign-up .sign-in-form input {
  border: none;
  background: transparent;
}
@media only screen and (max-width: 375px) {
  .footer-sign-up .sign-in-form input {
    padding: 10px 0 10px 10px;
  }
}
.footer-sign-up .sign-in-form input::-webkit-input-placeholder {
  font-weight: 500;
  color: #000000;
}
.footer-sign-up .sign-in-form input:-ms-input-placeholder {
  font-weight: 500;
  color: #000000;
}
.footer-sign-up .sign-in-form input::-ms-input-placeholder {
  font-weight: 500;
  color: #000000;
}
.footer-sign-up .sign-in-form input::placeholder {
  font-weight: 500;
  color: #000000;
}
.footer-sign-up .sign-in-form button {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}
@media only screen and (max-width: 375px) {
  .footer-sign-up .sign-in-form button {
    padding: 10px 15px;
  }
}

.footer-four .social-style-two a {
  opacity: 1;
  color: #454545;
  background: white;
}
.footer-four .social-style-two a:hover {
  color: white;
  background: #104cba;
}

.footer-four .list-style-three li:before {
  color: #454545;
}

.footer-four .list-style-two i {
  color: #104cba;
}

/* Footer Five */
.footer-five .copyright-inner {
  border-top: 1px solid #ced0d4;
}
.footer-five .copyright-inner p {
  opacity: 1;
  color: #0a1426;
  font-size: 16px;
  padding: 10px 0;
}

.footer-five .list-style-two i {
  font-size: 18px;
  color: #2f97f7;
}

.footer-five .social-style-two a {
  opacity: 1;
  background: white;
  color: #000000;
}
.footer-five .social-style-two a:hover {
  color: white;
  background: #2f97f7;
}

/* Footer Six */
.footer-six {
  font-size: 16px;
}
.footer-six p {
  font-weight: 600;
}
.footer-six .footer-widget .social-style-two {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.footer-six .footer-widget .social-style-two a {
  opacity: 1;
  color: #31394c;
  border-radius: 7px;
  margin: 0 10px 10px 0;
  background: rgba(55, 114, 255, 0.1);
}
.footer-six .footer-widget .social-style-two a:hover {
  color: white;
  background: #3772ff;
}
.footer-six .link-widget li a {
  color: #333f7d;
  font-weight: 600;
}
.footer-six .link-widget li a:hover {
  color: #ff7a41;
}
.footer-six .newsletter-widget form {
  padding: 0;
  max-width: none;
  border-color: #cccfde;
  border-radius: 5px 0 0 5px;
}
.footer-six .newsletter-widget form input {
  color: #666f9d;
}
.footer-six .newsletter-widget form input::-webkit-input-placeholder {
  color: #666f9d;
}
.footer-six .newsletter-widget form input:-ms-input-placeholder {
  color: #666f9d;
}
.footer-six .newsletter-widget form input::-ms-input-placeholder {
  color: #666f9d;
}
.footer-six .newsletter-widget form input::placeholder {
  color: #666f9d;
}
.footer-six .newsletter-widget form button {
  width: 60px;
  height: 60px;
  color: white;
  border-radius: 0;
}
.footer-six .copyright-inner {
  background: #f8fbff;
  padding: 25px 40px 20px;
}
@media only screen and (max-width: 375px) {
  .footer-six .copyright-inner {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.footer-six .copyright-inner p,
.footer-six .copyright-inner a {
  color: #000f5c;
  font-size: 18px;
}
.footer-six .copyright-inner .footer-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.footer-six .copyright-inner .footer-menu li {
  margin: 0 10px 5px 0;
  font-family: "Circular Std";
}

/* Footer Seven */
.footer-seven .footer-title {
  font-size: 27px;
  font-weight: 700;
  margin-bottom: 22px;
}

.footer-seven .social-style-two a {
  opacity: 1;
  background: rgba(255, 255, 255, 0.1);
}
.footer-seven .social-style-two a:hover {
  background: #f43336;
}

.footer-seven .list-style-three li {
  font-size: 18px;
  font-weight: 400;
  position: relative;
  margin-bottom: 15px;
}
.footer-seven .list-style-three li:last-child {
  margin-bottom: 0;
}
.footer-seven .list-style-three li:before {
  display: none;
}
.footer-seven .list-style-three li a:before {
  content: "\f101";
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  font-weight: 600;
  color: #f43336;
  font-family: "Font Awesome 5 Free";
}
.footer-seven .list-style-three li a:hover {
  padding-left: 25px;
}
.footer-seven .list-style-three li a:hover:before {
  opacity: 1;
}

.footer-seven .list-style-two li {
  font-size: 18px;
  margin-bottom: 20px;
}
.footer-seven .list-style-two li i {
  font-size: 20px;
  color: #f43336;
  margin-right: 15px;
}
.footer-seven .list-style-two li b {
  font-weight: 500;
  margin-right: 5px;
}
.footer-seven .list-style-two li:last-child {
  margin-bottom: 0;
}

.footer-seven .copyright-inner {
  padding-top: 10px;
  padding-bottom: 0;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  border-top: 1px solid rgba(232, 232, 233, 0.1);
}
.footer-seven .copyright-inner p {
  opacity: 1;
  font-size: 16px;
}
.footer-seven .copyright-inner .scroll-top {
  right: 0;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 60px;
  height: 60px;
  bottom: 10px;
  font-size: 20px;
  position: relative;
  border-radius: 50%;
  background: #f43336;
}

/* Footer Nine */
.footer-nine:before {
  top: 0;
  right: 0;
  content: "";
  z-index: -1;
  opacity: 0.1;
  position: absolute;
  width: 600px;
  height: 400px;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  background: #20c997;
}

.footer-nine .about-widget {
  max-width: 303px;
}

.footer-nine .social-style-two a {
  opacity: 1;
  z-index: 1;
  position: relative;
}
.footer-nine .social-style-two a:before {
  width: 100%;
  height: 100%;
  content: "";
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  border-radius: 50%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(-21.46%, #a146e8),
    color-stop(36.39%, #6c63d0),
    to(#387db8)
  );
  background: -webkit-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: -o-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: linear-gradient(90deg, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
}
.footer-nine .social-style-two a:hover:before {
  opacity: 1;
}

.footer-nine .list-style-two a {
  color: #a0a4a8;
}
.footer-nine .list-style-two a:hover {
  color: #20c997;
}

.footer-nine .newsletter-widget p {
  color: #a0a4a8;
}

.footer-nine .newsletter-widget form {
  padding: 0;
  border: none;
  border-radius: 0;
  background: #1b2429;
}
.footer-nine .newsletter-widget form input::-webkit-input-placeholder {
  font-size: 14px;
}
.footer-nine .newsletter-widget form input:-ms-input-placeholder {
  font-size: 14px;
}
.footer-nine .newsletter-widget form input::-ms-input-placeholder {
  font-size: 14px;
}
.footer-nine .newsletter-widget form input::placeholder {
  font-size: 14px;
}
.footer-nine .newsletter-widget form button {
  width: 56px;
  height: 56px;
  border-radius: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(-21.46%, #a146e8),
    color-stop(36.39%, #6c63d0),
    to(#387db8)
  );
  background: -webkit-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: -o-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: linear-gradient(90deg, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
}

.footer-nine .copyright-area {
  background: #1b2429;
}
.footer-nine .copyright-area .copyright-inner {
  padding-top: 15px;
}
.footer-nine .copyright-area .scroll-top {
  bottom: 5px;
  width: 50px;
  height: 50px;
  right: 0;
  font-size: 22px;
  position: relative;
  border-radius: 50%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(-21.46%, #a146e8),
    color-stop(36.39%, #6c63d0),
    to(#387db8)
  );
  background: -webkit-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: -o-linear-gradient(left, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
  background: linear-gradient(90deg, #a146e8 -21.46%, #6c63d0 36.39%, #387db8 100%);
}
